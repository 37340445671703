import React from 'react'
import { Checkbox, Menu, List, Dropdown, Button, message as AntMessage, Select, Tooltip, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'
import QRCode from 'qrcode.react'
import base64 from 'base-64'
import dayjs from 'dayjs'
import moment from 'moment'
import momentHijri from 'moment-hijri'
import _ from 'lodash'
import { pluck } from 'underscore'

import CheckMark from '../components/CustomComponents/CheckMark/CheckMark'
import DrawerContent from '../components/CleanComponents/DrawerContent'

import { hasPermission } from './auth.hepler'
import { ENGLISH_LABELS, URDU_LABELS } from '../constants/labels.constants'
import { volunteerColumns } from '../localstorage/localstorage'
import { masked } from './methods.helper'
import { isDeviceMobile } from './responsive.helper'

import { EditOutlined, CopyOutlined, DeleteOutlined, EyeOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

const ListItem = List.Item
const ListMeta = ListItem.Meta

export const passingMarks = Array.from(new Array(10), (x, i) => i).map(x => (x + 1) * 10)

export const minimum_question_count = {
  INTERMEDIATE: 20,
  DIFFICULT: 20,
  EASY: 20,
}

export const userRoles = ['Super Admin', 'Admin', 'Volunteer', 'Assistant Team Leader', 'Team Leader', 'Area Incharge', 'Trainer', 'Regional Admin']

export const refreshTimeColumns = 1 // value is in minutes
export const PROGRESS_SAVE_TIME = 600000 // value is in milliseconds

export const userManagementRoles = ['Super Admin', 'Admin', 'Assistant Team Leader', 'Team Leader', 'Area Incharge', 'Trainer', 'Regional Admin']

export const administratorRoles = ['Super Admin', 'Admin', 'Regional Admin', 'Area Incharge']
export const administratorRolesSuper = ['Super Admin', 'Admin', 'Regional Admin']

export const teamColumns = [
  {
    title: 'Iqama No.',
    dataIndex: 'iqama_id',
    key: 'iqama_id',
    width: 100,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 225,
  },
  {
    title: 'Mobile',
    dataIndex: 'mobile',
    width: 150,
    key: 'mobile',
  },
  {
    title: 'Team',
    dataIndex: 'team',
    key: 'team',
    width: 150,
  },
  {
    title: 'Active',
    dataIndex: 'status',
    key: 'status',
    width: 75,
  },
  {
    title: 'Verified',
    dataIndex: 'verified',
    key: 'verified',
    width: 100,
  },
]

export const handleCountryCodeChange = (name, setFieldsValue) => {
  let obj = {}
  obj[name] = null
  setFieldsValue(obj)
}

export const serializeParams = obj => {
  return (
    '?' +
    Object.entries(obj)
      .map(([key, val]) => `${key}=${val}`)
      .join('&')
  )
}

export const drawerContent = (id, volunteers, method, props, role, deleteMethod, profilePath) => {
  if (id && volunteers) {
    let volunteer
    if (method === 'team') {
      volunteer = volunteers.find(volunteer => volunteer.volunteer.id === id)
      if (volunteer) {
        volunteer = volunteer.volunteer
      }
    } else {
      volunteer = volunteers.find(volunteer => volunteer.id === id)
    }
    if (volunteer) {
      const menu = (
        <Menu>
          {props.goEditVolunteer && hasPermission('Volunteer', 'update') ? (
            <Menu.Item onClick={() => props.goEditVolunteer(volunteer ? volunteer.id : undefined)}>
              <div>Edit Volunteer</div>
            </Menu.Item>
          ) : undefined}
          {props.goEditIqama && hasPermission('Iqama', 'update') ? (
            <Menu.Item onClick={() => props.goEditIqama(volunteer && volunteer.iqama ? volunteer.iqama.id : undefined)}>
              <div>Edit Iqama</div>
            </Menu.Item>
          ) : undefined}
          {props.goEditUser && hasPermission('User', 'update') ? (
            <Menu.Item onClick={() => props.goEditUser(volunteer && volunteer.user ? volunteer.user.id : undefined)}>
              <div>Edit User</div>
            </Menu.Item>
          ) : undefined}
        </Menu>
      )

      const mobileMenu = (
        <Menu>
          {deleteMethod && hasPermission('Volunteer', 'destroy') ? (
            <Menu.Item onClick={() => deleteMethod(volunteer.id)} type="primary" danger>
              Delete Volunteer
            </Menu.Item>
          ) : undefined}
          <Menu.Item onClick={() => props.goToVolunteerProfile(volunteer)} type="primary">
            View Profile
          </Menu.Item>
          <Menu.SubMenu title="Edit Profile">{menu}</Menu.SubMenu>
        </Menu>
      )

      // For Area Incharge only
      const areaInchargeTeams =
          volunteer.user && volunteer.user.role === 'Area Incharge' && volunteer.area_incharge_teams
            ? volunteer.area_incharge_teams
                .map(t => t.name)
                .sort()
                .join(', ')
            : [],
        areaInchargeTeamsTitle = 'Teams Assigned: ' + areaInchargeTeams

      const areaInchargeTeamsStyle = {
        color: '#777',
        fontSize: '11px',
      }

      return (
        <div>
          <div className="card-body">
            <DrawerContent user={volunteer} />
          </div>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}>
            {!isDeviceMobile() ? (
              <div>
                {deleteMethod && hasPermission('Volunteer', 'destroy') ? (
                  <Button onClick={() => deleteMethod(volunteer.id)} type="primary" danger>
                    Delete Volunteer
                  </Button>
                ) : undefined}

                <React.Fragment>
                  {(props.goEditVolunteer || props.goEditIqama || props.goEditUser) &&
                  [
                    'Super Admin',
                    // "Admin"
                    // ,
                    // "Area Incharge"
                  ].includes(role) ? (
                    // hasPermission('Volunteer', 'update') ||
                    // hasPermission('Iqama', 'update') ||
                    // hasPermission('User', 'update')
                    <Dropdown overlay={menu} trigger={['click', 'hover']} placement="top" className="mr-2 ml-2">
                      <Button>Edit Profile</Button>
                    </Dropdown>
                  ) : undefined}
                  {profilePath ? (
                    <Link to={profilePath}>
                      <Button type="primary">View Profile</Button>
                    </Link>
                  ) : (
                    <Button onClick={() => props.goToVolunteerProfile(volunteer)} type="primary">
                      View Profile
                    </Button>
                  )}
                </React.Fragment>
              </div>
            ) : (
              <Dropdown trigger={['click', 'hover']} overlay={mobileMenu}>
                <Button>Manage Profile</Button>
              </Dropdown>
            )}
          </div>
        </div>
      )
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

export const qrCode = data => {
  if (!data) return <QRCode value={JSON.stringify(qrData)} size={200} renderAs="svg" />

  const qrURL = process.env.REACT_APP_URL + 'qr/attendance/' + data.iqama.registration_id
  const qrData = {
    'Volunteer Name': data.full_name,
    'Iqama ID': data.iqama.id,
    City: data.city ? data.city.name : '',
    'Mobile No.': data.mobile.international,
    'Blood Group': data.blood_grp,
    'Emergency Contact': data.emergency_contact_no.international,
  }

  return <QRCode value={JSON.stringify(qrData)} size={200} renderAs="svg" />
}

export const openMap = (latitude, longitude) => {
  if (latitude && longitude) {
    window.open('https://maps.google.com/?q=' + latitude + ',' + longitude)
  } else {
    window.open('https://maps.google.com/')
  }
}

export const getTableDataSourceObject = (volunteers, props) => {
  if (volunteers && volunteers.length > 0) {
    let arr = []

    volunteers.forEach((user, key) => {
      let volunteer = user ? user.volunteer : null

      if (volunteer) {
        let row = {
          key: key,
          iqama_id:
            volunteer && volunteer.iqama ? (
              props.role !== 'Volunteer' ? (
                <div
                  className="volunteer-profile"
                  onClick={() => {
                    props.goToVolunteerProfile(volunteer)
                  }}>
                  {props.maskIqamaID ? masked(volunteer.iqama.registration_id) : volunteer.iqama.registration_id}
                </div>
              ) : (
                <div>{volunteer.iqama.registration_id}</div>
              )
            ) : null,
          name: volunteer ? volunteer.full_name : null,
          team: volunteer && volunteer.team ? volunteer.team.name : null,
          mobile: volunteer && volunteer.mobile ? volunteer.mobile.international : '-',
          status: volunteer ? volunteer.status ? <CheckMark /> : <CheckMark type="unchecked" /> : null,
          verified: volunteer ? volunteer.verified ? <CheckMark /> : <CheckMark type="unchecked" /> : null,
        }

        if (volunteer.attendance) {
          let attendance = volunteer.attendance
          attendance.forEach(att => {
            row[att.key] = att.status ? <CheckMark /> : <CheckMark type="unchecked" />
          })
        }

        arr.push(row)
      }
    })

    return arr
  } else {
    return []
  }
}

export const rolesListFilter = (roles, currentUserRole) => {
  if (!roles || !currentUserRole) return []

  const forbidenRoles = {
    'Super Admin': [],
    Admin: ['Super Admin', 'Admin'],
    'Regional Admin': ['Super Admin', 'Admin', 'Regional Admin'],
    'Area Incharge': ['Super Admin', 'Admin', 'Regional Admin', 'Area Incharge'],
    'Team Leader': ['Super Admin', 'Admin', 'Regional Admin', 'Area Incharge', 'Trainer'],
  }

  const forbidenRolesNames = forbidenRoles[currentUserRole] || []

  const filteredRoles = roles.filter(r => !forbidenRolesNames.includes(r.name)).map(r => ({ label: r.name, value: r.id }))

  return filteredRoles
}

export const verifyIqamaIDLink = () => {
  let link =
    'https://www.moi.gov.sa/wps/portal/Home/publicservices/passports/iqama-expiry/!ut/p/z0/04_SjzQ0MTexMDExM7HQj9CPykssy0xPLMnMz0vMAfIjo8ziTYyD3Zw9fdy9zUJ8nQw8g7x8gkMCfQ0NnM31g1Pz9HOjHBUBnnDEkA!!/'
  window.open(link)
}

export const mapAttendanceColumns = async newColumns => {
  let columns = teamColumns
  if (newColumns && newColumns.length > 0) {
    let att = newColumns
    att.forEach(attendance => {
      let data = {
        title: attendance.name,
        dataIndex: attendance.key,
        width: attendance.key.includes('training') ? 75 : 125,
        key: attendance.key,
      }

      columns.push(data)
    })
    columns = { attendanceColumns: { columns }, created_at: moment().format() }

    await volunteerColumns.setItem('volunteerColumns', columns)
    return true
  }
  return false
}

export const checkVolunteerColumns = async newColumns => {
  // TODO: Revisit
  let columns = await volunteerColumns.getItem('volunteerColumns')
  // if (columns && columns.created_at) {
  // let createdDate = moment(columns.created_at).add(refreshTimeColumns, 'minutes')
  // let currentDate = moment()
  // if (createdDate < currentDate) {
  if (newColumns) {
    let checkData = await mapAttendanceColumns(newColumns)
    if (checkData) {
      columns = await volunteerColumns.getItem('volunteerColumns')
    }
  }
  // }
  // }

  if (columns && columns.attendanceColumns && columns.attendanceColumns.columns) {
    const cols = columns.attendanceColumns.columns
    const uniqColumns = Array.from(new Set(cols.map(a => a.title))).map(title => {
      return cols.find(a => a.title === title)
    })
    return uniqColumns
  }
}

export const checkTestValidity = attendance => {
  let testValid = false
  if (attendance && attendance.length > 0) {
    attendance.forEach(item => {
      if (item.key === 'training1' || item.key === 'training2') {
        if (item.status) {
          testValid = true
        } else {
          testValid = false
        }
      }
    })
  }
  return testValid
}

export const getRelevantTeamNames = (teams, id) => {
  let teamNames = []
  teams.map(item => {
    if (item.reports_to === id) {
      teamNames.push(
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>,
      )
    }
  })
  return teamNames
}

export const changeDateTimeZone = date => {
  const dateRef = dayjs(date).startOf('minutes')
  const riyadhTimeZone = dayjs.tz(dateRef, 'Asia/Riyadh')
  return riyadhTimeZone
}

export const multipleNameSearch = (key, records, query, isPostSearch = false) => {
  if (!query || records.length === 0) return
  let searchedRecords = []

  // Splits on comma, space and newline all at once
  let queryParts = _.split(query, /[, /\n]+/)
  if (isPostSearch) {
    queryParts = _.split(query, /[,/\n]+/)
  }
  for (let i = 0; i < queryParts.length; i++) {
    let filteredRecords = records.filter(item => item[key].toLowerCase().includes(queryParts[i].trim().toLowerCase()))

    searchedRecords = [...new Set([...searchedRecords, ...filteredRecords].map(JSON.stringify))].map(JSON.parse)
  }
  return searchedRecords
}

// TODO: Needs confirmation, Which keys to keep
export const tableSelectionOptions = attendance => {
  return {
    'Volunteer Options': [
      { label: 'Name', value: 'full_name', copyable: true },
      { label: 'Registration Date', value: 'registration_date' },
      { label: 'Mobile', value: 'mobile', copyable: true },
      { label: 'WhatsApp', value: 'whatsapp', copyable: true },
      { label: 'Team', value: 'teams' },
      { label: 'Status', value: 'status' },
      { label: 'Verified', value: 'verified' },

      { label: 'E-mail', value: 'email' },
      { label: 'Blood Group', value: 'blood_grp' },
      { label: 'Emergency Contact Name', value: 'emergency_contact_name', copyable: true },
      { label: 'Emergency Contact No.', value: 'emergency_contact_no', copyable: true },
      { label: 'Job Title', value: 'job_title' },
      { label: 'Education', value: 'education' },
      { label: 'Date of Birth', value: 'date_of_birth', copyable: true },
      { label: 'Languages', value: 'language' },
      { label: 'City', value: 'city' },
      { label: 'Selected Projects', value: 'selected_project_names' },
    ],

    'Iqama Options': [
      { label: 'Iqama No.', value: 'iqama_id', copyable: true },
      { label: 'Iqama Expiry Date', value: 'expiry_date', copyable: true },
    ],
    'Attendance Options': attendance ? attendance.map(item => ({ label: item.name, value: item.key })) : [],
    'Experience Options': [
      { label: 'PHVG Experience', value: 'phvg_experience' },
      { label: 'PHVG Mina Experience', value: 'phvg_mina_experience' },
      { label: 'Other Volunteer Experience', value: 'other_experience' },
    ],
  }
}

const writeToClipboard = text => {
  navigator.clipboard.writeText(text).then(
    () => AntMessage.success('Data copied to clipboard!'),
    () => AntMessage.error('Failed to copy data!'),
  )
}

const onCopyColumn = (volunteers, type, columnKey) => {
  let columnData

  switch (type) {
    case 'iqama':
      if (columnKey === 'expiry_date') columnData = volunteers.map(row => row.iqama.expiry_date_gregorian || row.iqama.expiry_date).join('\n')
      else columnData = volunteers.map(row => row.iqama[columnKey]).join('\n')
      writeToClipboard(columnData)
      break

    case 'volunteer':
      if (['mobile', 'whatsapp', 'emergency_contact_no'].includes(columnKey)) {
        columnData = volunteers.map(row => row[columnKey].local).join('\n')
      } else {
        columnData = volunteers.map(row => row[columnKey]).join('\n')
      }
      writeToClipboard(columnData)
      break

    default:
      console.error('Unsupported type')
      break
  }
}

export const mapColumnKeysToColumns = (volunteers, keys, attendance, isAttendanceEnabled, changes, onChange, selectedVolunteers, deleteVolunteer) => {
  keys = Object.keys(keys).filter(key => keys[key] === true)
  const columns = []

  const customTitle = (volunteers, text, type, key) => (
    <>
      {text}
      <Tooltip title="Click to copy the column">
        &nbsp;&nbsp;&nbsp;
        <CopyOutlined onClick={() => onCopyColumn(volunteers, type, key)} />{' '}
      </Tooltip>
    </>
  )

  if (keys.includes('iqama_id'))
    columns.push({
      title: customTitle(volunteers, 'Iqama No.', 'iqama', 'registration_id'),
      dataIndex: 'iqama_id',
      key: 'iqama_id',
      width: 100,
      fixed: 'left',
      align: 'center',
      render: (text, record) => (
        <span
          style={{ color: '#00a65a', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => window.open(`#/manage/volunteers/profile/${base64.encode(record.id)}`, '_blank')}>
          {record?.iqama?.registration_id}
        </span>
      ),
    })

  if (keys.includes('full_name'))
    columns.push({
      title: customTitle(volunteers, 'Name', 'volunteer', 'full_name'),
      dataIndex: 'full_name',
      key: 'full_name',
      width: 200,
      fixed: 'left',

      render: (text, record) => (
        <span
          style={{ color: '#00a65a', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => window.open(`#/manage/volunteers/profile/${base64.encode(record.id)}`, '_blank')}>
          {record?.full_name}
        </span>
      ),
    })

  if (keys.includes('registration_date'))
    columns.push({
      title: 'Reg. Date',
      key: 'register_at',
      width: 120,
      sorter: (a, b) => {
        if (a.registration_date && b.registration_date) return new Date(a.registration_date) - new Date(b.registration_date)
      },

      render: (text, record) => (record.registration_date ? dayjs(record.registration_date).format('ddd, DD MMM YYYY [at] h:mm a') : '-'),
    })

  if (keys.includes('expiry_date'))
    columns.push({
      title: customTitle(volunteers, 'Iqama Expiry Date', 'iqama', 'expiry_date'),
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      width: 100,
      align: 'center',

      render: (text, { iqama }) => {
        const { expiry_date, expiry_date_gregorian } = iqama
        const format = 'iYYYY-iMM-iDD'
        const expiryDate = expiry_date_gregorian || expiry_date

        if (expiryDate) {
          const iqamaExpiryWithMessage = message => {
            return (
              <div className="d-flex justify-content-center align-items-center" style={{ color: 'red', cursor: 'pointer' }}>
                <Tooltip title={message}>
                  <span className="mr-2 mt-1">{expiryDate}</span>
                  <ExclamationCircleOutlined style={{ fontSize: 18 }} />
                </Tooltip>
              </div>
            )
          }

          function daysToMonthsAndDays(days) {
            const months = Math.floor(days / 30)
            const remainingDays = days % 30
            return { months, remainingDays }
          }

          // Calculate Hajj date and expiry date
          const hajjDate = momentHijri(`${momentHijri().iYear()}-12-10`, format)
          const expiry = momentHijri(expiryDate, expiry_date_gregorian ? undefined : format)

          let dateDifference = expiry.diff(hajjDate, 'days')
          const today = moment()
          if (today.isAfter(expiry)) {
            return iqamaExpiryWithMessage('The iqama has expired')
          }
          // Calculate date difference
          else if (dateDifference < 0) {
            const { months, remainingDays } = daysToMonthsAndDays(dateDifference * -1)
            return iqamaExpiryWithMessage(
              `Iqama will expire ${months > 0 ? (months === 1 ? `${months} month ` : `${months} months `) : ''}${months > 0 && remainingDays > 0 ? ' and ' : ''}${
                remainingDays > 0 ? (remainingDays === 1 ? `${remainingDays} day` : `${remainingDays} days `) : ''
              } before Hajj`,
            )
          }
          return expiryDate
        }
      },
    })

  if (keys.includes('mobile'))
    columns.push({
      title: customTitle(volunteers, 'Mobile', 'volunteer', 'mobile'),

      dataIndex: 'mobile',
      key: 'mobile',
      width: 120,
      render: (text, record) => record.mobile.local,
    })
  if (keys.includes('whatsapp'))
    columns.push({
      title: customTitle(volunteers, 'WhatsApp', 'volunteer', 'whatsapp'),
      dataIndex: 'whatsapp',
      key: 'whatsapp',
      width: 120,
      render: (text, record) => record.whatsapp.local,
    })
  if (keys.includes('teams'))
    columns.push({
      title: 'Teams',
      dataIndex: 'teams',
      key: 'teams',
      width: 150,
      render: (text, record) => (record.teams ? pluck(record.teams, 'name').join(', ') : 'Not Assigned'),
    })
  if (keys.includes('status'))
    columns.push({
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',

      width: 100,
      render: text => (text ? <CheckMark /> : <CheckMark type="unchecked" />),
    })
  if (keys.includes('verified'))
    columns.push({
      title: 'Verified',
      dataIndex: 'verified',
      key: 'verified',
      align: 'center',

      width: 100,
      render: text => (text ? <CheckMark /> : <CheckMark type="unchecked" />),
    })
  if (keys.includes('email')) columns.push({ title: 'Email', dataIndex: 'email', key: 'email', width: 150, render: (text, record) => record.user.email })

  if (keys.includes('blood_grp'))
    columns.push({
      title: 'Blood Group',
      dataIndex: 'blood_grp',
      key: 'blood_grp',
      align: 'center',

      width: 100,
    })
  if (keys.includes('emergency_contact_name'))
    columns.push({
      title: customTitle(volunteers, 'Emergency Contact Name', 'volunteer', 'emergency_contact_name'),
      dataIndex: 'emergency_contact_name',
      key: 'emergency_contact_name',
      width: 200,
    })
  if (keys.includes('emergency_contact_no'))
    columns.push({
      title: customTitle(volunteers, 'Emergency Contact No.', 'volunteer', 'emergency_contact_no'),
      dataIndex: 'emergency_contact_no',
      key: 'emergency_contact_no',
      align: 'center',
      width: 150,
      render: (text, record) => record.emergency_contact_no.local,
    })
  if (keys.includes('job_title')) columns.push({ title: 'Job Title', dataIndex: 'job_title', key: 'job_title', width: 150 })
  if (keys.includes('education'))
    columns.push({ title: 'Education', dataIndex: 'education', key: 'education', align: 'center', width: 150, render: (text, record) => record.education.name })
  if (keys.includes('date_of_birth'))
    columns.push({ title: customTitle(volunteers, 'Date of Birth', 'volunteer', 'date_of_birth'), dataIndex: 'date_of_birth', width: 150, key: 'date_of_birth' })
  if (keys.includes('language'))
    columns.push({ title: 'Languages', dataIndex: 'language', key: 'language', align: 'center', width: 200, render: (text, record) => record.language.join(', ') })
  if (keys.includes('city'))
    columns.push({ title: 'City', dataIndex: 'city', key: 'city', align: 'center', align: 'center', width: 50, render: (text, record) => record?.city?.name || 'No City' })
  if (keys.includes('phvg_experience'))
    columns.push({ title: 'PHVG Experience', dataIndex: 'phvg_experience', key: 'phvg_experience', align: 'center', align: 'center', width: 150 })
  if (keys.includes('phvg_mina_experience'))
    columns.push({ title: 'PHVG Mina Experience', dataIndex: 'phvg_mina_experience', key: 'phvg_mina_experience', align: 'center', width: 150 })
  if (keys.includes('other_experience')) columns.push({ title: 'Other Experience', dataIndex: 'other_experience', key: 'other_experience', align: 'center', width: 150 })

  if (keys.includes('selected_project_names'))
    columns.push({
      title: 'Selected Projects',
      dataIndex: 'selected_project_names',
      key: 'selected_project_names',
      width: 300,
      render: (text, record) => record.selected_project_names.join(', '),
    })
  attendance.forEach(attendanceItem => {
    if (keys.includes(attendanceItem.key)) {
      columns.push({
        title: attendanceItem.name,
        dataIndex: `attendance.${attendanceItem.key}`,
        key: attendanceItem.key,
        width: 150,
        align: 'center',
        render: (text, record) => {
          if (isAttendanceEnabled) {
            const attendanceRecord = record.attendance.find(item => item.key === attendanceItem.key)

            const backendStatus = attendanceRecord.status
            const changeForRecord = changes.find(change => change.id === record.id)
            const isChanged = changeForRecord && changeForRecord.attendance.includes(attendanceItem.key)

            const isChecked = (backendStatus && !isChanged) || (!backendStatus && isChanged)

            return (
              <Checkbox disabled={!selectedVolunteers.includes(record.id)} checked={isChecked} onChange={() => markAttendance(attendanceItem.key, record.id, changes, onChange)} />
            )
          }
          return record.attendance.find(item => item.key === attendanceItem.key).status ? <CheckMark /> : <CheckMark type="unchecked" />
        },
      })
    }
  })

  columns.push({
    title: 'Actions',
    key: 'actions',
    width: 100,
    fixed: 'right',
    render: (text, record) => (
      <span>
        <Link to={`/manage/volunteers/profile/${base64.encode(record.id)}`}>
          <Button type="link">
            <EyeOutlined />
          </Button>
        </Link>
        <Dropdown
          menu={{
            items: [
              // TODO: Issue with the volunteer edit section, will be addressed when working on profile changes
              { key: '1', label: <Link to={`/manage/volunteers/edit/${base64.encode(record.id)}`}>Edit Volunteer</Link> },
              { key: '2', label: <Link to={`/manage/iqama/edit/${base64.encode(record?.iqama?.id)}`}>Edit Iqama</Link> },
              { key: '3', label: <Link to={`/manage/user/edit/${base64.encode(record?.user?.id)}`}>Edit User</Link> },
            ],
          }}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}>
          <Button type="link">
            <EditOutlined />
          </Button>
        </Dropdown>

        <Popconfirm title="Are you sure you want to delete this volunteer?" onConfirm={() => deleteVolunteer(record.id)} okText="Yes" cancelText="No">
          <Button type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      </span>
    ),
  })
  return columns
}
const markAttendance = (key, volunteerID, changes, onChange) => {
  let attendanceArray = [...changes]
  let activeVolunteer = attendanceArray.find(item => item.id === volunteerID)
  if (activeVolunteer) {
    if (!activeVolunteer.attendance.includes(key)) {
      activeVolunteer.attendance.push(key)
    } else {
      let index = activeVolunteer.attendance.findIndex(item => item === key)
      activeVolunteer.attendance.splice(index, 1)
    }
  } else {
    let data = {
      id: volunteerID,
      attendance: [key],
    }
    attendanceArray.push(data)
  }
  onChange(attendanceArray)
}

const mapLabelKeyToText = (type, key) => {
  const errorMessages = {
    volunteer_image: 'VOLUNTEER_PICTURE_ERROR_REQUIRED',
    iqama_image: 'IQAMA_PICTURE_ERROR_REQUIRED',
    first_name: 'FIRST_NAME_ERROR_REQUIRED',
    last_name: 'LAST_NAME_ERROR_REQUIRED',
    date_of_birth: 'DATE_OF_BIRTH_ERROR_REQUIRED',
    mobile: 'MOBILE_NUMBER_ERROR_REQUIRED',
    whatsapp: 'WHATSAPP_NUMBER_REQUIRED',
    city_id: 'CITY_ERROR_REQUIRED',
    city_camp_id: 'CAMP_ERROR_REQUIRED',
    district_id: 'DISTRICT_ERROR_REQUIRED',
    language: 'LANGUAGES_ERROR_REQUIRED',
    education_id: 'EDUCATION_ERROR_REQUIRED',
    blood_grp: 'BLOOD_GROUP_ERROR_REQUIRED',
    job_title: 'JOB_TITLE_ERROR_REQUIRED',
    company_name: 'COMPANY_ERROR_REQUIRED',
    job_experience: 'EXPERIENCE_ERROR_REQUIRED',
    phvg_experience: 'EXPERIENCE_ERROR_REQUIRED',
    phvg_mina_experience: 'EXPERIENCE_ERROR_REQUIRED',
    other_experience: 'EXPERIENCE_ERROR_REQUIRED',
    emergency_contact_name: 'EMERGENCY_CONTACT_NAME_ERROR_REQUIRED',
    emergency_contact_no: 'EMERGENCY_CONTACT_NUMBER_ERROR_REQUIRED',
  }

  if (type === 'error' && errorMessages[key]) {
    return errorMessages[key]
  }
  return ''
}

export const getTranslationsText = (key, language, type) => {
  if (type) {
    const fieldText = mapLabelKeyToText(type, key)

    const label = language === 'english' ? ENGLISH_LABELS[fieldText] : URDU_LABELS[fieldText]
    return label || ''
  } else {
    const label = language === 'english' ? ENGLISH_LABELS[key] : URDU_LABELS[key]
    return label || ''
  }
}

export const formatMobileNumber = number => {
  if (!number) return null
  if (number.startsWith('+')) return number.slice(1)
  if (number.startsWith('05')) return `966${number.slice(1)}`
}

export const volunteerEditFields = [
  { colSpan: 24, english: 'Volunteer Picture', key: 'volunteer_image', type: 'image', aspect: 1, required: true },
  { colSpan: 12, english: 'First Name', key: 'first_name', type: 'string', required: true },
  { colSpan: 12, english: 'Last Name', key: 'last_name', type: 'string', required: true },
  { colSpan: 12, english: 'Mobile Number', key: 'mobile', type: 'phone', countryCodes: [{ name: '966 (Saudi Arabia)', value: '966' }], required: true },
  {
    colSpan: 12,
    english: 'WhatsApp Number',
    key: 'whatsapp',
    type: 'phone',
    countryCodes: [
      { name: '92 (Pakistan)', value: '92' },
      { name: '966 (Saudi Arabia)', value: '966' },
    ],
    required: true,
  },
  { colSpan: 12, english: 'Date of Birth', key: 'date_of_birth', type: 'calendar', required: true },
  { colSpan: 12, english: 'Project', key: 'selected_project_ids', type: 'dropdown', mode: 'multiple', options: 'projects', required: true },
  { colSpan: 12, english: 'City', key: 'city_id', type: 'dropdown', options: 'cities', required: true },
  { colSpan: 12, english: 'City Camp', key: 'city_camp_id', type: 'dropdown', options: 'city_camps', required: false },
  { colSpan: 12, english: 'District', key: 'district_id', type: 'dropdown', options: 'district', required: false },
  { colSpan: 12, english: 'Language', key: 'language', type: 'dropdown', mode: 'multiple', options: 'languages', required: true },
  { colSpan: 12, english: 'Education', key: 'education_id', type: 'dropdown', options: 'education', required: true },
  { colSpan: 12, english: 'Blood Group', key: 'blood_grp', type: 'dropdown', options: 'blood_groups', required: true },

  { colSpan: 12, english: 'Emergency Contact Name', key: 'emergency_contact_name', type: 'string', required: true },
  {
    colSpan: 12,
    english: 'Emergency Contact Number',
    key: 'emergency_contact_no',
    type: 'phone',
    countryCodes: [
      { name: '92 (Pakistan)', value: '92' },
      { name: '966 (Saudi Arabia)', value: '966' },
    ],
    required: true,
  },
  { colSpan: 12, english: 'Job Title', key: 'job_title', type: 'dropdown', options: 'job_titles', required: true },
  { colSpan: 12, english: 'Company', key: 'company_name', type: 'string', required: false },
  { colSpan: 12, english: 'Job Experience', key: 'job_experience', type: 'number', required: true },
  { colSpan: 12, english: 'PHVG Experience', key: 'phvg_experience', type: 'number', required: true },
  { colSpan: 12, english: 'PHVG MINA Experience', key: 'phvg_mina_experience', type: 'number', required: true },
  { colSpan: 12, english: 'Other Experience', key: 'other_experience', type: 'number', required: false },

  { colSpan: 24, english: 'Driving License', key: 'has_driving_license', type: 'checkbox', required: false },
  { colSpan: 24, english: 'Driving License Image', key: 'driving_license_image', type: 'image', aspect: 2, required: true },
  { colSpan: 12, english: 'Do you own a car', key: 'car', type: 'checkbox', required: false },
  { colSpan: 12, english: 'Are you willing to take your car to Makkah', key: 'car_availability', type: 'checkbox', required: false },
  { colSpan: 24, english: 'Car Registration Image', key: 'car_registration_image', type: 'image', aspect: 2, required: true },
  { colSpan: 6, english: 'Update Profile Image', key: 'picture_should_update', type: 'checkbox', required: false },
  { colSpan: 6, english: 'Update Iqama Image', key: 'iqama_picture_should_update', type: 'checkbox', required: false },
  { colSpan: 6, english: 'Update Iqama Expiry', key: 'iqama_expiry_should_update', type: 'checkbox', required: false },
  { colSpan: 6, english: 'Active', key: 'status', type: 'checkbox', required: false },
  { colSpan: 6, english: 'Verified', key: 'verified', type: 'checkbox', required: false },
  { colSpan: 6, english: 'Allow More Attempts', key: 'allow_next_attempt', type: 'checkbox', required: false },
  { colSpan: 6, english: 'Map Reader', key: 'map_reader', type: 'checkbox', required: false },
  { colSpan: 6, english: 'Test Applicable', key: 'is_test_applicable', type: 'checkbox', required: false },

  { colSpan: 6, english: 'Hajj Performed', key: 'is_performed_hajj', type: 'checkbox', required: false },
  { colSpan: 6, english: 'Vaccinated', key: 'vaccination', type: 'checkbox', required: false },
]

export const volunteerfield = [
  'volunteer_image',
  'first_name',
  'last_name',
  'mobile',
  'whatsapp',
  'date_of_birth',
  'selected_project_ids',
  'city_id',
  'city_camp_id',
  'district_id',
  'language',
  'education_id',
  'blood_grp',
  'emergency_contact_name',
  'emergency_contact_no',
  'job_title',
  'company_name',
  'job_experience',
  'phvg_experience',
  'phvg_mina_experience',
  'other_experience',
  'has_driving_license',
  'driving_license_image',
  'car',
  'car_availability',
  'car_registration_image',
  'is_performed_hajj',
  'vaccination',
]
