import React from 'react';
import Content from '../ErrorScreen/Content/index';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    componentDidCatch(error, errorInfo) {
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
    }
    
    render() {
      if (this.state.errorInfo) {        
        return (
          <Content errorMessage = "There was an error fetching information for you. Please try again later"/>
        );
      }
      return this.props.children;
    }  
}

export default ErrorBoundary