import localforage from 'localforage'

export var user = localforage.createInstance({
  name: 'user',
})

export var testProgress = localforage.createInstance({
  name: 'volunteer_id',
})

export var volunteerTest = localforage.createInstance({
  name: 'test',
})

export var token = localforage.createInstance({
  name: 'token',
})

export var teamVolunteer = localforage.createInstance({
  name: 'teamVolunteer',
})

export var poles = localforage.createInstance({
  name: 'poles',
})

export var buildings = localforage.createInstance({
  name: 'buildings',
})
export var building = localforage.createInstance({
  name: 'building',
})

export var posts = localforage.createInstance({
  name: 'posts',
})

export var pole = localforage.createInstance({
  name: 'pole',
})


export var training = localforage.createInstance({
  name: 'training',
})

export var trainings = localforage.createInstance({
  name: 'trainings',
})

export var post = localforage.createInstance({
  name: 'post',
})

export var teams = localforage.createInstance({
  name: 'teams',
})

export var volunteerColumns = localforage.createInstance({
  name: 'volunteerColumns',
})

export var volunteersProfile = localforage.createInstance({
  name: 'volunteer_profile',
})

export var searchQueries = localforage.createInstance({
  name: 'query',
})
