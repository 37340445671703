import React from 'react'
import { Col, Row, Spin } from 'antd'
import { AgeFromDate } from 'age-calculator'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import AttendancePanel from './AttendancePanel'
import OtherInformation from './OtherInformation'
import TeamAndProjectInformation from './TeamAndProjectInformation'
import TestInformation from './TestInformation'

import { encodeIdAsync } from '../../../../../helpers/methods.helper'
import './style.scss'

class DashboardInformation extends React.Component {
  state = {
    isLoading: true,
  }

  currentDate(dob) {
    if (!dob) return undefined

    let date = dob.toString().split('-')
    let ageFromDate = new AgeFromDate(
      new Date(parseInt(date[0], 10), parseInt(date[1], 10) - 1, parseInt(date[2], 10)),
    ).age
    return ageFromDate
  }

  render() {
    const { user, teams, projects, test } = this.props.user
    const { goToTeam } = this.props
    if (user) {
      let { user: volunteer } = this.props
      return (
        <div>
          <Row gutter={[12, 12]} style={{ marginBottom: 12 }}>
            <Col xs={24} lg={24} xl={24}>
              <TeamAndProjectInformation  selectedProjects={volunteer.selected_project_names} teams={teams} projects={projects} goToTeam={goToTeam} />
            </Col>
          </Row>
          <Row gutter={[12, 12]} style={{ marginBottom: 12 }}>
            <Col xs={24} lg={6} xl={6}>
              <AttendancePanel volunteer={volunteer} />
            </Col>
            <Col xs={24} lg={18} xl={18}>
              <TestInformation tests={test} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <OtherInformation volunteer={volunteer} currentDate={this.currentDate} />
            </Col>
          </Row>
        </div>
      )
    } else {
      return (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )
    }
  }
}
const mapStateToProps = state => ({
  data: state,
})

const mapDispatchToProps = dispatch => ({
  goToTeam: async id => {
    const encodedId = await encodeIdAsync(id)
    dispatch(push('/manage/teams/' + encodedId))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(DashboardInformation)
