import _ from 'lodash';
import React from 'react';
import { Col, Row } from 'antd';
import CheckMark from '../../../../../components/CustomComponents/CheckMark/CheckMark';
import moment from 'moment';

const ProjectInformation = ({ project }) => {
  return (
    <div className='info-container mb-3'>
      <h6 className='text-center info__header'>Project Information</h6>
      <hr />
      {project ? (
        <>
          <Row>
            <Col span={6}>
              <label className='info__title'>Project Name</label>
            </Col>
            <Col span={6}>{project.name}</Col>
            <Col span={6}>
              <label className='info__title'>Active</label>
            </Col>
            <Col span={6}>
              {project.active ? <CheckMark /> : <CheckMark type='unchecked' />}
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <label className='info__title'>Start Date</label>
            </Col>
            <Col span={6}>
              {moment(project.start_date).format('ddd, DD MMM YYYY')}
            </Col>
            <Col span={6}>
              <label className='info__title'>End Date</label>
            </Col>
            <Col span={6}>
              {moment(project.end_date).format('ddd, DD MMM YYYY')}
            </Col>
          </Row>
        </>
      ) : (
        <div className='d-flex justify-content-center align-items-center h-100'>
          <label className='text-center info__title'>
            No Project Information.
          </label>
        </div>
      )}
    </div>
  );
};

export default ProjectInformation;
