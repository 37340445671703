import { cityCamps as cityCampsConstants } from '../constants/app.constants'
import { getData } from '../helpers/request.helper'
import { URL_PATH } from '../constants/api.constants'

export const getCityCamps = id => {
  return async dispatch => {
    let cityCamps = await getData('GET', URL_PATH.CITY_CAMPS)
    if (cityCamps && cityCamps.success) {
      dispatch({
        type: cityCampsConstants.SET_CITY_CAMPS,
        cityCamps: cityCamps.camps,
      })
    }
  }
}

export const getCityCamp = id => {
  return async dispatch => {
    let cityCamp = await getData('GET', URL_PATH.CITY_CAMPS + id)
    if (cityCamp && cityCamp.success) {
      dispatch({
        type: cityCampsConstants.SET_CITY_CAMP,
        cityCamp: cityCamp.camp,
      })
    }
  }
}
