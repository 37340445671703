import React, { useState } from 'react'
import { qrCode } from '../../../helpers/data.helper'
import { Modal, Button } from 'antd'
import { QrcodeOutlined } from '@ant-design/icons'
const QrModal = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false)

  const showModal = () => {
    setIsOpen(true)
  }
  const closeModal = () => {
    setIsOpen(false)
  }
  return (
    <React.Fragment>
      <Button className="mr-2" icon={<QrcodeOutlined />} onClick={showModal}>
        QR Code
      </Button>
      <Modal
        open={isOpen}
        onCancel={closeModal}
        footer={
          <Button key="back" onClick={closeModal}>
            Close
          </Button>
        }
        centered
        width={300}
        title="QR Code"
      >
        <div className="d-flex justify-content-center align-items-center">
          {user ? qrCode(user) : null}
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default QrModal
