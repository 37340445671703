import React, { useState } from 'react'
import { Spin, Tag, Typography } from 'antd'

import './styles.scss'

const { Text } = Typography

const PersonProfileSection = ({ user }) => {
  const [volunteerImageLoaded, setVolunteerImageLoaded] = useState(false)
  const [volunteerImageError, setVolunteerImageError] = useState(false)

  const status = user => {
    const { status, verified } = user
    return (
      <>
        {status ? <Tag color="success">Active</Tag> : <Tag color="error">Inactive</Tag>}
        {verified ? <Tag color="success">Verified</Tag> : <Tag color="error">Unverified</Tag>}
      </>
    )
  }

  const badges = user => {
    const { has_passed_test, is_performed_hajj, kit, car, vaccination, verified } = user
    return (
      <div>
        {is_performed_hajj && <Tag>Performed Hajj</Tag>}
        {has_passed_test && <Tag>Passed Test</Tag>}
        {kit && <Tag>Kit Available</Tag>}
        {car && <Tag>Car Available</Tag>}
        {vaccination && <Tag>Vaccinated</Tag>}
        {verified && <Tag>Verified</Tag>}
      </div>
    )
  }

  const ProfileImage = user => {
    const src = user?.volunteer_image || 'resources/images/avatars/person.png'
    return (
      <Spin spinning={!volunteerImageLoaded}>
        {!volunteerImageError ? (
          <img src={src} onError={() => setVolunteerImageError(true)} onLoad={() => setVolunteerImageLoaded(true)} alt="User Profile Picture" className="profile-image mr-3" />
        ) : (
          <img src={'resources/images/avatars/person.png'} onLoad={() => setVolunteerImageLoaded(true)} alt="User Profile Picture" className="profile-image mr-3" />
        )}
      </Spin>
    )
  }

  return (
    <div className="d-flex">
      {ProfileImage(user)}
      <div>
        <Text strong style={{ fontSize: 18 }} className="mr-3">
          {user.full_name}
        </Text>
        {status(user)}
        <br />
        <Text className="">{`${user.user.role} ${user?.team?.name || ''}`}</Text>
        <br />
        <Text>
          {user.job_title} at <strong>{user.company_name}</strong>
        </Text>
        <br />
        {user?.city && (
          <>
            <Text>
              <i class="fa fa-map-marker mr-2" style={{ color: '#00a65a' }} />
              {user.city.name}
            </Text>
            <br />
          </>
        )}
        <Text>
          <i class="fa fa-phone mr-2" style={{ color: '#00a65a' }} />
          {user.mobile.local}
        </Text>
        <Text>
          <i class="fa fa-whatsapp mx-2" style={{ color: '#00a65a' }} />
          {user.whatsapp.local}
        </Text>
        {badges(user)}
      </div>
    </div>
  )
}

export default PersonProfileSection
