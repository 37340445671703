import React from 'react'
import { connect } from 'react-redux'
import { logout } from 'ducks/app'
import { BellOutlined, SettingOutlined, UserOutlined, TeamOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar, Badge, Spin, Button } from 'antd'
import { user as userStorage } from '../../../../localstorage/localstorage'
import { push } from 'react-router-redux'
import VolunteerData from '../../../../pages/Manage/Volunteer/VolunteerProfile/VolunteerData'

const mapDispatchToProps = dispatch => ({
  logout: event => {
    event.preventDefault()
    dispatch(logout())
  },
  goToProfile: () => {
    dispatch(push('/profile'))
  },
  goToSettings: () => {
    dispatch(push('/settings'))
  },
  goToNotifications: () => {
    dispatch(push('/send-notifications'))
  },
  goToLostHaji: () => {
    dispatch(push('/lost-hujjaj'))
  },
  goToPhvgHajjNav: () => {
    dispatch(push('/phvg-hajj-navigator'))
  },
})

const mapStateToProps = (state, props) => ({
  userState: state.app.userState,
})

class ProfileMenu extends React.Component {
  state = {
    isLoading: true,
    userObject: '',
  }

  async componentDidMount() {
    let user = await userStorage.getItem('user')
    if (user) this.setState({ isLoading: false, userObject: user })
  }

  render() {
    const {
      userState,
      logout,
      goToProfile,
      goToSettings,
      goToNotifications,
      goToLostHaji,
      goToPhvgHajjNav,
    } = this.props
    if (!this.state.isLoading) {
      const { iqama, user } = this.state.userObject
      let volunteer = this.state.userObject
      const menu = (
        <Menu selectable={false}>
          <Menu.Item>
            <div className="rfq__widget__system-status__item">
              <strong>Hello, {volunteer.first_name}</strong>
              <div>
                <strong>Iqama ID: </strong> {iqama.registration_id}
                <br />
              </div>

              <div>
                <strong>Role: </strong> {user.role}
              </div>
            </div>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item>
            <div className="rfq__widget__system-status__item">
              <strong>Email: </strong> {user.email}
              <br />
              <strong>Phone: </strong>{' '}
              {volunteer && volunteer.mobile ? volunteer.mobile.international : '-'}
            </div>
          </Menu.Item>
          <Menu.Divider />
          {userState.role !== 'Volunteer' ? (
            <Menu.Item onClick={() => goToProfile()}>
              <div className="rfq__widget__system-status__item">
                <UserOutlined style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                <span style={{ verticalAlign: 'middle' }}>View Profile</span>
              </div>
            </Menu.Item>
          ) : (
            undefined
          )}
          {/* {userState.role === 'Super Admin' ? (
            <Menu.Item onClick={() => goToNotifications()}>
              <div className="rfq__widget__system-status__item">
                <BellOutlined style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                <span style={{ verticalAlign: 'middle' }}>Notification Center</span>
              </div>
            </Menu.Item>
          ) : (
            undefined
          )}
          {userState.role === 'Super Admin' || userState.role === 'Admin' ? (
            <Menu.Item onClick={() => goToLostHaji()}>
              <div className="rfq__widget__system-status__item">
                <TeamOutlined style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                <span style={{ verticalAlign: 'middle' }}>Lost Hujjaj</span>
              </div>
            </Menu.Item>
          ) : (
            undefined
          )} */}
          {userState.role === 'Super Admin' ? (
            <Menu.Item onClick={() => goToSettings()}>
              <div className="rfq__widget__system-status__item">
                <SettingOutlined style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                <span style={{ verticalAlign: 'middle' }}>Settings</span>
              </div>
            </Menu.Item>
          ) : (
            undefined
          )}
          {/* {userState.role === 'Super Admin' && <Menu.Divider />}
          {userState.role === 'Super Admin' ? (
            <Menu.Item onClick={() => goToPhvgHajjNav()}>
              <div className="rfq__widget__system-status__item"> */}
                {/* <SettingOutlined style={{verticalAlign :'middle', marginRight: '5px'}} />  */}
                {/* <span style={{ verticalAlign: 'middle' }}>PHVG Hajj Navigator</span>
              </div>
            </Menu.Item>
          ) : (
            undefined
          )}
          {userState.role === 'Super Admin' && <Menu.Divider />} */}

          <Menu.Item>
            <a href="javascript: void(0);" onClick={logout}>
              <i className="topbar__dropdownMenuIcon icmn-exit" /> Logout
            </a>
          </Menu.Item>
        </Menu>
      )
      return (
        <div className="topbar__dropdown d-inline-block">
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <Button className="topbar__avatar">{user.role}</Button>
          </Dropdown>
        </div>
      )
    } else {
      return (
        <div className="text-center">
          <Spin size="small" />
        </div>
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu)
