import React from 'react'
import './style.scss'
import { Spin } from 'antd'
import { formatDate, convertToHijri } from '../../../helpers/methods.helper'
class PersonIqamaCard extends React.Component {
  state = {
    iqamaPicture: false,
    iqamaImageLoaded: false,
    iqamaImageError: false,
  }

  IqamaImage = user => {
    return (
      <React.Fragment>
        <Spin spinning={!this.state.iqamaImageLoaded}>
          {!this.state.iqamaImageError ? (
            <img
              className="Iqama-img-wrapped"
              onLoad={() => this.setState({ iqamaImageLoaded: true })}
              onError={() => this.setState({ iqamaImageError: true })}
              src={user.iqama && user.iqama.iqama_image ? user.iqama.iqama_image : 'resources/images/avatars/iqama.png'}
              alt=""
            />
          ) : (
            <img className="Iqama-img-wrapped" onLoad={() => this.setState({ iqamaImageLoaded: true })} src={'resources/images/avatars/iqama.png'} alt="" />
          )}
        </Spin>
      </React.Fragment>
    )
  }
  render() {
    const { user } = this.props
    return (
      <div className="row ">
        <div className="col-12 col-xl-5 d-flex align-items-center justify-content-center">{this.IqamaImage(user)}</div>
        {user ? (
          <div className="col-12 col-xl-7">
            <div className="info-wrapper">
              <label className="person-name">Iqama Info</label>
              <label className="person-default">Iqama number: {user.iqama.registration_id}</label>
              <label className="person-default">Valid untill: {user.iqama.expiry_date_gregorian || formatDate(convertToHijri(user.iqama.expiry_date))}</label>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default PersonIqamaCard
