import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setUpdatingContent } from 'ducks/app'
import { isEmpty } from 'lodash'
import Breadcrumb from 'components/LayoutComponents/Breadcrumb'
import { withRouter } from 'react-router-dom'

class AppContent extends React.Component {
  static contextTypes = {
    getContentBuffer: PropTypes.func,
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.isUpdatingContent && !nextProps.isUpdatingContent) {
      return false
    }
    return true
  }

  componentDidUpdate() {
    const { isUpdatingContent, dispatch } = this.props
    if (isUpdatingContent) {
      dispatch(setUpdatingContent(false))
    }
  }

  render() {
    const { getContentBuffer } = this.context
    const { pathName, content } = getContentBuffer()
    const { pathname } = this.props.location
    return isEmpty(content) ? (
      <div className="utils__loadingPage" />
    ) : (
      <div className="utils__content" style={{ height: '100%' }}>
        {pathname === '/registration' || pathname === '/login' || pathname === '/forgot-password' || pathname === '/unauthorized' || pathname === '/map' ? undefined : (
          <Breadcrumb name={pathName} />
        )}
        {content}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  isUpdatingContent: state.app.isUpdatingContent,
})

export default connect(mapStateToProps)(withRouter(AppContent))
