const initialState = {
  notifications: {
    visible: false,
    loading: true,
    unreadNotifications: [],
    notificationsCount: 0,
    readNotifications: [],
    loadMaximumNotifications: false,
    seeMoreLoading: false,
  },
  volunteers: {
    loading: false,
    list: [],
  },
  teamVolunteers: {
    loading: false,
    list: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'AUTHENTICATED':
      return {
        ...state,
        authenticated: true,
        message: action.message,
        volunteerData: action.response,
      }
    case 'SET_POLES':
      return {
        ...state,
        poles: action.poles,
      }
    case 'SET_BUILDING':
      return {
        ...state,
        building: action.building,
      }

      case 'SET_BUILDINGS':
      return {
        ...state,
        buildings: action.buildings,
      }
    case 'SET_POLE':
      return {
        ...state,
        pole: action.pole,
      }
    case 'SET_POSTS':
      return {
        ...state,
        posts: action.posts,
      }
    case 'SET_POST':
      return {
        ...state,
        post: action.post,
      }
      case 'SET_TRAININGS':
      return {
        ...state,
        trainings: action.trainings,
      }
    case 'SET_TRAINING':
      return {
        ...state,
        training: action.training,
      }
      case 'SET_PROJECTS':
      return {
        ...state,
        projects: action.projects,
      }
    case 'SET_PROJECT':
      return {
        ...state,
        project: action.project,
      }
    case 'SET_REQUIRED_DATA':
      return {
        ...state,
        requiredData: action.requiredData,
      }
      

    // Volunteer
    case 'SET_VOLUNTEER':
      return {
        ...state,
        volunteer: action.volunteer,
      }
    case 'SET_VOLUNTEERS':
      return {
        ...state,
        volunteers: action.volunteers,
        total: action.total,
      }

    case 'SET_VOLUNTEER_AREA_INCHARGES':
      return {
        ...state,
        areaIncharges: action.areaIncharges,
      }

    case 'SET_TEAM_VOLUNTEERS_FOR_ASSIGNMENT':
      return {
        ...state,
        teamVolunteers: action.teamVolunteers,
      }
    case 'SET_IQAMA':
      return {
        ...state,
        iqama: action.iqama,
      }
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
      }
    case 'SET_TEAM':
      return {
        ...state,
        team: action.team,
      }
    case 'SET_TEAMS':
      return {
        ...state,
        teams: action.teams,
      }
    case 'SET_TEAMS_NAME':
      return {
        ...state,
        teamsName: action.teamsName,
      }
    case 'SET_CITY_CAMPS':
      return {
        ...state,
        cityCamps: action.cityCamps,
      }
    case 'SET_CITY_CAMP':
      return {
        ...state,
        cityCamp: action.cityCamp,
      }
    case 'SET_CITIES':
      return {
        ...state,
        cities: action.cities,
      }
    case 'SET_ALL_TEAMS':
      return {
        ...state,
        allTeams: action.allTeams,
      }
    case 'SET_CITY':
      return {
        ...state,
        city: action.city,
      }
    case 'SET_ACTIVITIES':
      return {
        ...state,
        activities: action.activities,
      }
    case 'SET_TESTS':
      return {
        ...state,
        tests: action.tests,
      }
    case 'SET_TEST':
      return {
        ...state,
        test: action.test,
      }
    case 'SET_TEST_PROGRESS':
      return {
        ...state,
        progress: action.progress,
      }
    case 'SET_QUESTIONS':
      return {
        ...state,
        questions: action.questions,
      }
    case 'SET_QUESTION':
      return {
        ...state,
        question: action.question,
      }
    case 'SET_CURRENT_TEST':
      return {
        ...state,
        test: action.test,
        testFound: action.testFound,
      }
    case 'SET_QUESTION_ID':
      return {
        ...state,
        questionID: action.questionID,
      }
    case 'SET_GENERIC_REPORT':
      return {
        ...state,
        report: action.report,
      }

    case 'SET_NEW_VOLUNTEER_LOCATION':
      return {
        ...state,
        newVolunteerLocations: action.newVolunteerLocations,
      }

    // Cases for rooms
    case 'SET_ROOMS':
      return {
        ...state,
        rooms: action.rooms,
      }
    case 'SET_ROOM':
      return {
        ...state,
        room: action.room,
      }

    case 'NOTIFICATIONS':
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...action.notifications,
        },
      }
    case 'GET_SETTINGS':
      return {
        ...state,
        settings: action.settings,
      }

    case 'GET_INITIAL_SHIFTS':
      return {
        ...state,
        initialShifts: action.initialShifts,
      }
    case 'GET_LAYOUT_RULES':
      return {
        ...state,
        rules: action.rules,
      }
    case 'GET_HAJJ_NAV_ASSETS':
      return {
        ...state,
        hajjNavAssets: action.assets,
      }
    case 'GET_HAJJ_NAV_SETTINGS':
      return {
        ...state,
        hajjNavSettings: action.settings,
      }
    case 'GET_HAJJ_NAV_REGIONS':
      return {
        ...state,
        hajjNavRegions: action.regions,
      }
    case 'SET_GEOFENCES':
      return {
        ...state,
        geofences: action.geofences,
      }
    case 'VOLUNTEER_IN_AREA':
      return{
        ...state,
        volunteerInArea: action.volunteerInArea,
      }
    default:
      return state
  }
}
