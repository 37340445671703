import React from 'react'
import { Spin, FloatButton, Layout as AntLayout } from 'antd'
import classNames from 'classnames'
import { Spinner } from 'react-redux-spinner'
import PropTypes from 'prop-types'
import Routes from 'routes'
import { ContainerQuery } from 'react-container-query'
import { withRouter } from 'react-router-dom'
import { enquireScreen, unenquireScreen } from 'enquire-js'

import TopBar from 'components/LayoutComponents/TopBar'
import Menu from 'components/LayoutComponents/Menu'
import Content from 'components/LayoutComponents/Content'
import Loader from 'components/LayoutComponents/Loader'
import LayoutState from 'components/LayoutComponents/LayoutState'
import SettingsSider from 'components/LayoutComponents/SettingsSider'
import { isAllowedLocation } from 'helpers/methods.helper'

const AntContent = AntLayout.Content
const AntHeader = AntLayout.Header

const query = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599,
  },
  'screen-xxl': {
    minWidth: 1600,
  },
}

const centered = {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
}

let isMobile
enquireScreen(b => {
  isMobile = b
})

let contentBuffer = {
  pathName: null,
  content: null,
}

class Layout extends React.Component {
  static childContextTypes = {
    getContentBuffer: PropTypes.func,
    setContentBuffer: PropTypes.func,
  }

  state = {
    isMobile,
    isAllowedRegistration: null,
  }

  getChildContext() {
    return {
      getContentBuffer: () => contentBuffer,
      setContentBuffer: ({ pathName, content }) => (contentBuffer = { pathName, content }),
    }
  }

  componentDidMount() {
    this.enquireHandler = enquireScreen(mobile => {
      this.setState({
        isMobile: mobile,
      })
    })
    isAllowedLocation().then(allowed => this.setState({ isAllowedRegistration: true }))
  }

  componentWillUnmount() {
    unenquireScreen(this.enquireHandler)
  }

  render() {
    const isMobile = !!this.state.isMobile
    const { pathname } = this.props.location
    const { isAllowedRegistration } = this.state

    if (isAllowedRegistration === null) {
      return (
        <div style={centered}>
          <Spin tip="Loading..." />
          <h4>Please wait, we are checking access for your country.</h4>
        </div>
      )
    }

    if (isAllowedRegistration === false) {
      return (
        <div style={centered}>
          <h4>This app doesn't function in your country.</h4>
        </div>
      )
    }

    return (
      <ContainerQuery query={query}>
        {params => (
          <div className={classNames(params)} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {pathname === '/login' || pathname === '/registration' || pathname === '/forgot-password' ? (
              <AntLayout>
                <Spinner />
                <Routes />
                <AntLayout>
                  <AntContent style={{ height: '100%' }}>
                    <Content />
                  </AntContent>
                </AntLayout>
              </AntLayout>
            ) : pathname === '/map' ? (
              <>
                <Routes />
                <AntHeader style={{ paddingInline: 0, backgroundColor: 'white', padding: '0px 20px' }}>
                  <TopBar />
                </AntHeader>
                <Content isMobile={isMobile} />
              </>
            ) : (
              <AntLayout>
                <LayoutState />
                <Loader />
                <Spinner />
                <FloatButton.BackTop />
                <Routes />
                <Menu isMobile={isMobile} />
                <SettingsSider />
                <AntLayout>
                  <AntHeader style={{ paddingInline: 0, backgroundColor: 'white', padding: '0px 20px', borderBottom: '1px solid #e4e9f0' }}>
                    <TopBar />
                  </AntHeader>
                  <AntContent style={{ height: '100%' }}>
                    <Content isMobile={isMobile} />
                  </AntContent>
                </AntLayout>
              </AntLayout>
            )}
          </div>
        )}
      </ContainerQuery>
    )
  }
}

export default withRouter(Layout)
