import React, { createContext, useEffect, useState } from 'react'
import { Spin, Typography } from 'antd'

import Layout from 'components/LayoutComponents/Layout/index'
import { getData } from 'helpers/request.helper'

export const RequiredDataContext = createContext(null)

const App = () => {
  const [requiredData, setRequiredData] = useState(null)
  useEffect(() => {
    const getRequiredData = async () => {
      const response = await getData('GET', 'registrations/required_data?all=true')
      setRequiredData(response)
    }
    getRequiredData()
  }, [])
  return (
    <>
      {requiredData ? (
        <RequiredDataContext.Provider value={requiredData}>
          <Layout />
        </RequiredDataContext.Provider>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Spin size="large" className="mb-3" />
          <Typography.Title level={3}>Please wait while we load important data from our server</Typography.Title>
        </div>
      )}
    </>
  )
}

export default App
