import React, { useState } from 'react'
import { Spin, Typography } from 'antd'

import { formatDate, convertToHijri } from '../../../helpers/methods.helper'

import './styles.scss'

const { Text } = Typography

const PersonIqamaSection = ({ user }) => {
  const [iqamaImageLoaded, setIqamaImageLoaded] = useState(false)
  const [iqamaImageError, setIqamaImageError] = useState(false)

  const IqamaImage = user => {
    const src = user.iqama && user.iqama.iqama_image ? user.iqama.iqama_image : 'resources/images/avatars/iqama.png'

    return (
      <Spin spinning={!iqamaImageLoaded}>
        {!iqamaImageError ? (
          <img onLoad={() => setIqamaImageLoaded(true)} onError={() => setIqamaImageError(true)} src={src} alt="User Iqama Picture" className="iqama-image mr-3" />
        ) : (
          <img onLoad={() => setIqamaImageLoaded(true)} src={'resources/images/avatars/iqama.png'} alt="User Iqama Picture" className="iqama-image mr-3" />
        )}
      </Spin>
    )
  }

  return (
    <div className="d-flex">
      {IqamaImage(user)}
      <div>
        <Text strong style={{ fontSize: 16 }}>
          Iqama Information
        </Text>
        <br />
        <Text strong>Iqama number: {user?.iqama?.registration_id || ''}</Text>
        <br />
        <Text strong>Valid until: {user.iqama.expiry_date_gregorian || formatDate(convertToHijri(user.iqama.expiry_date))}</Text>
      </div>
    </div>
  )
}

export default PersonIqamaSection
