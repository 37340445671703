import React from 'react'
import Notification from './Notification'
import { Divider, Button, Spin } from 'antd'

const NotificationList = props => {
  let {
    notificationsCount,
    unreadNotifications,
    readNotifications,
    loadMaximumNotifications,
    fetchmaxNotifications,
    seeMoreLoading,
  } = props

  // console.log(readNotifications)

  return (
    <div>
      {notificationsCount > 0 ? (
        <div>
          <Divider
            style={{
              color: 'green',
            }}
          >
            New
          </Divider>
          {unreadNotifications.map((v, i) => {
            return <Notification data={v} divider={i + 1 === notificationsCount ? false : true} />
          })}
        </div>
      ) : (
        ''
      )}
      {readNotifications && readNotifications.length > 0 ? (
        <div>
          <Divider>Old</Divider>
          {readNotifications.map((v, i) => {
            return (
              <Notification data={v} divider={i + 1 === readNotifications.length ? false : true} />
            )
          })}
        </div>
      ) : (
        ''
      )}
      {!loadMaximumNotifications && readNotifications && readNotifications.length > 9 ? (
        <div className="text-center">
          <Button type="link" onClick={fetchmaxNotifications}>
            See All
          </Button>
        </div>
      ) : seeMoreLoading ? (
        <div className="text-center">
          <Spin size="small"></Spin>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default NotificationList
