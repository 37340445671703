import React from 'react'
import ProfileMenu from './ProfileMenu'
import Notifications from './Notifications'
import './style.scss'

class TopBar extends React.Component {
  render() {
    return (
      <div className="topbar__right">
        <Notifications />
        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar
