import {
  volunteer as volunteerConstants,
  report as reportConstants,
} from '../constants/app.constants'
import { getData, postData } from '../helpers/request.helper'
import { serializeParams } from '../helpers/data.helper'
import { URL_PATH } from '../constants/api.constants'

export const getVolunteerData = id => {
  return async dispatch => {
    let volunteer = await getData('GET', URL_PATH.VOLUNTEER + id)
    if (volunteer && volunteer.success) {
      dispatch({
        type: volunteerConstants.SET_VOLUNTEER,
        volunteer: volunteer.volunteer,
      })
    }
  }
}

export const getVolunteersData = (params = {}) => {
  return async dispatch => {
    let response = await getData(
      'GET',
      (params.search ? URL_PATH.VOLUNTEER_SEARCH : URL_PATH.VOLUNTEER) + serializeParams(params),
    )
    if (response && response.volunteers) {
      dispatch({
        type: volunteerConstants.SET_VOLUNTEERS,
        volunteers: response.volunteers || [],
        total: response.total || 0,
      })
    }
  }
}

export const getVolunteersBySearch = (params = {}) => {
  console.log(params)
  return async dispatch => {
    let response = await postData('POST', URL_PATH.VOLUNTEER_SEARCH_BY_IQAMA, params)
    if (response && response.volunteers) {
      dispatch({
        type: volunteerConstants.SET_VOLUNTEERS,
        volunteers: response.volunteers || [],
        total: response.total || 0,
      })
    }
  }
}

export const getVolunteersDataAdvSearch = (params = {}) => {
  return async dispatch => {
    dispatch({
      type: volunteerConstants.SET_VOLUNTEERS,
      volunteers: {
        loading: true,
        list: [],
      },
    })
    let response = await getData(
      'GET',
      (params.search ? URL_PATH.VOLUNTEER_SEARCH : URL_PATH.VOLUNTEER) + serializeParams(params),
    )
    if (response && response.volunteers) {
      dispatch({
        type: volunteerConstants.SET_VOLUNTEERS,
        volunteers: {
          loading: false,
          list:
            response.volunteers.map(v => ({
              key: v.iqama.registration_id,
              name: v.full_name,
              iqama: v.iqama.registration_id,
            })) || [],
        },
      })
    }
  }
}

export const refreshStore = () => {
  return async dispatch => {
    dispatch({
      type: volunteerConstants.SET_VOLUNTEERS,
      volunteers: {
        loading: true,
        list: [],
      },
    })
  }
}

export const getVolunteersForTeam = teamID => {
  return async (dispatch, state) => {
    dispatch({
      type: volunteerConstants.SET_TEAM_VOLUNTEERS_FOR_ASSIGNMENT,
      teamVolunteers: {
        list: state().reducer.teamVolunteers ? state().reducer.teamVolunteers.list : [],
        loading: true,
      },
    })
    let response = await getData('GET', 'teams/' + teamID + '/volunteers')
    if (response && response.success) {
      dispatch({
        type: volunteerConstants.SET_TEAM_VOLUNTEERS_FOR_ASSIGNMENT,
        teamVolunteers: {
          list:
            response.volunteers.map(v => ({
              name: v.full_name,
              iqama: v.registration_id,
              role: v.role,
              id: v.id,
              team: v.team,
            })) || [],
          loading: false,
        },
      })
    }
  }
}

export const getVolunteersForAssignment = (params = {}) => {
  return async (dispatch, state) => {
    dispatch({
      type: volunteerConstants.SET_VOLUNTEERS,
      volunteers: {
        list: state().reducer.volunteers ? state().reducer.volunteers.list : [],
        loading: true,
      },
    })
    let response = await getData('GET', URL_PATH.VOLUNTEER_SEARCH + serializeParams(params))
    if (response && response.success) {
      dispatch({
        // TODO: Remove unused attributes
        type: volunteerConstants.SET_VOLUNTEERS,
        volunteers: {
          list:
            response.volunteers.map(v => ({
              name: v.full_name,
              iqama: v.iqama.registration_id,
              role: v.user.role,
              id: v.id,
              projects: v.projects,
              team: "Not Assigned",
              teamID: null,
              teams: v.teams,
            })) || [],
          loading: false,
        },
      })
    }
  }
}

export const getAreaInchargeList = () => {
  return async dispatch => {
    let response = await getData('GET', URL_PATH.VOLUNTEER_AREA_INCHARGES)
    dispatch({
      type: volunteerConstants.SET_VOLUNTEER_AREA_INCHARGES,
      areaIncharges: response.area_incharges || [],
    })
  }
}

export const getReportsData = () => {
  return async dispatch => {
    let response = await getData('GET', URL_PATH.GENERIC_REPORT)

    if (response && response.success) {
      dispatch({
        type: reportConstants.SET_GENERIC_REPORT,
        report: response.data,
      })
    }
  }
}
