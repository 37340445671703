import React from 'react'
import './styles.scss'
import { Modal, Button, Spin, Avatar, Divider, Badge } from 'antd'
import _ from 'lodash'
import PersonProfileSection from './PersonProfileSection'
import PersonIqamaSection from './PersonIqamaSection'
import { qrCode } from '../../../helpers/data.helper'
import { Col, Row } from 'antd'

class ProfileHeadCard extends React.Component {
  render() {
    var user = this.props.user
    return (
      <div>
        {user ? (
          <>
            <Row gutter={[8, 12]}>
              <Col span={24}>
                <PersonProfileSection user={user} />
              </Col>
              <Col span={24}>
                <PersonIqamaSection user={user} />
              </Col>
            </Row>

            <hr />
            <label className="qrcode-head">QR Code</label>
            <div className="d-flex justify-content-center align-items-center">{qrCode(user)}</div>
          </>
        ) : (
          <div className="text-center">
            <Spin size="small" />
          </div>
        )}
      </div>
    )
  }
}
export default ProfileHeadCard
