import React from 'react'
import './style.scss'
import { Modal, Button, Spin, Avatar, Divider, Badge, Col, Row } from 'antd'
import CheckMark from '../../../components/CustomComponents/CheckMark/CheckMark'
import PersonProfileCard from './PersonProfileCard'
import PersonIqamaCard from './PersonIqamaCard'
import _ from 'lodash'
class ProfileHeadCard extends React.Component {
  render() {
    var user = this.props.user
    return (
      <div>
        {user ? (
          <>
            <div className="row ">
              <div className="col-12 col-md-6 pb-2">
                <PersonProfileCard user={user} />
              </div>
              <div className="col-12 col-md-6 pb-2">
                <PersonIqamaCard user={user} />
              </div>
            </div>

            <Row className="mt-3">
              {user.driving_license_image && (
                <Col span={12} className="d-flex flex-column ml-4">
                  <strong style={{ fontSize: 16 }}>Driver License</strong>
                  <img src={user.driving_license_image} className="Iqama-img-wrapped" />
                </Col>
              )}
              {user.car_registration_image && (
                <Col span={5} className="d-flex flex-column ml-4">
                  <strong style={{ fontSize: 16 }} className='ml-2' I>
                    Car Registration
                  </strong>
                  <img src={user.car_registration_image} className="ml-2 Iqama-img-wrapped" />
                </Col>
              )}
            </Row>
          </>
        ) : (
          <div className="text-center">
            <Spin size="small" />
          </div>
        )}
      </div>
    )
  }
}
export default ProfileHeadCard
