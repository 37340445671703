import request from '../request/api'
import axios from 'axios'

export async function getData(method, url) {
  try {
    let value = await request({ method: method, url: url })
      .then(data => {
        return data
      })
      .catch(error => {
        return error
      })
    return value
  } catch (error) {
    return error
  }
}

export async function postData(method, url, data) {
  try {
    let value = await request({ method: method, url: url, data: data })
      .then(data => {
        return data
      })
      .catch(error => {
        return error
      })
    return value
  } catch (error) {
    return error
  }
}

export async function LocationServiceAPI(options) {
  const instance = axios.create({ baseURL: process.env.REACT_APP_LOCATION_SERVICE_URL });

  const onSuccess = response => {
    return response.data;
  };

  const onError = error => {
    console.error('Request Failed:', error.config);
    if (error.response) {
      console.error('Status:', error.response.status);
      // console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.message || error.response);
  };

  return instance(options).then(onSuccess).catch(onError);
}
