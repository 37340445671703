import React from 'react'
import { NotificationOutlined } from '@ant-design/icons'
import { Drawer, Badge, Spin, Empty } from 'antd'
import './style.scss'
import { user as userStorage } from '../../../../localstorage/localstorage'
import { connect } from 'react-redux'
import NotificationList from './NotificationList'
import {
  allReadnotifications,
  markRead,
  checkNewnotifications,
  fetchmaxNotifications,
} from '../../../../actions/notifications.action'

class Notifications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      isAdmin: false,
    }
    this.interval = null
  }

  componentDidMount() {
    if (localStorage.getItem('app.Authorization')) {
      this.props.checkNotifications()
      this.props.getNotifications(false)
      this.interval = setInterval(() => {
        this.props.checkNotifications()
      }, 60000)
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.authenticated && this.props.authenticated) {
      this.props.checkNotifications()
      this.props.getNotifications(false)
      if (this.interval == null) {
        this.interval = setInterval(() => {
          this.props.checkNotifications()
        }, 60000)
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
    this.props.markNotifications()
  }

  fetchmaxNotifications = () => {
    this.props.getAllnotifications()
  }
  render() {
    const {
      notificationsCount,
      unreadNotifications,
      readNotifications,
      loading,
      seeMoreLoading,
      loadMaximumNotifications,
    } = this.props.notifications
    const { isAdmin } = this.state

    return (
      <div>
        {isAdmin ? (
          ''
        ) : (
          <span className="Badge">
            <Badge count={notificationsCount} onClick={this.showDrawer}>
              <NotificationOutlined className="Badge-Icon" />
            </Badge>
          </span>
        )}
        <Drawer
          title="Notifications"
          placement="right"
          closable={true}
          onClose={this.onClose}
          open={this.state.visible}
          width={500}
        >
          {loading ? (
            <div rootClassName="Spinner-Container">
              <Spin size="large"></Spin>
            </div>
          ) : unreadNotifications &&
            unreadNotifications.length === 0 &&
            readNotifications &&
            readNotifications.length === 0 ? (
            <div rootClassName="text-center">
              <Empty description={'No notification records'} />
            </div>
          ) : (
            <NotificationList
              notificationsCount={notificationsCount}
              unreadNotifications={unreadNotifications}
              readNotifications={readNotifications}
              seeMoreLoading={seeMoreLoading}
              loadMaximumNotifications={loadMaximumNotifications}
              fetchmaxNotifications={this.fetchmaxNotifications}
            />
          )}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notifications: state.reducer.notifications,
  authenticated: state.reducer.authenticated,
})

const mapDispatchToProps = dispatch => ({
  checkNotifications: () => {
    dispatch(checkNewnotifications())
  },
  markNotifications: () => {
    dispatch(markRead())
  },
  getNotifications: all => {
    dispatch(allReadnotifications(all))
  },
  getAllnotifications: () => {
    dispatch(fetchmaxNotifications())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
