import React from "react";
import {
  Button,
  Spin,
  Row,
  Col,
  Tabs,
  Menu,
  Dropdown,
  Alert,
  notification,
  Steps
} from "antd";
import ProfileHeadCard from "components/CleanComponents/ProfileHeadCard";
import {
  user as userStorage,
  volunteersProfile,
  testProgress,
  volunteerTest,
} from "../../../../../localstorage/localstorage";
import { login } from "../../../../../constants/app.constants";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router-dom";
import DashboardInformation from "./information.dashboard";
import { ENGLISH_LABELS } from "../../../../../constants/labels.constants";
import "./style.scss";
import { URL_PATH } from "../../../../../constants/api.constants";
import { getData } from "../../../../../helpers/request.helper";
import RegistrationPopup from "../../../../../components/LayoutComponents/RegistrationPopup/index";
import HistoryPanel from "./historyPanel";
import { hasPermission } from "../../../../../helpers/auth.hepler";
import { encodeIdAsync } from "../../../../../helpers/methods.helper";
import QrModal from "../../../../../components/CleanComponents/QRModal/QrModal";
import RegistrationSteps from "../../../../../components/LayoutComponents/RegistrationSteps/index";

const { TabPane } = Tabs;

class VolunteerData extends React.Component {
  state = {
    volunteer: {},
    isLoading: true,
    response: {},
    isCurrentUserOrMgtRole: false,
    role: "",
  };

  componentDidMount() {
    this.updateUser(this.props);
    let { pathname: currentPath } = this.props.location;
    const role = window.localStorage.getItem("app.Role");
    if (currentPath === "/profile" || currentPath === "/dashboard") {
      this.setState({ isCurrentUser: true });
    }
    this.setState({
      role: role,
    });
  }
  componentWillReceiveProps(props) {
    this.updateUser(props);
  }

  async updateUser(properties) {
    let { pathname: currentPath } = properties.location;
    let volunteer;
    if (currentPath === "/profile" || currentPath === "/dashboard") {
      let localVolunteer = await userStorage.getItem("user");
      let response = await getData(
        "GET",
        URL_PATH.VOLUNTEER + localVolunteer.id
      );
      if (response && response.success) {
        // TODO: Confirm: Why are we checking that is the volunteer is updated or not?
        // const { volunteer } = response;
        // if (
        //   localVolunteer &&
        //   (volunteer.updated_at || volunteer.test_updated_at)
        // ) {
        //   let volunteerUpdated = new Date(volunteer.updated_at);
        //   if (
        //     volunteerUpdated > new Date(localVolunteer.updated_at) ||
        //     new Date(volunteer.test_updated_at) >
        //       new Date(localVolunteer.test_updated_at) ||
        //     !volunteer.test_updated_at
        //   ) {
        //     localVolunteer = volunteer;
        //   }
        // }
        volunteer = response.volunteer
        volunteer = await volunteersProfile.setItem(
          "volunteer_profile",
          volunteer
        );
      }
      // volunteer = localVolunteer;
    } else {
      // volunteer = properties.data
      let response = await getData(
        "GET",
        URL_PATH.VOLUNTEER + properties.volunteerId
      );
      if (response && response.success) {
        volunteer = response.volunteer;
      } else {
        notification.error({
          message: "Failed to fetch volunteer. Try again.",
        });
      }
      if (volunteer) {
        volunteer = await volunteersProfile.setItem(
          "volunteer_profile",
          volunteer
        );
      } else {
        volunteer = await volunteersProfile.getItem("volunteer_profile");
      }
    }
    if (volunteer) {
      // let isTestValid = await getData(
      //   "GET",
      //   URL_PATH.VOLUNTEER + volunteer.id + URL_PATH.VOLUNTEER_TEST_VALIDITIY
      // );
      // if (isTestValid && !isTestValid.success) {
      //   await testProgress.setItem(volunteer.id, null);
      //   await volunteerTest.setItem("test", null);
      // }
      this.setState({
        volunteer,
        isLoading: false,
        testValid: false,
        continueTest: false,
        // testValid: isTestValid.success,
        // continueTest: isTestValid.continue || false,
      });
    }
  }
  render() {
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <Spin size="large" />
        </div>
      );
    } else {
      let volunteer = this.state.volunteer;
      // TODO: Make this conditional to Test availability

      const showWarning =
        volunteer.iqama.iqama_expiry_should_update ||
        volunteer.iqama.picture_should_update ||
        volunteer.picture_should_update ||
        this.state.testValid || 
        volunteer.ask_leader_role;

      const showWarningUpdate =
        !volunteer.has_updated_iqama_info ||
        !volunteer.has_updated_volunteer_info ||
        volunteer.iqama.iqama_expiry_should_update ||
        volunteer.iqama.picture_should_update ||
        volunteer.picture_should_update;

      const menu = (
        <Menu>
          {this.state.isCurrentUser || hasPermission("Volunteer", "update") ? (
            <Menu.Item
              onClick={() =>
                this.props.goEditVolunteer(volunteer ? volunteer.id : undefined)
              }
            >
              <div>Edit Volunteer</div>
            </Menu.Item>
          ) : (
            undefined
          )}
          {this.state.isCurrentUser || hasPermission("Iqama", "update") ? (
            <Menu.Item
              onClick={() =>
                this.props.goEditIqama(
                  volunteer && volunteer.iqama ? volunteer.iqama.id : undefined
                )
              }
            >
              <div>Edit Iqama</div>
            </Menu.Item>
          ) : (
            undefined
          )}
          {this.state.isCurrentUser || hasPermission("User", "update") ? (
            <Menu.Item
              onClick={() =>
                this.props.goEditUser(
                  volunteer && volunteer.user ? volunteer.user.id : undefined
                )
              }
            >
              <div>Edit User</div>
            </Menu.Item>
          ) : (
            undefined
          )}
        </Menu>
      );
      return (
        <div>
          {/* TODO: Revamp */}
          <Row>
            <RegistrationSteps userID={volunteer.id}/>
          </Row>
          <Row>
            {showWarning ? (
              <Alert
                style={{
                  width: "100%",
                  marginBottom: "10px",
                }}
                message="Attention"
                type="warning"
                showIcon
                description={
                  <span style={{ fontSize: 15 }}>
                    {volunteer?.picture_should_update && (
                      <div>
                        Please update your{' '}
                        <span className="pop-over-text app-link" onClick={() => this.props.goEditVolunteer(volunteer.id)}>
                          volunteer image
                        </span>
                      </div>
                    )}
                    {volunteer?.iqama?.iqama_expiry_should_update && (
                      <div>
                        Please update your{' '}
                        <span className="pop-over-text app-link" onClick={() => this.props.goEditIqama(volunteer.iqama.id)}>
                          iqama expiry date
                        </span>
                      </div>
                    ) }
                    {volunteer?.iqama?.picture_should_update && (
                      <div>
                        Please update your{' '}
                        <span className="pop-over-text app-link" onClick={() => this.props.goEditIqama(volunteer.iqama.id)}>
                          iqama image
                        </span>
                      </div>
                    )}
                    {/* {this.state.testValid ? (
                      <div>
                        Please attempt your test.{' '}
                        <span onClick={() => this.props.conductTest(volunteer.id)} className="pop-over-text app-link">
                          {this.state.continueTest ? 'Continue Test' : 'Start Test'}
                        </span>
                      </div>
                    ) : (
                      this.state.volunteer.ask_leader_role && (
                        <div>
                          Please Attemp this test if you want to become Team Leader or Assistant Team Leader?{' '}
                          <span onClick={() => this.props.conductTest(volunteer.id, 'TL/ATL')} className="pop-over-text app-link">
                            Start Test
                          </span>
                        </div>
                      )
                    )} */}
                  </span>
                }
              />
            ) : (
              undefined
            )}
            <br />
            <Col span={24}>
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-12 col-md-8 d-flex justify-content-md-start justify-content-center">
                      <div className="utils__title">
                        <strong>Volunteer Information</strong>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 d-flex justify-content-md-end justify-content-center">
                      <div className="infobtnwrapper">
                        <QrModal user={volunteer} />
                        {hasPermission("Volunteer", "update") ||
                        hasPermission("Iqama", "update") ||
                        hasPermission("User", "update") ||
                        this.state.isCurrentUser ? (
                          <Dropdown overlay={menu} trigger={["click", "hover"]}>
                            <Button>Edit Profile</Button>
                          </Dropdown>
                        ) : (
                          undefined
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-12">
                      <ProfileHeadCard user={volunteer} />
                      <Tabs size={"large"} defaultActiveKey="1">
                        <TabPane tab="Personal Information" key="1">
                          <DashboardInformation user={this.state.volunteer} />
                        </TabPane>
                        {this.state.role === "Super Admin" ? (
                          <TabPane tab="History" key="2">
                            <HistoryPanel />
                          </TabPane>
                        ) : (
                          undefined
                        )}
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* <RegistrationPopup /> */}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  response: state.reducer.volunteer,
});

const mapDispatchToProps = (dispatch) => ({
  getVolunteer: (volunteer) => {
    dispatch({
      type: login.AUTHENTICATED,
      message: volunteer.message,
      response: volunteer,
    });
  },
  goLogin: () => {
    dispatch(push("/login"));
  },
  goEditIqama: async (id) => {
    const encodedId = await encodeIdAsync(id);
    dispatch(push("/iqama/edit/" + encodedId));
  },
  goEditVolunteer: async (id) => {
    const encodedId = await encodeIdAsync(id);
    dispatch(push("/volunteer/edit/" + encodedId));
  },
  goEditUser: async (id) => {
    const encodedId = await encodeIdAsync(id);
    dispatch(push("/user/edit/" + encodedId));
  },
  conductTest: async (id, testType) => {
    if (testType === 'TL/ATL') {
      localStorage.setItem('testType', 'TL/ATL');
    }else{
      localStorage.removeItem('testType');
    }
    const encodedId = await encodeIdAsync(id);
    dispatch(push(`/test/instructions/${encodedId}`));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VolunteerData));
