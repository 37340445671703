import React from 'react'
import { Route } from 'react-router-dom'
import { ConnectedSwitch } from 'reactRouterConnected'
import Loadable from 'react-loadable'
import Page from 'components/LayoutComponents/Page'
import NotFoundPage from 'pages/NotFoundPage'
import HomePage from 'pages/HomePage'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => null,
  })

const loadableRoutes = {
  // Default Pages
  '/login': {
    component: loadable(() => import('pages/LoginPage')),
  },
  '/empty': {
    component: loadable(() => import('pages/EmptyPage')),
  },
  '/registration': {
    component: loadable(() => import('pages/RegistrationPage')),
  },
  '/forgot-password': {
    component: loadable(() => import('pages/ForgotPassword')),
  },
  // Dashboards
  '/dashboard': {
    component: loadable(() => import('pages/Dashboard')),
  },
  // Edit Volunteer and Iqama

  '/manage/volunteers/edit/:id': {
    component: loadable(() => import('pages/Manage/Volunteer/EditVolunteer')),
  },
  //When user is redirected to the edit volunteer page from profile page
  '/volunteer/edit/:id': {
    component: loadable(() => import('pages/Manage/Volunteer/EditVolunteer')),
  },
  '/manage/iqama/edit/:id': {
    component: loadable(() => import('pages/Manage/Iqama/EditIqama')),
  },
  //When user is redirected to the edit iqama page from profile page
  '/iqama/edit/:id': {
    component: loadable(() => import('pages/Manage/Iqama/EditIqama')),
  },
  '/manage/user/edit/:id': {
    component: loadable(() => import('pages/Manage/User/EditUser')),
  },
  //When user is redirected to the edit user page from profile page
  '/user/edit/:id': {
    component: loadable(() => import('pages/Manage/User/EditUser')),
  },
  '/profile': {
    component: loadable(() => import('pages/Manage/Volunteer/VolunteerProfile')),
  },
  '/manage/events': {
    component: loadable(() => import('pages/Manage/Trainings')),
  },
  '/manage/events/new': {
    component: loadable(() => import('pages/Manage/Trainings/NewTraining')),
  },
  '/manage/events/edit/:id': {
    component: loadable(() => import('pages/Manage/Trainings/EditTraining')),
  },

  // Projects
  '/manage/projects': {
    component: loadable(() => import('pages/Manage/Projects')),
  },
  '/manage/projects/new': {
    component: loadable(() => import('pages/Manage/Projects/NewProject')),
  },
  '/manage/projects/edit/:id': {
    component: loadable(() => import('pages/Manage/Projects/EditProject')),
  },

  // Poles and posts
  '/manage/poles': {
    component: loadable(() => import('pages/Manage/Poles')),
  },
  '/manage/poles/new': {
    component: loadable(() => import('pages/Manage/Poles/NewPole')),
  },
  '/manage/poles/:id': {
    component: loadable(() => import('pages/Manage/Poles/Pole')),
  },
  '/manage/poles/edit/:id': {
    component: loadable(() => import('pages/Manage/Poles/EditPole')),
  },
  '/manage/posts': {
    component: loadable(() => import('pages/Manage/Posts')),
  },
  '/manage/posts/new': {
    component: loadable(() => import('pages/Manage/Posts/NewPost')),
  },
  '/manage/posts/edit/:id': {
    component: loadable(() => import('pages/Manage/Posts/EditPost')),
  },
  '/manage/residences': {
    component: loadable(() => import('pages/Manage/Buildings')),
  },
  '/manage/residences/new': {
    component: loadable(() => import('pages/Manage/Buildings/NewBuilding')),
  },
  '/manage/residences/edit/:id': {
    component: loadable(() => import('pages/Manage/Buildings/EditBuilding')),
  },
  '/manage/teams/post-assignment': {
    component: loadable(() => import('pages/Manage/Teams/PostAssignment')),
  },
  
  '/manage/posts/post-plan': {
    component: loadable(() => import('pages/Manage/Posts/PostPlan')),
  },

  // City Camps
  '/manage/citycamps': {
    component: loadable(() => import('pages/Manage/CityCamps')),
  },
  '/manage/citycamps/new': {
    component: loadable(() => import('pages/Manage/CityCamps/NewCityCamp')),
  },
  '/manage/citycamps/edit/:id': {
    component: loadable(() => import('pages/Manage/CityCamps/EditCityCamp')),
  },
  // Teams
  '/manage/teams': {
    component: loadable(() => import('pages/Manage/Teams')),
  },
  '/manage/teams/new': {
    component: loadable(() => import('pages/Manage/Teams/NewTeam')),
  },
  '/manage/teams/edit/:id': {
    component: loadable(() => import('pages/Manage/Teams/EditTeam')),
  },
  '/manage/teams/team-assignment': {
    component: loadable(() => import('pages/Manage/Teams/TeamAssignment')),
  },

  '/manage/teams/plans': {
    component: loadable(() => import('./pages/Manage/TeamPlan')),
  },
  '/manage/teams/plans/new': {
    component: loadable(() => import('./pages/Manage/TeamPlan/NewTeamPlan')),
  },
  '/manage/teams/plans/edit/:id': {
    component: loadable(() => import('./pages/Manage/TeamPlan/NewTeamPlan')),
  },
  '/manage/teams/plans/rules': {
    component: loadable(() => import('./pages/Manage/TeamPlan/TeamPlanRules')),
  },

  '/manage/teams/:id': {
    component: loadable(() => import('pages/Manage/Teams/Team')),
  },
  // Super admin and admin routes
  '/manage/volunteers': {
    component: loadable(() => import('pages/Manage/Volunteer/VolunteerList')),
  },
  '/manage/volunteers/add': {
    component: loadable(() => import('pages/Manage/Volunteer/AddVolunteer')),
  },
  '/manage/volunteers/profile/:id': {
    component: loadable(() => import('pages/Manage/Volunteer/VolunteerProfile')),
  },
  '/error': {
    component: loadable(() => import('pages/ErrorScreen/Error')),
  },
  '/unauthorized': {
    component: loadable(() => import('pages/ErrorScreen/UnauthorizedAccess')),
  },
  // Cities routes
  '/manage/cities': {
    component: loadable(() => import('pages/Manage/City')),
  },
  '/manage/cities/edit/:id': {
    component: loadable(() => import('pages/Manage/City/EditCity')),
  },
  '/manage/cities/new': {
    component: loadable(() => import('pages/Manage/City/NewCity')),
  },
  // Activitites
  '/activities': {
    component: loadable(() => import('pages/Activities')),
  },
  //Test
  '/manage/tests': {
    component: loadable(() => import('pages/Manage/Test')),
  },
  '/manage/tests/add': {
    component: loadable(() => import('pages/Manage/Test/AddTest')),
  },
  '/manage/tests/edit/:id': {
    component: loadable(() => import('pages/Manage/Test/EditTest')),
  },
  '/manage/tests/questions': {
    component: loadable(() => import('pages/Manage/Test/Questions')),
  },
  '/manage/tests/questions/new': {
    component: loadable(() => import('pages/Manage/Test/Questions/AddQuestion')),
  },
  '/manage/tests/questions/edit/:id': {
    component: loadable(() => import('pages/Manage/Test/Questions/EditQuestion')),
  },
  '/conduct-test/:id': {
    component: loadable(() => import('pages/Manage/Test/Exam')),
  },
  '/test/instructions/:id': {
    component: loadable(() => import('pages/Manage/Test/Exam/Instructions')),
  },
  '/manage/test/test-assignment': {
    component: loadable(() => import('pages/Manage/Test/TestAssignment')),
  },
  // '/notifications': {
  //   component: loadable(() => import('pages/Notifications')),
  // },
  // PLans royute
  '/manage/room-assignment': {
    component: loadable(() => import('pages/Manage/Plans')),
  },
  '/manage/rooms': {
    component: loadable(() => import('pages/Manage/Rooms')),
  },
  '/manage/rooms/edit/:id': {
    component: loadable(() => import('pages/Manage/Rooms/EditRoom')),
  },
  // Route for Maps
  '/map': {
    component: loadable(() => import('pages/Maps')),
  },
  '/map/dashboard': {
    component: loadable(() => import('pages/Maps/Dashboard')),
  },
  '/map/geofencing': {
    component: loadable(() => import('pages/Map/GeoFencing')),
  },
  // Route For sending notifications
  '/send-notifications': {
    component: loadable(() => import('pages/SendNotifications')),
  },
  '/settings': {
    component: loadable(() => import('pages/Settings')),
  },
  '/settings/access-control': {
    component: loadable(() => import('pages/Settings/ACL')),
  },
  '/phvg-hajj-navigator/locations': {
    component: loadable(() => import('pages/PHVGHajjNavigator/Locations')),
  },
  '/phvg-hajj-navigator/regions': {
    component: loadable(() => import('pages/PHVGHajjNavigator/Regions')),
  },
  '/phvg-hajj-navigator/translations': {
    component: loadable(() => import('pages/PHVGHajjNavigator/Translations')),
  },
  '/phvg-hajj-navigator/translations/add': {
    component: loadable(() => import('pages/PHVGHajjNavigator/Translations/AddTranslation')),
  },
  '/lost-hujjaj': {
    component: loadable(() => import('pages/ManageLostHaji')),
  },
  '/phvg-hajj-navigator/user-reviews': {
    component: loadable(() => import('pages/PHVGHajjNavigator/ReviewsPage')),
  },
  '/phvg-hajj-navigator/Settings': {
    component: loadable(() => import('pages/PHVGHajjNavigator/Settings')),
  },
}

class Routes extends React.Component {
  timeoutId = null

  componentDidMount() {
    this.timeoutId = setTimeout(
      () => Object.keys(loadableRoutes).forEach(path => loadableRoutes[path].component.preload()),
      5000, // load after 5 sec
    )
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  render() {
    return (
      <ConnectedSwitch>
        <Route exact path="/" component={HomePage} />
        {Object.keys(loadableRoutes).map(path => {
          const { exact, ...props } = loadableRoutes[path]
          props.exact = exact === void 0 || exact || false // set true as default
          return <Route key={path} path={path} {...props} />
        })}
        <Route
          render={() => (
            <Page>
              <NotFoundPage />
            </Page>
          )}
        />
      </ConnectedSwitch>
    )
  }
}

export { loadableRoutes }
export default Routes
