import { createAction, createReducer } from 'redux-act'
import { push } from 'react-router-redux'
import { pendingTask, begin, end } from 'react-redux-spinner'
import request from '../request/api'
import { URL_PATH } from '../constants/api.constants'
import {
  user,
  posts,
  teams,
  teamVolunteer,
  pole,
  volunteerColumns,
  volunteersProfile,
  searchQueries,
} from '../localstorage/localstorage'
import { requiredData } from '../localstorage/requiredData.localstorage'

const REDUCER = 'app'
const NS = `@@${REDUCER}/`
const _setFrom = createAction(`${NS}SET_FROM`)
const _setLoading = createAction(`${NS}SET_LOADING`)
export const _setHideLogin = createAction(`${NS}SET_HIDE_LOGIN`)

export const setUserState = createAction(`${NS}SET_USER_STATE`)
export const setUpdatingContent = createAction(`${NS}SET_UPDATING_CONTENT`)
export const setActiveDialog = createAction(`${NS}SET_ACTIVE_DIALOG`)
export const deleteDialogForm = createAction(`${NS}DELETE_DIALOG_FORM`)
export const addSubmitForm = createAction(`${NS}ADD_SUBMIT_FORM`)
export const deleteSubmitForm = createAction(`${NS}DELETE_SUBMIT_FORM`)
export const setLayoutState = createAction(`${NS}SET_LAYOUT_STATE`)

export const setLoading = isLoading => {
  const action = _setLoading(isLoading)
  action[pendingTask] = isLoading ? begin : end
  return action
}

export const resetHideLogin = () => (dispatch, getState) => {
  const state = getState()
  if (state.pendingTasks === 0 && state.app.isHideLogin) {
    dispatch(_setHideLogin(false))
  }
  return Promise.resolve()
}

export const initAuth = roles => (dispatch, getState) => {
  // Use Axios there to get User Data by Auth Token with Bearer Method Authentication

  const userRole = window.localStorage.getItem('app.Role')
  const state = getState()

  const users = {
    volunteer: {
      role: 'Volunteer',
    },
    superAdmin: {
      role: 'Super Admin',
    },
    admin: {
      role: 'Admin',
    },
    areaIncharge: {
      role: 'Area Incharge',
    },
    teamLeader: {
      role: 'Team Leader',
    },
    assistantTeamLeader: {
      role: 'Assistant Team Leader',
    },
    trainer: {
      role: 'Trainer',
    },
    regionalAdmin: {
      role: 'Regional Admin',
    },
  }

  const setUser = userState => {
    dispatch(
      setUserState({
        userState: {
          ...userState,
        },
      }),
    )
    if (!roles.find(role => role === userRole)) {
      if (!(state.routing.location.pathname === '/dashboard')) {
        dispatch(push('/dashboard'))
      }
      return Promise.resolve(false)
    }
    return Promise.resolve(true)
  }

  switch (userRole) {
    case 'Volunteer':
      return setUser(users.volunteer, userRole)
    case 'Super Admin':
      return setUser(users.superAdmin, userRole)
    case 'Assistant Team Leader':
      return setUser(users.assistantTeamLeader, userRole)
    case 'Trainer':
      return setUser(users.trainer, userRole)
    case 'Team Leader':
      return setUser(users.teamLeader, userRole)
    case 'Area Incharge':
      return setUser(users.areaIncharge, userRole)
    case 'Admin':
      return setUser(users.admin, userRole)
      case 'Regional Admin':
      return setUser(users.regionalAdmin, userRole)
    default:
      const location = state.routing.location
      const from = location.pathname + location.search
      dispatch(_setFrom(from))
      dispatch(push('/login'))
      return Promise.reject()
  }
}

export async function login(iqama_id, password, dispatch) {
  let data = {
    iqama_id: iqama_id,
    password: password,
  }
  try {
    let value = await request({ method: 'POST', url: URL_PATH.LOGIN_URL, data })
      .then(async function(data) {
        return data
      })
      .catch(error => {
        return error
      })
    return value
  } catch (error) {
    dispatch(push('/login'))
    dispatch(_setFrom(''))
  }
}

export const logout = () => async (dispatch, getState) => {
  dispatch(
    setUserState({
      userState: {
        email: '',
        role: '',
      },
    }),
    window.localStorage.clear(),
  )
  window.localStorage.setItem('app.Authorization', '')
  window.localStorage.setItem('app.Role', '')
  await user.setItem('user', null)
  await requiredData.setItem('requiredData', null)
  await teams.setItem('teams', null)
  await teamVolunteer.setItem('teamVolunteer', null)
  await volunteersProfile.setItem('volunteer_profile', null)
  await searchQueries.setItem('query', null)
  await volunteerColumns
    .setItem('volunteerColumns', null)
    .then(function() {
      dispatch(push('/login'))
    })
    .catch(function(err) {})
}

const initialState = {
  // APP STATE
  from: '',
  isUpdatingContent: false,
  isLoading: false,
  activeDialog: '',
  dialogForms: {},
  volunteerData: {},
  submitForms: {},
  isHideLogin: false,

  // LAYOUT STATE
  layoutState: {
    isMenuTop: false,
    menuMobileOpened: false,
    menuCollapsed: false,
    menuShadow: true,
    themeLight: true,
    squaredBorders: false,
    borderLess: true,
    fixedWidth: false,
    settingsOpened: false,
  },

  // USER STATE
  userState: {
    email: '',
    role: '',
  },
}

export default createReducer(
  {
    [_setFrom]: (state, from) => ({ ...state, from }),
    [_setLoading]: (state, isLoading) => ({ ...state, isLoading }),
    [_setHideLogin]: (state, isHideLogin) => ({ ...state, isHideLogin }),
    [setUpdatingContent]: (state, isUpdatingContent) => ({
      ...state,
      isUpdatingContent,
    }),
    [setUserState]: (state, { userState }) => ({ ...state, userState }),
    [setLayoutState]: (state, param) => {
      const layoutState = { ...state.layoutState, ...param }
      const newState = { ...state, layoutState }
      window.localStorage.setItem('app.layoutState', JSON.stringify(newState.layoutState))
      return newState
    },
    [setActiveDialog]: (state, activeDialog) => {
      const result = { ...state, activeDialog }
      if (activeDialog !== '') {
        const id = activeDialog
        result.dialogForms = { ...state.dialogForms, [id]: true }
      }
      return result
    },
    [deleteDialogForm]: (state, id) => {
      const dialogForms = { ...state.dialogForms }
      delete dialogForms[id]
      return { ...state, dialogForms }
    },
    [addSubmitForm]: (state, params, data) => {
      return { ...state, volunteerData: params }
    },
    [deleteSubmitForm]: (state, id) => {
      const submitForms = { ...state.submitForms }
      delete submitForms[id]
      return { ...state, submitForms }
    },
  },
  initialState,
)
