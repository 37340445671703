export const URL_PATH = {
  API: process.env.REACT_APP_API_URL,
  POST_CONTENT_TYPE: 'application/json',
  LOGIN_URL: 'authentication/login',
  REGISTRATION_URL: 'registrations',
  VERIFY_IQAMA: 'registrations/verify_iqama',
  VERIFY_PHONE: 'registrations/verify_phone',
  IQAMA: 'iqamas/',
  USER: 'users/',

  // Volunteer
  VOLUNTEER: 'volunteers/',
  VOLUNTEER_SEARCH: 'volunteers/search',
  VOLUNTEER_SEARCH_BY_IQAMA: 'volunteers/iqama_search',
  VOLUNTEER_TEST_VALIDITIY: '/validate_test_attempts',
  VOLUNTEER_AREA_INCHARGES: 'volunteers/get_area_incharges',
  VOLUNTEER_BULK_ATTENDANCE: '/volunteers/mark_bulk_attendance',
  VOLUNTEER_BULK_ACTIONS_MARK_TRAINER: '/volunteers/bulk_actions_mark_trainers',
  VOLUNTEER_BULK_ACTIONS_ACTIVATE_TEST: '/volunteers/bulk_actions_activate_test',
  VOLUNTEER_BULK_ACTIONS_MARK_MAP_READERS: '/volunteers/bulk_actions_mark_map_readers',
  VOLUNTEER_POPUP: '/popup',
  VOLUNTEER_REG_PROCESS: '/post_registration_processes',

  // Generic report for all entities
  GENERIC_REPORT: 'volunteers/report',
  VOLUNTEER_REPORT: 'volunteers/generate_report',

  // Team
  TEAM: 'teams/',
  GET_TEAMS_POSTS: 'get_teams_posts/',
  GET_TEAMS_ROOMS: 'get_teams_rooms/',
  TEAMS: 'teams/get_teams',
  TEAMS_NAME: 'teams/team_name',
  UPDATE_BULK_TEAMS: 'teams/bulk_update',

REQUIRED_DATA: 'registrations/required_data?all=true',
  POLES: 'poles/',
  POSTS: 'posts/',
  TRAININGS: 'trainings/',
  PROJECTS: 'projects/',
  UPDATE_BULK_POSTS: 'posts/bulk_update',
  UPDATE_BULK_POLES: 'poles/bulk_update',
  ROOMS_REPORT: 'rooms/generate_report',
  ROOMS_LISTING_REPORT: 'rooms/generate_room_listing_report',
  FORGOT_PASSWORD_FORGOT: 'users/forgot',
  FORGOT_PASSWORD_RESET: 'users/reset',
  CITY_CAMPS: 'city_camps/',
  CITY_CAMPS_REPORT: 'city_camps/generate_report',
  CITIES: 'cities/',
  ACTIVITY: 'notifications',
  TEAM_REPORT: 'teams/generate_report',
  CITY_REPORT: 'cities/generate_report',
  POSTS_REPORT: 'posts/generate_report',
  POLES_REPORT: 'poles/generate_report',
  TESTS_REPORT: 'tests/generate_report',
  TESTS: 'tests/',
  ASSIGN_TESTS: 'tests/assign_test',
  QUESTIONS: 'questions/',
  QUESTIONS_ID: 'questions/questions',
  CURRENT_TEST: 'tests/current_test',
  SAVE_PROGRESS: 'volunteers/save_progress',
  GET_PROGRESS: '/get_progress',

  BUILDINGS: 'buildings/',

  ROOMS: '/rooms/',

  NOTIFICATIONS: 'notifications',
  MARK_READ: 'notifications/mark_as_read',
  // send push notification
  SEND_PUSH_NOTIFICATION_URL: 'notifications/send_push_notification',
  SEND_OTHER_NOTIFICATION: 'notifications',

  BLOCK_VOLUNTEERS: 'settings/blacklist_users',
  GET_BLOCKED_VOLUNTEER: 'black_lists',
  SETTINGS: 'settings/change_settings',
  GETSETTINGS: 'settings',
  GETINITIALSHIFT: 'settings/get_shift_name',
  GETLAYOUTRULES: 'users/get_roles',
}
