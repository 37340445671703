import React, { useState } from 'react'
import './style.scss'
import { Modal, Button, Spin, Avatar, Divider, Badge, Tag } from 'antd'
import CheckMark from '../../../components/CustomComponents/CheckMark/CheckMark'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { WhatsAppOutlined } from '@ant-design/icons'
class PersonProfileCard extends React.Component {
  state = {
    volunteerImageLoaded: false,
    volunteerImageError: false,
  }
  status = user => {
    const { status, verified } = user
    return (
      <React.Fragment>
        {status ? <Tag color="success">Active</Tag> : <Tag color="error">Inactive</Tag>}

        {verified ? <Tag color="success">Verified</Tag> : <Tag color="error">Unverified</Tag>}
      </React.Fragment>
    )
  }

  badges = user => {
    const { has_passed_test, is_performed_hajj, kit, car, vaccination } = user
    return (
      <div>
        {is_performed_hajj ? <Tag>Performed Hajj</Tag> : null}
        {has_passed_test ? <Tag>Passed Test</Tag> : null}
        {kit ? <Tag>Kit Available</Tag> : null}
        {car ? <Tag>Car Available</Tag> : null}
        {vaccination ? <Tag>Vaccinated</Tag> : null}
      </div>
    )
  }

  ProfileImage = user => {
    return (
      <React.Fragment>
        <Spin spinning={!this.state.volunteerImageLoaded}>
          {!this.state.volunteerImageError ? (
            <img
              src={
                user.volunteer_image
                  ? user.volunteer_image
                  : 'resources/images/avatars/person.png'
              }
              onError={() => this.setState({ volunteerImageError: true })}
              onLoad={() => this.setState({ volunteerImageLoaded: true })}
              // size={200}
              alt="user-profile-pic"
              className="img-wrapped"
            />
          ) : (
            <img
              src={'resources/images/avatars/person.png'}
              onLoad={() => this.setState({ volunteerImageLoaded: true })}
              // size={200}
              alt="user-profile-pic"
              className="img-wrapped"
            />
          )}
        </Spin>
      </React.Fragment>
    )
  }
  render() {
    const { user } = this.props

    return (
      <div className="row ">
        <div className="col-12 col-xl-4 d-flex align-items-center justify-content-center">
          {this.ProfileImage(user)}
        </div>
        {user ? (
          <div className="col-12 col-xl-8">
            <div className="info-wrapper">
              <label className="person-name">
                {user.full_name} <span>{this.status(user)}</span>
              </label>
              <label className="person-default">{user.user.role}</label>
              <label className="person-job">
                {user.job_title} at <b>{user.company_name}</b>
                <span className="tooltip-text">
                  <b>{user.company_name}</b>
                </span>
              </label>
              {user.city ? (
                <div>
                  <label className="profile-icon pr-2">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </label>
                  <label className="person-default">{user.city.name}</label>
                </div>
              ) : null}

              <div className="contact-wrapper">
                <label className="profile-icon pr-2">
                  <FontAwesomeIcon icon={faPhoneAlt} />
                </label>
                <label className="phone-info pr-3">{user.mobile.local}</label>

                <label className="whatsapp-icon pr-2">
                  <WhatsAppOutlined />
                </label>
                <label className="phone-info">{user.whatsapp.local}</label>
              </div>
              {this.badges(user)}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default PersonProfileCard
