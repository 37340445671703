import React from 'react'
import { createRoot } from 'react-dom/client'
import { ConfigProvider } from 'antd'
import enGB from 'antd/locale/en_GB'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { ConnectedRouter, routerMiddleware } from 'react-router-redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createHashHistory as createHistory } from 'history'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import App from 'app'
import ErrorBoundary from './pages/ErrorHandler/ErrorBoundary'

import reducer from 'ducks'
import 'es6-promise/auto'
import 'setimmediate'

import 'bootstrap/dist/css/bootstrap.min.css' // bootstrap styles
import 'resources/AntStyles/AntDesign/antd.cleanui.scss'
import 'resources/CleanStyles/Core/core.cleanui.scss'
import 'resources/CleanStyles/Vendors/vendors.cleanui.scss'
import 'mapbox-gl/dist/mapbox-gl.css'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)


const history = createHistory()
const router = routerMiddleware(history)
const middlewares = [router, thunk]
const isLogger = false
if (isLogger && process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger')
  middlewares.push(logger)
}
export const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)))

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ConfigProvider
        locale={enGB}
        theme={{
          token: {
            colorPrimary: '#00b96b',
            colorInfo: '#0887c9',
            colorError: '#fb434a',
            colorSuccess: '#00a65a',
          },
        }}>
        <ErrorBoundary>
          <Helmet titleTemplate="PHVG Razakar - %s" />
          <App />
        </ErrorBoundary>
      </ConfigProvider>
    </ConnectedRouter>
  </Provider>,
)
// subscribeToNotifications()
// registerServiceWorker()

export default history
