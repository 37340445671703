import React from "react";
import { InboxOutlined, UserOutlined } from "@ant-design/icons";
import {
  Input,
  Button,
  Select,
  Row,
  Col,
  notification,
  Spin,
  message,
  Upload,
  Typography,
  Modal,
  Form
} from "antd";
import { URL_PATH } from "../../../constants/api.constants";
import HijriDatePicker from "../../CustomComponents/HijriDatePicker/containers/app";
import { postData } from "../../../helpers/request.helper";
import { isNumeric } from "../../../helpers/methods.helper";
import { handleCountryCodeChange } from "../../../helpers/data.helper";
import {
  URDU_LABELS,
  ENGLISH_LABELS
} from "../../../constants/labels.constants";
import {
  textFieldValidator,
  phoneNumberValidator,
  emergencyPhoneValidator
} from "../../../validator/validator";
import { iqamaExpiryValidator } from "../../../validator/iqamaExpiryValidator";
import { errorRenderer } from "../../../helpers/error.helper";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { user, volunteersProfile } from "../../../localstorage/localstorage";

const FormItem = Form.Item;
const { Option } = Select;
const { Text } = Typography;

class ReRegistrationPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleRegister: false,
      language: "english",
      existingData: "",
      dataLoading: true,
      iqamaExpiryError: false,
      iqamaImageErr: false,
      volunteerImageErr: false,
      crop: {
        x: 25,
        y: 25,
        width: 50,
        height: 50
      }
    };
  }
  formRef = React.createRef();

  async componentDidMount() {
    let data = await user.getItem("user");
    this.setState({
      dataLoading: false,
      existingData: data
    });
  }
  splitPhoneNumber = phone => {
    return phone.substring(1);
  };

  splitCountryCode(phone) {
    if (phone.substring(0, 3) === "+92") {
      return "92";
    } else {
      return "966";
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.visible !== state.visibleRegister) {
      return {
        visibleRegister: props.visible
      };
    }

    // Return null if the state hasn't changed
    return null;
  }
  beforeUpload(file) {
    if (!file.error) return false;

    const isJPG = file.type === "image/jpeg" || file.type === "image/png";
    let error = "";

    if (!isJPG) {
      error = "You can only upload JPG or PNG file. ";
    }

    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      error += "Image size must smaller than 1 MB.";
    }

    if (error.length > 0) message.error(error);

    return isJPG && isLt2M;
  }

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    };
    reader.readAsDataURL(img);
  }
  iqamaExpiryDate = async date => {
    await this.setState({
      iqamaExpiry: date,
      iqamaExpiryError: !iqamaExpiryValidator(date)
    });
  };
  handleVolunteerImage = info => {
    if (this.beforeUpload(info.file)) {
      this.getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          volunteerImage: imageUrl,
          visible: true,
          volunteerVisible: true
        })
      );
    }
  };
  handleIqamaImage = info => {
    if (this.beforeUpload(info.file)) {
      this.getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          iqamaImage: imageUrl,
          visible: true,
          iqamaVisible: true
        })
      );
    }
  };
  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
    if (this.state.iqamaVisible) {
      this.setState({ croppedIqamaImage: this.state.iqamaImage });
    } else {
      this.setState({ croppedVolunteerImage: this.state.volunteerImage });
    }
    this.makeClientCrop(this.state.crop, pixelCrop);
    return false;
  };
  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };
  onCropChange = crop => {
    this.setState({ crop });
  };
  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        "newFile.jpeg"
      );
      if (this.state.iqamaVisible) {
        this.setState({ croppedIqamaImage: croppedImageUrl });
      } else {
        this.setState({ croppedVolunteerImage: croppedImageUrl });
      }
    }
  }
  handleSubmit = async values => {
    if (!this.state.iqamaExpiry) {
      this.setState({
        iqamaExpiryError: true
      });
    }
    this.setState({
      iqamaImageErr: this.state.croppedIqamaImage ? false : true,
      volunteerImageErr: this.state.croppedVolunteerImage ? false : true
    });
    if (
      this.state.iqamaExpiry &&
      this.state.croppedVolunteerImage &&
      this.state.croppedIqamaImage
    ) {
      this.setState({
        loading: true
      });
      let data = {
        mobile: values.phoneCountryCode + values.phoneNumber,
        whatsapp: values.whatsappCountryCode + values.whatsappNumber,
        emergency_contact_name: values.emergencyContactName,
        emergency_contact_no:
          values.emergencyCountryCode + values.emergencyContactNumber,
        iqama_attributes: {
          registration_id: this.state.existingData.iqama.registration_id,
          expiry_date: this.state.iqamaExpiry
        }
      };
      var userData = new FormData();
      userData.append("volunteer", JSON.stringify(data));
      userData.append(
        "volunteer_image",
        JSON.stringify(
          this.state.croppedVolunteerImage.substring(
            22,
            this.state.croppedVolunteerImage.length
          )
        )
      );
      userData.append(
        "iqama_image",
        JSON.stringify(
          this.state.croppedIqamaImage.substring(
            22,
            this.state.croppedIqamaImage.length
          )
        )
      );
      let response = await postData("PUT", URL_PATH.REGISTRATION_URL, userData);
      if (response.success) {
        notification.success({
          message: "Volunteer updated successfully",
          description: response.message
        });
        // let volunteerObj = await user.getItem("user");
        // if (volunteerObj.id === parseInt(volunteerID, 10)) {
        //   await user.setItem("user", response.volunteer);
        // } else {
        //   await volunteersProfile.setItem(
        //     "volunteer_profile",
        //     response.volunteer
        //   );
        // }
        //   this.props.history.goBack();
        this.props.close();
      } else if (!response.success && response.errors) {
        notification.error({
          message: "Volunteer update failed",
          description: errorRenderer(response.errors)
        });
        this.setState({ loading: false });
      }
    } else {
      message.error("There is an error in above fields.");
      this.setState({ loading: false });
      return false;
    }
  };
  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );
    return canvas.toDataURL();
  }
  cropImage = image => {
    return (
      <ReactCrop
        src={image}
        crop={this.state.crop}
        onImageLoaded={this.onImageLoaded}
        onComplete={this.onCropComplete}
        onChange={this.onCropChange}
      />
    );
  };
  render() {
    const { language, existingData, dataLoading } = this.state;
    let labels = language === "english" ? ENGLISH_LABELS : URDU_LABELS;
    return dataLoading ? (
      <div></div>
    ) : (
      <div>
        <Modal
          title={"Re-registration Form"}
          open={this.state.visibleRegister}
          // onOk={this.handleOk}
          onCancel={() => {
            this.props.close();
          }}
            maskClosable={false}
          //   centered
            // closable={false}
          //   okText="Continue"
          //   cancelText="Ask me Later"
          footer={[
            <Button
              key="submit"
              htmlType="submit"
              onClick={() => {
                this.formRef.current
                  .validateFields()
                  .then(values => this.handleSubmit(values));
              }}
              loading={this.state.loading}
            >
              Submit
            </Button>
          ]}
          centered
        >
          <div>
            <Form
              ref={this.formRef}
              className="login-form"
              initialValues={{
                phoneCountryCode: "966",
                phoneNumber:
                  existingData &&
                  existingData.mobile &&
                  existingData.mobile.local
                    ? this.splitPhoneNumber(existingData.mobile.local)
                    : undefined,
                whatsappCountryCode:
                  existingData &&
                  existingData.whatsapp &&
                  existingData.whatsapp.international
                    ? this.splitCountryCode(existingData.whatsapp.international)
                    : undefined,
                whatsappNumber:
                  existingData &&
                  existingData.whatsapp &&
                  existingData.whatsapp.local
                    ? this.splitPhoneNumber(existingData.whatsapp.local)
                    : undefined,
                emergencyContactName: existingData.emergency_contact_name
                  ? existingData.emergency_contact_name
                  : undefined,
                emergencyCountryCode:
                  existingData &&
                  existingData.emergency_contact_no &&
                  existingData.emergency_contact_no.international
                    ? this.splitCountryCode(
                        existingData.emergency_contact_no.international
                      )
                    : undefined,
                emergencyContactNumber:
                  existingData &&
                  existingData.emergency_contact_no &&
                  existingData.emergency_contact_no.local
                    ? this.splitPhoneNumber(
                        existingData.emergency_contact_no.local
                      )
                    : undefined
              }}
            >
              {/* <fieldset> */}
              <label
                className={
                  language === "english"
                    ? "label-form"
                    : "label-form float-right"
                }
              >
                {labels.IQAMA_ID}
              </label>

              <FormItem>
                <Input
                  value={existingData.iqama.registration_id}
                  placeholder={labels.PLACEHOLDER_ENTER_IQAMA_ID}
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  disabled={true}
                />
              </FormItem>
              <div>
                <label
                  className={
                    language === "english"
                      ? "label-form"
                      : "label-form float-right"
                  }
                >
                  {labels.IQAMA_EXPIRY_DATE}
                </label>
              </div>
              <FormItem>
                <HijriDatePicker onValueChange={this.iqamaExpiryDate} />
              </FormItem>
              {this.state.iqamaExpiryError ? (
                <span>
                  <span
                    className={
                      language === "english"
                        ? "label-form"
                        : "label-form float-right"
                    }
                    style={{ color: "red", zIndex: -20 }}
                  >
                    {labels.IQAMA_MSG}{" "}
                  </span>
                  <br />
                  <br />
                </span>
              ) : (
                undefined
              )}
              <Row>
                <label
                  className={
                    language === "english"
                      ? "label-form"
                      : "label-form float-right"
                  }
                >
                  {labels.MOBILE_NUMBER}
                </label>
              </Row>
              <Row gutter={5}>
                <Col xs={10} lg={8} xl={8}>
                  <FormItem name="phoneCountryCode">
                    <Select
                      disabled
                      placeholder={labels.PLACEHOLDER_COUNTRY_CODE}
                    >
                      <Option value="966">Saudi Arabia (+966)</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={14} lg={16} xl={16}>
                  <FormItem shouldUpdate>
                    {({ getFieldValue }) => {
                      let placeholder =
                        getFieldValue("phoneCountryCode") === "966"
                          ? "Enter Phone Number (e.g. 501234567)"
                          : "Enter Phone Number (e.g. 3012345678)";
                      return (
                        <FormItem
                          name="phoneNumber"
                          rules={[
                            {
                              required: true,
                              message: labels.ERROR_MESSAGE_COUNTRY_CODE
                            },
                            ({ getFieldValue }) => ({
                              validator(rules, value) {
                                return phoneNumberValidator(value, "966");
                              }
                            }),
                            ({ getFieldValue }) => ({
                              validator(rules, value) {
                                return emergencyPhoneValidator(
                                  value,
                                  getFieldValue("emergencyContactNumber")
                                );
                              }
                            })
                          ]}
                        >
                          <Input
                            onKeyPress={isNumeric}
                            placeholder={placeholder}
                          />
                        </FormItem>
                      );
                    }}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <label
                  className={
                    language === "english"
                      ? "label-form"
                      : "label-form float-right"
                  }
                >
                  {labels.WHATSAPP_NUMBER}
                </label>
              </Row>
              <Row gutter={5}>
                <Col xs={10} lg={8} xl={8}>
                  <FormItem
                    name="whatsappCountryCode"
                    rules={[
                      {
                        required: true,
                        message: labels.ERROR_MESSAGE_COUNTRY_CODE
                      }
                    ]}
                  >
                    <Select placeholder="Country Code">
                      <Option value="966">Saudi Arabia (+966)</Option>
                      <Option value="92">Pakistan (+92)</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={14} lg={16} xl={16}>
                  <FormItem shouldUpdate>
                    {({ getFieldValue }) => {
                      let placeholder =
                        getFieldValue("whatsappCountryCode") === "966"
                          ? "Enter Whatsapp Number (e.g. 501234567)"
                          : "Enter Whatsapp Number (e.g. 3012345678)";
                      return (
                        <FormItem
                          name="whatsappNumber"
                          rules={[
                            {
                              required: true,
                              message: labels.ERROR_MESSAGE_WHATSAPP_NUMBER
                            },
                            ({ getFieldValue }) => ({
                              validator(rules, value) {
                                return phoneNumberValidator(
                                  value,
                                  getFieldValue("whatsappCountryCode")
                                );
                              }
                            }),
                            ({ getFieldValue }) => ({
                              validator(rules, value) {
                                return emergencyPhoneValidator(
                                  value,
                                  getFieldValue("emergencyContactNumber")
                                );
                              }
                            })
                          ]}
                        >
                          <Input
                            placeholder={placeholder}
                            onKeyPress={isNumeric}
                          />
                        </FormItem>
                      );
                    }}
                  </FormItem>
                </Col>
              </Row>
              <label
                className={
                  language === "english"
                    ? "label-form"
                    : "label-form float-right"
                }
              >
                {labels.EMERGENCY_CONTACT_NAME}
              </label>
              <FormItem
                name="emergencyContactName"
                rules={[
                  {
                    required: true,
                    message: "Please enter you Emergency Contact Name"
                  },
                  () => ({
                    validator(rules, value) {
                      return textFieldValidator(value);
                    }
                  })
                ]}
              >
                <Input placeholder="Emergency Contact Name" />
              </FormItem>
              <Row>
                <label
                  className={
                    language === "english"
                      ? "label-form"
                      : "label-form float-right"
                  }
                >
                  {labels.EMERGENCY_CONTACT_NUMBER}
                </label>
              </Row>
              <Row gutter={5}>
                <Col xs={10} lg={8} xl={8}>
                  <FormItem
                    name="emergencyCountryCode"
                    rules={[
                      {
                        required: true,
                        message: "Please select your country code."
                      }
                    ]}
                  >
                    <Select
                      placeholder="Country Code"
                      // onChange={() =>
                      //   handleCountryCodeChange(
                      //     "emergencyContactNumber",
                      //     setFieldsValue
                      //   )
                      // }
                    >
                      <Option value="966">Saudi Aarabia (+966)</Option>
                      <Option value="92">Pakistan (+92)</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={14} lg={16} xl={16}>
                  <FormItem shouldUpdate>
                    {({ getFieldValue }) => {
                      let placeholder =
                        getFieldValue("emergencyCountryCode") === "966"
                          ? "Enter Emergency Number (e.g. 501234567)"
                          : "Enter Emergency Number (e.g. 3012345678)";

                      return (
                        <FormItem
                          name="emergencyContactNumber"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please enter your emergency contact number."
                            },
                            ({ getFieldValue }) => ({
                              validator(rules, value) {
                                return phoneNumberValidator(
                                  value,
                                  getFieldValue("emergencyCountryCode")
                                );
                              }
                            })
                          ]}
                        >
                          <Input
                            placeholder={placeholder}
                            onKeyPress={isNumeric}
                          />
                        </FormItem>
                      );
                    }}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24}>
                  <label
                    className={
                      language === "english"
                        ? "label-form"
                        : "label-form float-right"
                    }
                  >
                    {labels.VOLUNTEER_IMAGE}
                  </label>
                  <FormItem>
                    <Upload
                      name="volunteerImage"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      onChange={this.handleVolunteerImage}
                    >
                      {existingData.volunteer_image ||
                      this.state.croppedVolunteerImage ? (
                        <div>
                          <img
                            className="display-image"
                            src={ this.state.croppedVolunteerImage}
                            alt="avatar"
                          />
                          <div>
                            <InboxOutlined
                              style={{ color: "rgba(0,0,0,.25)", fontSize: 30 }}
                            />
                            <div className="ant-upload-text">
                              {labels.UPDATE_VOLUNTEER_IMAGE}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <InboxOutlined
                            style={{ color: "rgba(0,0,0,.25)", fontSize: 30 }}
                          />
                          <div className="ant-upload-text">
                            {labels.UPDATE_VOLUNTEER_IMAGE}
                          </div>
                        </div>
                      )}
                    </Upload>
                  </FormItem>
                  {this.state.volunteerImageErr ? (
                    <span>
                      <span
                        className={
                          language === "english"
                            ? "label-form"
                            : "label-form float-right"
                        }
                        style={{ color: "red", zIndex: -20 }}
                      >
                        "Please select new volunteer image."
                      </span>
                      <br />
                      <br />
                    </span>
                  ) : (
                    undefined
                  )}
                </Col>
              </Row>
              <Row gutter={12}>
              <Col xs={24}>
                  <label
                    className={
                      language === "english"
                        ? "label-form"
                        : "label-form float-right"
                    }
                  >
                    {labels.IQAMA_IMAGE}
                  </label>
                  <FormItem>
                    <Upload
                      name="iqamaImage"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      onChange={this.handleIqamaImage}
                    >
                      {this.state.croppedIqamaImage ? (
                        <img
                          className="display-image"
                          src={this.state.croppedIqamaImage}
                          alt="avatar"
                        />
                      ) : (
                        <div>
                          <InboxOutlined
                            style={{
                              color: "rgba(0,0,0,.25)",
                              fontSize: 30
                            }}
                          />
                          <div className="ant-upload-text">
                            Upload Iqama Image
                          </div>
                        </div>
                      )}
                    </Upload>
                  </FormItem>
                  {this.state.iqamaImageErr ? (
                    <span>
                      <span
                        className={
                          language === "english"
                            ? "label-form"
                            : "label-form float-right"
                        }
                        style={{ color: "red", zIndex: -20 }}
                      >
                        "Please select new iqama image."
                      </span>
                      <br />
                      <br />
                    </span>
                  ) : (
                    undefined
                  )}
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
        <Modal
          open={this.state.visible}
          onOk={() =>
            this.setState({
              visible: false,
              iqamaVisible: false,
              volunteerImage: false
            })
          }
          onCancel={() =>
            this.setState({
              visible: false,
              iqamaVisible: false,
              volunteerImage: false
            })
          }
          okText="Save"
          className="image-crop-modal"
        >
          {this.state.iqamaVisible
            ? // Render Iqama Croppping
              this.cropImage(this.state.iqamaImage)
            : //render volunteer Croppping
              this.cropImage(this.state.volunteerImage)}
        </Modal>
      </div>
    );
  }
}

export default ReRegistrationPopup;