import { notifications as notificationsConstants } from '../constants/app.constants'
import { getData, postData } from '../helpers/request.helper'
import { URL_PATH } from '../constants/api.constants'

export const fetchmaxNotifications = () => {
  return async dispatch => {
    dispatch({
      type: notificationsConstants.NOTIFICATIONS,
      notifications: {
        seeMoreLoading: true,
        loadMaximumNotifications: true,
      },
    })
    dispatch(allReadnotifications(true))
  }
}

export const allReadnotifications = all => {
  return async dispatch => {
    try {
      let data = await getData('GET', `${URL_PATH.NOTIFICATIONS}?all=${all}`)
      if (data) {
        dispatch({
          type: notificationsConstants.NOTIFICATIONS,
          notifications: {
            readNotifications: data.notifications,
            seeMoreLoading: false,
            loading: false,
            loadMaximumNotifications: all,
          },
        })
      }
    } catch (e) {
      console.log(e)
    }
  }
}

export const markRead = () => {
  return async (dispatch, getState) => {
    let _notifications = await getState().reducer.notifications
    let data = await postData('POST', URL_PATH.MARK_READ)
    if (data) {
      dispatch({
        type: notificationsConstants.NOTIFICATIONS,
        notifications: {
          unreadNotifications: [],
          readNotifications: _notifications.readNotifications
            ? _notifications.readNotifications
            : [],
          notificationsCount: 0,
        },
      })
    }
  }
}

export const checkNewnotifications = () => {
  return async (dispatch, getState) => {
    try {
      let notifications = await getState().reducer.notifications
      let data = await getData('GET', `${URL_PATH.NOTIFICATIONS}?all=${true}&unread=${true}`)
      // let data = await getData('GET', URL_PATH.NEW_NOTIFICATIONS)
      if (data && data.success && data.notifications.length > 0) {
        if (notifications.unreadNotifications.length === 0) {
          dispatch({
            type: notificationsConstants.NOTIFICATIONS,
            notifications: {
              unreadNotifications: data.notifications,
              notificationsCount: data.notifications.length,
            },
          })
        } else if (data.notifications.length > 0) {
          let previousDate = new Date(notifications.unreadNotifications[0].created_at)
          let newDate = new Date(data.notifications[0].created_at)
          if (previousDate.valueOf() !== newDate.valueOf()) {
            dispatch({
              type: notificationsConstants.NOTIFICATIONS,
              notifications: {
                unreadNotifications: data.notifications,
                notificationsCount: data.notifications.length,
              },
            })
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
}
