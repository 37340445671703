import { notification } from 'antd'
import base64 from 'base-64'
import dayjs from 'dayjs'
import fromNow from 'from-now'
import { toHijri } from 'hijri-date/lib/safe'

import { URL_PATH } from '../constants/api.constants'
import request from '../request/api'
import { getData } from './request.helper'

export const isBoolean = val => {
  return typeof val === 'boolean'
}

export const isAllowedLocation = async () => {
  const { NODE_ENV, REACT_APP_IPAPI_API_URL, REACT_APP_ALLOWED_COUNTRY } = process.env
  if (NODE_ENV === 'development') return true

  const dayInMilliseconds = 86400000
  const currentTimeStamp = new Date().getTime()
  let myIP = null

  // Temoporary fix for the old myip key
  try {
    myIP = JSON.parse(localStorage.getItem('myip'))
  } catch {
    myIP = null
  }
  if (myIP && myIP?.timeStamp && myIP.timeStamp + dayInMilliseconds > currentTimeStamp) return true

  try {
    const allowedCountries = REACT_APP_ALLOWED_COUNTRY.split(',') || []
    const { country_name, ip } = await getData('GET', REACT_APP_IPAPI_API_URL)

    if (allowedCountries.includes(country_name)) {
      localStorage.setItem('myip', JSON.stringify({ timeStamp: currentTimeStamp, ip: ip }))
      return true
    }
    return false
  } catch (error) {
    console.error('Request failed:', error)
    localStorage.removeItem('myip')

    return false
  }
}

export const isNumeric = e => {
  const code = e.which || e.keyCode
  if (code >= 48 && code <= 57) return true

  e.preventDefault()
  return false
}

export const minAllowedDOB = () => {
  const minDOB = dayjs().subtract(16, 'years')
  return dayjs(minDOB, 'YYYY/MM/DD')
}

export const disabledDateHandler = current => {
  let start = minAllowedDOB()
  return current && current > start
}

export const masked = iqama => {
  return iqama ? iqama.replace(iqama.substring(3, 7), '****') : ''
}

export const truncate = (text, limit) => {
  if (text.length > limit) return text.slice(0, limit > 3 ? limit - 3 : limit) + '...'
  return text
}

export const getTimefromnow = info => {
  return fromNow(info.time)
}

export const encodeIdAsync = id => {
  const encodePromise = new Promise((resolve, reject) => {
    const encodedId = base64.encode(id)
    if (encodedId) {
      resolve(encodedId)
    } else {
      reject(console.log("can't encode the id"))
    }
  })
  return encodePromise
}

export const isBase64 = path => {
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
  if (
    //path !== edit, path !== user, path !== dashboard were added because regex was dealing them as base64 values
    base64regex.test(path) === true &&
    path !== 'edit' &&
    path !== 'user' &&
    path !== 'dashboard'
  ) {
    return base64.decode(path)
  } else {
    return false
  }
}

export const decodeIdAsync = encodedId => {
  const decodePromise = new Promise((resolve, reject) => {
    const decodedId = base64.decode(encodedId)
    if (decodedId) {
      resolve(decodedId)
    } else {
      reject(console.log("can't decode the id"))
    }
  })
  return decodePromise
}

export const formatDate = date => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join('-')
}

export const sendOneNotification = (notificationMessage, iqamaId) => {
  let message = { message: notificationMessage }
  let send_sms = false
  let volunteer_iqamas = [iqamaId]
  let all = false
  let all_teams = undefined
  request({
    method: 'POST',
    url: URL_PATH.SEND_OTHER_NOTIFICATION,
    data: {
      ...message,
      send_sms,
      volunteer_iqamas,
      all,
      all_teams,
    },
  })
    .then(response => {
      if (response && response.success) {
        notification.success({
          title: 'Success',
          message: response.message,
        })
      } else {
        notification.error({
          title: 'Error',
          message: response.message,
        })
      }
    })
    .catch(e => {
      notification.error({
        title: 'Error',
        message: 'Sending notification failed.',
      })
    })
}

export const convertToHijri = date => {
  const hijraRegex = /(14)/
  if (hijraRegex.test(date)) {
    return date
  } else {
    const convertedDate = toHijri(new Date(date))
    return `${convertedDate._year}-${convertedDate._month}-${convertedDate._date}`
  }
}

export const getDeviceType = () => {
  const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent
  const isMobile = /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i.test(userAgent)
  const isTablet = /Tablet|iPad/i.test(userAgent)
  const width = window.innerWidth

  if (isTablet || (isMobile && width >= 768)) {
    return 'tablet'
  } else if (isMobile) {
    return 'mobile'
  } else {
    return 'desktop'
  }
}

export const getInitials = name => {
  if (!name) return ''
  return name
    .trim()
    .split(' ')
    .map(word => word[0].toUpperCase())
    .join('')
}
