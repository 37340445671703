import { AgeFromDate } from 'age-calculator'
import moment from 'moment'
import { URL_PATH } from '../constants/api.constants'
import { postData } from '../helpers/request.helper'

export const textFieldValidator = value => {
  var regex = new RegExp(/^[a-zA-Z\s]*$/)
  if (regex.test(value)) {
    return Promise.resolve()
  } else {
    return Promise.reject('Field should only contain alphabets.')
  }
}

export const compareToFirstPassword = form => (rule, value, callback) => {
  if (value && value !== form.getFieldValue('password')) {
    callback("Password and Confirm Password don't match.")
  } else {
    callback()
  }
}

export const validateToNextPassword = (form, confirmDirty) => (rule, value, callback) => {
  if (value && confirmDirty) {
    form.validateFields(['confirm'], { force: true })
  }
  callback()
}

export const emailValidator = value => {
  var regex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
  if (value) {
    if (!regex.test(value)) {
      return Promise.reject('Please enter valid email address.')
    }
  }
  return Promise.resolve()
}

export const numbersValidator = value => {
  var regex = new RegExp(/^[0-9]+$/)
  if (value) {
    if (!regex.test(value)) {
      return Promise.reject('Field should only contain numbers 0-9.')
    }
  }
  return Promise.resolve()
}

export const phoneNumberValidator = (phoneNo, countryCode) => {
  var regex = new RegExp(/^[0-9]+$/)
  if (phoneNo) {
    if (!regex.test(phoneNo)) {
      return Promise.reject('Field should only contain numbers 0-9.')
    }
    if (countryCode === '92') {
      if (phoneNo.substring(0, 1) !== '3' || phoneNo.length !== 10) {
        return Promise.reject(
          'Number should be 10 digits long and start with 3xxxxxxxxx for Pakistan.',
        )
      }
    } else if (countryCode === '966') {
      if (phoneNo.substring(0, 1) !== '5' || phoneNo.length !== 9) {
        return Promise.reject(
          'Number should be 9 digits long and start with 5xxxxxxxx for Saudi Arabia.',
        )
      }
    } else if (!countryCode) {
      return Promise.reject('Please enter country code first.')
    } else {
      return Promise.resolve()
    }
  }
  return Promise.resolve()
}

export const doesNumberExist = async (field, value, code) => {
  let data = {
    number: code + value,
    is_mobile: field === 'mobile' ? true : false,
  };
  const response = await postData('POST', URL_PATH.VERIFY_PHONE, data);
  return response.success;
};

const currentDate = date_of_birth => {
  let date = date_of_birth.toString().split('-')
  let ageFromDate = new AgeFromDate(
    new Date(parseInt(date[0], 10), parseInt(date[1], 10) - 1, parseInt(date[2], 10)),
  ).age
  return ageFromDate
}

export const experienceValidator = (value, dob) => {
  let valueExperience = parseInt(value, 10)
  if (valueExperience === 0) {
    return Promise.resolve()
  } else if (valueExperience < 0) {
    return Promise.reject('Please enter valid experience.')
  }
  // TODO: Fix PHVG experience
  if (dob && dob.format('YYYY/MM/DD') !== moment(moment(), 'YYYY/MM/DD').format('YYYY/MM/DD')) {
    let age = currentDate(dob.format('YYYY-MM-DD'))
    age = age - 10
    if (valueExperience > age) {
      return Promise.reject('Experience must be less than your age.')
    } else if (valueExperience < 0) {
      return Promise.reject('Please enter valid experience.')
    }
  }
  return Promise.resolve()
}

export const phvgExperienceValidator = value => {
  let phvgAge = currentDate('2011-01-01')
  if (value === 0) {
    return Promise.resolve()
  } else if (value < 0) {
    return Promise.reject('Please enter valid experience.')
  }
  if (value > phvgAge) {
    return Promise.reject('PHVG Experience should be ' + phvgAge + ' or lesser.')
  }
  return Promise.resolve()
}

export const birthdateValidator = value => {
  if (value.format('YYYY/MM/DD') === moment(moment(), 'YYYY/MM/DD').format('YYYY/MM/DD')) {
    return Promise.reject('Date of Birth is required')
  }
  return Promise.resolve()
}

export const emergencyPhoneValidator = (value, emergencyNumber) => {
  if (emergencyNumber && value === emergencyNumber) {
    return Promise.reject('Emergency phone number should not be same as Phone number')
  }
  return Promise.resolve()
}

export const validateQuestionRange = (value, length) => {
  if (value > length) {
    return Promise.reject(
      `Questions limit should be less than or equal to selected questions (${length})`,
    )
  }
  return Promise.resolve()
}
