import axios from 'axios'
import { notification } from 'antd'
import { token as tokenStorage } from '../localstorage/localstorage'
import { URL_PATH } from '../constants/api.constants'
import { store } from '../index'
import { push } from 'react-router-redux'
const instance = axios.create({
  baseURL: URL_PATH.API,
})

instance.defaults.headers.post['Content-Type'] = 'multipart/form-data' //URL_PATH.POST_CONTENT_TYPE

instance.interceptors.request.use(async config => {
  const token = await tokenStorage.getItem('token')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

const request = options => {
  const onSuccess = response => {
    if (response && response.data && response.data.status && !response.data.success && response.data.status === 401) {
      store.dispatch(push('/unauthorized'))
    } else {
      return response.data
    }
  }

  const onError = error => {
    // notification.error({
    //   message: 'Something went wrong. Please try again.',
    // })
    console.error('Request Failed:', error.config)
    if (error.response) {
      console.error('Status:', error.response.status)
      console.error('Data:', error.response.data)
      console.error('Headers:', error.response.headers)
    } else {
      console.error('Error Message:', error.message)
    }

    return Promise.reject(error.response || error.message)
  }

  return instance(options)
    .then(onSuccess)
    .catch(onError)
}

export default request
