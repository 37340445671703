import React from 'react'
import { Divider } from 'antd'
import { getTimefromnow } from './../../../../helpers/methods.helper'
import './style.scss'

class Notification extends React.Component {
  render() {
    const { message, created_at } = this.props.data
    const divider = this.props.divider
    return (
      <div>
        <div className="notificationtab">
          <h6>{message}</h6>
          <p style={{ margin: '0' }}>
            {created_at
              ? getTimefromnow({
                time: created_at,
                locale: 'en'
              })
              : ''}
          </p>
        </div>
        {divider ? <Divider style={{ margin: '10px 0px' }} /> : ''}
      </div>
    )
  }
}

export default Notification
