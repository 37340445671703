import { settings } from '../constants/app.constants'
import { postData } from '../helpers/request.helper'
import { URL_PATH } from '../constants/api.constants'

export const getAppAccessRules = () => {
  return async dispatch => {
    try{
      let rules = await postData('POST', URL_PATH.GETLAYOUTRULES , {current_user : true} )
      if (rules && rules.success) {
        dispatch({
          type: settings.GET_LAYOUT_RULES,
          rules: rules.rules,
        })
      }
    }catch(e){
      console.log(e)
    }
  }
}
