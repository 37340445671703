import { team as teamConstants } from '../constants/app.constants';
import { getData, postData } from '../helpers/request.helper';
import { URL_PATH } from '../constants/api.constants';

export const getTeamData = (id) => {
  return async (dispatch) => {
    let teamData = await getData('GET', URL_PATH.TEAM + id);
    if (teamData && teamData.success) {
      dispatch({
        type: teamConstants.SET_TEAM,
        team: teamData.team,
      });
    }
  };
};

export const refreshStore = () => {
  return async (dispatch) => {
    dispatch({
      type: teamConstants.SET_TEAM,
      team: '',
    });
  };
};

export const getTeamsData = () => {
  return async (dispatch) => {
    let teamsData = await getData('GET', URL_PATH.TEAMS);
    dispatch({
      type: teamConstants.SET_TEAMS,
      teams: teamsData,
    });
  };
};

export const getAllTeams = () => {
  return async (dispatch) => {
    let teamsData = await getData(
      'GET',
      URL_PATH.TEAM + URL_PATH.GET_TEAMS_POSTS
    );
    if (teamsData && teamsData.success) {
      dispatch({
        type: teamConstants.SET_ALL_TEAMS,
        allTeams: teamsData.teams,
      });
    }
  };
};

export const getTeamRooms = () => {
  return async (dispatch) => {
    let teamsData = await getData(
      'GET',
      URL_PATH.TEAM + URL_PATH.GET_TEAMS_ROOMS
    );
    if (teamsData && teamsData.success) {
      dispatch({
        type: teamConstants.SET_ALL_TEAMS,
        allTeams: teamsData.teams,
      });
    }
  };
};

export const getTeamsName = (showAllTeams) => {
  return async (dispatch) => {
    let teamsData = await getData(
      'GET',
      `${URL_PATH.TEAMS_NAME}${showAllTeams ? '?show_all_teams=true' : ''}`
    );
    dispatch({
      type: teamConstants.SET_TEAMS_NAME,
      teamsName: teamsData,
    });
  };
};

export const activeOrInactiveTeams = (params) => {
  return async (dispatch) => {
    let response = await postData('POST', URL_PATH.UPDATE_BULK_TEAMS, params);
    if (response && response.success) {
      dispatch({
        type: teamConstants.SET_TEAMS,
        teams: response,
      });
    }
  };
};
