export const menuData = role => {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      url: '/dashboard',
      mode: 'inline',
      icon: 'icmn icmn-stack',
    },
    ...(['Super Admin'].includes(role)
      ? [
          {
            title: 'PHVG Hajj Navigator',
            key: 'PHVGHajjNavigator',
            mode: 'inline',
            icon: 'icmn icmn-stack',
            count: 4,
            children: [
              {
                title: 'Locations',
                key: 'locations',
                url: '/phvg-hajj-navigator/locations',
              },
              {
                title: 'Regions / Countries',
                key: 'regions',
                url: '/phvg-hajj-navigator/regions',
              },
              {
                title: 'Translations',
                key: 'translations',
                url: '/phvg-hajj-navigator/translations',
              },
              {
                title: 'User Reviews',
                key: 'reviews',
                url: '/phvg-hajj-navigator/user-reviews',
              },
              {
                title: 'Settings',
                key: 'setting',
                url: '/phvg-hajj-navigator/settings',
              },
            ],
          },
        ]
      : []),
    ...(['Super Admin', 'Admin', 'Area Incharge', 'Team Leader', 'Assistant Team Leader', 'Trainer', 'Regional Admin'].includes(role)
      ? [
          {
            title: 'Manage',
            key: 'manage',
            mode: 'inline',
            icon: 'icmn icmn-menu',
            count: 8,
            children: [
              ...(['Super Admin', 'Admin'].includes(role)
                ? [
                    {
                      title: 'Projects',
                      key: 'projects',
                      url: '/manage/projects',
                      icon: 'icmn icmn-file-text2',
                    },
                  ]
                : []),

              ...(['Super Admin', 'Admin', 'Regional Admin', 'Area Incharge'].includes(role)
                ? [
                    {
                      title: 'Teams',
                      key: 'teams',
                      mode: 'inline',
                      icon: 'icmn icmn-users',
                      count: 2,
                      children: [
                        {
                          title: 'Team List',
                          key: 'team-list',
                          url: '/manage/teams',
                        },
                        ...(['Super Admin', 'Admin', 'Regional Admin'].includes(role)
                          ? [
                              {
                                title: 'Team Assignment',
                                key: 'team-assignment',
                                url: '/manage/teams/team-assignment',
                              },
                              {
                                title: 'Team Plan',
                                key: 'team-plan',
                                url: '/manage/teams/plans',
                              },
                              {
                                title: 'Post Assignment',
                                key: 'post-assignment',
                                url: '/manage/teams/post-assignment',
                              },
                              {
                                title: 'Room Assignment',
                                key: 'room-assignment',
                                url: '/manage/room-assignment',
                              },
                            ]
                          : []),
                      ],
                    },
                  ]
                : []),
              ...(['Super Admin', 'Admin', 'Area Incharge', 'Team Leader', 'Assistant Team Leader', 'Trainer', 'Regional Admin'].includes(role)
                ? [
                    {
                      title: ['Team Leader', 'Assistant Team Leader'].includes(role) ? 'Team Volunteers' : 'Volunteers',
                      key: 'volunteers',
                      icon: 'icmn icmn-user',
                      children: [
                        {
                          title: ['Team Leader', 'Assistant Team Leader'].includes(role) ? 'Team Volunteers' : 'Volunteers',
                          key: 'volunteer-list',
                          url: '/manage/volunteers',
                          icon: 'icmn icmn-user',
                        },

                        ...(['Super Admin', 'Admin'].includes(role)
                          ? [
                              {
                                title: 'Training Events',
                                key: 'trainings',
                                url: '/manage/events',
                                icon: 'icmn icmn-paste',
                              },
                            ]
                          : []),
                      ],
                    },
                  ]
                : []),
              ...(['Super Admin', 'Admin'].includes(role)
                ? [
                    {
                      title: 'Cities',
                      key: 'cities',
                      url: '/manage/cities',
                      icon: 'icmn icmn-table',
                    },
                  ]
                : []),
              ...(['Super Admin', 'Admin'].includes(role)
                ? [
                    {
                      title: 'Residences',
                      key: 'residences',
                      url: '/manage/residences',
                      icon: 'icmn icmn-exit',
                    },
                  ]
                : []),
              ...(['Super Admin', 'Admin'].includes(role)
                ? [
                    {
                      title: 'Rooms',
                      key: 'rooms',
                      url: '/manage/rooms',
                      icon: 'icmn icmn-exit',
                    },
                  ]
                : []),
              ...(['Super Admin'].includes(role)
                ? [
                    {
                      title: 'Tests',
                      key: 'tests',
                      icon: 'icmn icmn-file-text',
                      children: [
                        ...(['Super Admin'].includes(role)
                          ? [
                              {
                                title: 'Test List',
                                key: 'test-list',
                                url: '/manage/tests',
                              },
                              {
                                title: 'Test Assignment',
                                key: 'test-assignment',
                                url: '/manage/test/test-assignment',
                              },
                            ]
                          : []),
                      ],
                    },
                  ]
                : []),
              ...(['Super Admin', 'Admin'].includes(role)
                ? [
                    {
                      title: 'Posts',
                      key: 'posts',
                      url: '/manage/posts',
                      icon: 'icmn icmn-flag',
                    },
                    {
                      title: 'Camp / Road (Poles)',
                      key: 'poles',
                      url: '/manage/poles',
                      icon: 'icmn icmn-flag',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(['Super Admin', 'Admin', 'Regional Admin'].includes(role)
      ? [
          {
            title: 'Maps',
            key: 'maps',
            mode: 'inline',
            icon: 'icmn icmn-sphere',
            children: [
              ...(['Super Admin', 'Admin', 'Regional Admin'].includes(role)
                ? [
                    {
                      title: 'Dashboard',
                      key: 'maps-dashboard',
                      url: '/map/dashboard',
                    },
                    {
                      title: 'View Map',
                      key: 'view-maps',
                      url: '/map',
                    },
                    ...(['Super Admin', 'Admin'].includes(role)
                      ? [
                          {
                            title: 'Geo Fencing',
                            key: 'geofencing',
                            url: '/map/geofencing',
                          },
                        ]
                      : []),
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(['Super Admin', 'Admin', 'Regional Admin'].includes(role)
      ? [
          {
            title: 'Notification Center',
            key: 'notifications',
            url: '/send-notifications',
            mode: 'inline',
            icon: 'icmn icmn-bell',
          },
        ]
      : []),
    ...(['Super Admin', 'Admin'].includes(role)
      ? [
          {
            title: 'Lost Hujjaj',
            key: 'lost-hujjaj',
            url: '/lost-hujjaj',
            mode: 'inline',
            icon: 'fa fa-deafness',
          },
        ]
      : []),
  ]
}
