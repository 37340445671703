const pole = {
  SET_POLES: 'SET_POLES',
  SET_POLE: 'SET_POLE',
}
const training = {
  SET_TRAININGS: 'SET_TRAININGS',
  SET_TRAINING: 'SET_TRAINING',
}
const project = {
  SET_PROJECTS: 'SET_PROJECTS',
  SET_PROJECT: 'SET_PROJECT',
}
const buildings = {
  SET_BUILDINGS: 'SET_BUILDINGS',
  SET_BUILDING: 'SET_BUILDING',
};

const rooms = {
  SET_ROOMS: 'SET_ROOMS',
  SET_ROOM: 'SET_ROOM',
};

const activity = {
  SET_ACTIVITIES: 'SET_ACTIVITIES',
};

const cityCamps = {
  SET_CITY_CAMPS: 'SET_CITY_CAMPS',
  SET_CITY_CAMP: 'SET_CITY_CAMP',
};

const cities = {
  SET_CITIES: 'SET_CITIES',
  SET_CITY: 'SET_CITY',
};

const post = {
  SET_POSTS: 'SET_POSTS',
  SET_POST: 'SET_POST',
};

const volunteer = {
  SET_VOLUNTEERS: 'SET_VOLUNTEERS',
  SET_VOLUNTEER: 'SET_VOLUNTEER',
  SET_TEAM_VOLUNTEERS_FOR_ASSIGNMENT: 'SET_TEAM_VOLUNTEERS_FOR_ASSIGNMENT',
  SET_VOLUNTEER_AREA_INCHARGES: 'SET_VOLUNTEER_AREA_INCHARGES',
  START_DATE: '2018/01/01',
  DATE_FORMAT: 'YYYY/DD/MM',
};

const report = {
  SET_GENERIC_REPORT: 'SET_GENERIC_REPORT',
};

const iqama = {
  SET_IQAMA: 'SET_IQAMA',
};

const user = {
  SET_USER: 'SET_USER',
};

const team = {
  SET_TEAM: 'SET_TEAM',
  SET_TEAMS: 'SET_TEAMS',
  SET_ALL_TEAMS: 'SET_ALL_TEAMS',
  SET_TEAMS_NAME: 'SET_TEAMS_NAME',
};

const requiredData = {
  SET_REQUIRED_DATA: 'SET_REQUIRED_DATA',
};

const login = {
  AUTHENTICATED: 'AUTHENTICATED',
  REGISTRATION_URL: 'UNAUTHENTICATED',
};

const tests = {
  SET_TESTS: 'SET_TESTS',
  SET_TEST: 'SET_TEST',
  SET_CURRENT_TEST: 'SET_CURRENT_TEST',
  SET_TEST_PROGRESS: 'SET_TEST_PROGRESS',
  TEST_NOT_FOUND: 'TEST_NOT_FOUND',
};

const questions = {
  SET_QUESTIONS: 'SET_QUESTIONS',
  SET_QUESTION: 'SET_QUESTION',
  SET_QUESTION_ID: 'SET_QUESTION_ID',
};

const map = {
  SET_NEW_VOLUNTEER_LOCATION: 'SET_NEW_VOLUNTEER_LOCATION',
  SET_GEOFENCES: 'SET_GEOFENCES',
};
const notifications = {
  CHECK_NEW_NOTIFICATIONS: 'CHECK_NEW_NOTIFICATIONS',
  GET_READ_NOTIFICATIONS: 'GET_READ_NOTIFICATIONS',
  GET_ALL_READ_NOTIFICATIONS: 'GET_ALL_READ_NOTIFICATIONS',
  MARK_READ: 'MARK_READ',
  NOTIFICATIONS: 'NOTIFICATIONS',
};
const settings = {
  GET_SETTINGS: 'GET_SETTINGS',
  GET_INITIAL_SHIFTS: 'GET_INITIAL_SHIFTS',
  GET_LAYOUT_RULES: 'GET_LAYOUT_RULES',
  GET_HAJJ_NAV_ASSETS: 'GET_HAJJ_NAV_ASSETS',
  GET_HAJJ_NAV_SETTINGS: 'GET_HAJJ_NAV_SETTINGS',
  GET_HAJJ_NAV_REGIONS: 'GET_HAJJ_NAV_REGIONS',
};

const CITY_CENTERS = [
  { name: 'Jeddah', latlng: '39.1925442, 21.4856787' },
  { name: 'Khobar', latlng: '50.1959419, 26.2155519' },
  { name: 'Jubail', latlng: '49.5672031, 26.9585712' },
  { name: 'Dammam', latlng: '50.0829853, 26.4082193' },
  { name: 'Al Baha', latlng: '41.4707677, 20.0206956' },
  { name: 'Jazan', latlng: '42.5699059, 16.8876046' },
  { name: 'Madinah Al Munawarah ', latlng: '39.5624298, 24.5200264' },
  { name: 'Makkah Al Mukarramah', latlng: '39.8574832, 21.3833627' },
  { name: 'Najran', latlng: '44.2297596, 17.5630248' },
  { name: 'Yanbu', latlng: '38.1819283, 24.0161337' },
  { name: 'Qaseem', latlng: '43.4199999, 26.0603612' },
  { name: 'Tabouk', latlng: '36.5631092, 28.3839150' },
  { name: 'Taif', latlng: '40.4081889, 21.2748967' },
  { name: 'Abha', latlng: '42.5099151, 18.2506113' },
  { name: 'Rabigh', latlng: '39.0192312, 22.7878224' },
  { name: 'Riyadh', latlng: '46.6762839, 24.7159518' },
];

const MOBILE_APP_URLS = {
  IOS: 'https://apps.apple.com/pk/app/phvg-razakar/id1359738765',
  ANDROID: 'https://play.google.com/store/apps/details?id=com.emersive.phvgrazakar&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
}

export {
  pole,
  training,
  project,
  buildings,
  rooms,
  questions,
  tests,
  login,
  requiredData,
  team,
  user,
  iqama,
  report,
  volunteer,
  cities,
  cityCamps,
  activity,
  post,
  map,
  notifications,
  settings,
  CITY_CENTERS,
  MOBILE_APP_URLS
}
