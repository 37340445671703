import React from "react";
import { Typography, Timeline, Steps, Row, Col, List, Table, Tag } from "antd";
import CheckMark from "../../../../../components/CustomComponents/CheckMark/CheckMark";
import {
  user as userStorage,
  volunteersProfile,
  testProgress,
  volunteerTest
} from "../../../../../localstorage/localstorage";
const { Text } = Typography;
const { Step } = Steps;
const ListItem = List.Item;
const ListMeta = ListItem.Meta;
class HistoryPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      current: 0,
      historyData: [
        // {
        //   year: 1665,
        //   attendance: [
        //     { key: "training1", name: "Training 1", status: false },
        //     { key: "training2", name: "Training 2", status: false },
        //     {
        //       key: "makkah_dispatched",
        //       name: "Makkah Dispatched",
        //       status: false
        //     },
        //     {
        //       key: "mina_dispatched_10",
        //       name: "Mina Dispatched (10 Dhul Hijjah)",
        //       status: false
        //     },
        //     {
        //       key: "mina_dispatched_11",
        //       name: "Mina Dispatched (11 Dhul Hijjah)",
        //       status: false
        //     },
        //     {
        //       key: "mina_dispatched_12",
        //       name: "Mina Dispatched (12 Dhul Hijjah)",
        //       status: false
        //     },
        //     {
        //       key: "mina_attendance_10",
        //       name: "Mina Attendance (10 Dhul Hijjah)",
        //       status: false
        //     },
        //     {
        //       key: "mina_attendance_11",
        //       name: "Mina Attendance (11 Dhul Hijjah)",
        //       status: false
        //     },
        //     {
        //       key: "mina_attendance_12",
        //       name: "Mina Attendance (12 Dhul Hijjah)",
        //       status: false
        //     },
        //     { key: "tawaf_e_ziarat", name: "Tawaf e Ziarat", status: false },
        //     {
        //       key: "azizia_dispatched_12",
        //       name: "Azizia Dispatched (12 Dhul Hijjah)",
        //       status: false
        //     }
        //   ],
        //   role: "Team Leader",
        //   teamName: "PHVG 506"
        // },
        // {
        //   year: 1667,
        //   attendance: [
        //     { key: "training1", name: "Training 1", status: false },
        //     { key: "training2", name: "Training 2", status: false },
        //     {
        //       key: "makkah_dispatched",
        //       name: "Makkah Dispatched",
        //       status: false
        //     },
        //     {
        //       key: "mina_dispatched_10",
        //       name: "Mina Dispatched (10 Dhul Hijjah)",
        //       status: false
        //     },
        //     {
        //       key: "mina_dispatched_11",
        //       name: "Mina Dispatched (11 Dhul Hijjah)",
        //       status: false
        //     },
        //     {
        //       key: "mina_dispatched_12",
        //       name: "Mina Dispatched (12 Dhul Hijjah)",
        //       status: false
        //     },
        //     {
        //       key: "mina_attendance_10",
        //       name: "Mina Attendance (10 Dhul Hijjah)",
        //       status: false
        //     },
        //     {
        //       key: "mina_attendance_11",
        //       name: "Mina Attendance (11 Dhul Hijjah)",
        //       status: false
        //     },
        //     {
        //       key: "mina_attendance_12",
        //       name: "Mina Attendance (12 Dhul Hijjah)",
        //       status: false
        //     },
        //     { key: "tawaf_e_ziarat", name: "Tawaf e Ziarat", status: false },
        //     {
        //       key: "azizia_dispatched_12",
        //       name: "Azizia Dispatched (12 Dhul Hijjah)",
        //       status: false
        //     }
        //   ],
        //   role: "Team Leader",
        //   teamName: "PHVG 506"
        // }
      ],
      columns: [
        {
          title: "",
          dataIndex: "name",
          key: "name",
          render: text => <strong>{text}</strong>
        }
      ],
      data: []
    };
  }

  async componentDidMount() {
    let res = await volunteersProfile.getItem('volunteer_profile')
    this.setState({
      historyData : res ? res.volunteer_archives : []
    },()=>{
      let {columns,data,historyData} = this.state;
      historyData.forEach((v, i) => {
        let tmp = {
          title: v.year,
          dataIndex: v.year,
          key: v.year,
          align: "center"
        };
        columns.push(tmp);
        let tmp3 = {
          name: "Role"
        };
        tmp3[v.year] = v.role;
        tmp3.key = "role";
        let foundIndex = data.findIndex(x => x.key === tmp3.key);
        if (foundIndex !== -1) {
          data[foundIndex] = { ...data[foundIndex], ...tmp3 };
        } else {
          data.push(tmp3);
        }
        tmp3 = {
          name: "Team Name"
        };
        tmp3[v.year] = v.team;
        tmp3.key = "teamName";
        foundIndex = data.findIndex(x => x.key === tmp3.key);
        if (foundIndex !== -1) {
          data[foundIndex] = { ...data[foundIndex], ...tmp3 };
        } else {
          data.push(tmp3);
        }
  
        v.attendance.forEach((w, j) => {
          let tmp2 = {};
          tmp2.key = w.key;
          tmp2[tmp.dataIndex] = w.status ? (
            <CheckMark />
          ) : (
            <CheckMark type="unchecked" />
          );
          tmp2.name = w.name;
          let foundIndex = data.findIndex(x => x.key === w.key);
          if (foundIndex !== -1) {
            data[foundIndex] = { ...data[foundIndex], ...tmp2 };
          } else {
            data.push(tmp2);
          }
        });
      });
      this.setState({
        columns: columns,
        data: data,
        loading : false
      });
    })
  }

  onChange = current => {
    console.log("onChange:", current);
    this.setState({ current });
  };
  render() {
    const { historyData, current } = this.state;

    return (
      <React.Fragment>
        {/* <div className="row"> */}
        <div style={{ padding: "15px" }}>
{/* 
        <Steps
              current={this.state.current}
              progressDot
              onChange={this.onChange}
              direction="vertical"
              type="navigation"
            >
              {historyData.map((v, i) => {
                return (
                  <Step
                    title={v.year}
                    description={
                      <div>
                        <Text>
                          {v.role} <br />
                        </Text>
                        <Text>{v.teamName}</Text>
                      </div>
                    }
                    status={this.state.current == i ? "finish" : "wait"}
                  ></Step>
                );
              })}
            </Steps> */}
          {/* <Timeline>
              {historyData.map((v, i) => {
                return (
                  <Timeline.Item>
                    <strong
                      style={{ color: i == current ? "#00a65a" : undefined, cursor : 'pointer' }}
                      onClick={() => this.onChange(i)}
                    >
                      {v.year}
                    </strong>
                    <div>
                      <Text>
                        {v.role} <br />
                      </Text>
                      <Text>{v.team}</Text>
                    </div>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </div>

          <div className="col-md-9 px-5">
            <List
              bordered
              size="small"
              header={
                <strong>
                  Attendance for year {historyData[current] ? historyData[current].year : null}
                </strong>
              }
            >
              { historyData[current] ? historyData[current].attendance.map(v => {
                return (
                  <ListItem>
                    <ListMeta title={v.name} />
                    <div>
                      {v.status ? (
                        <CheckMark />
                      ) : (
                        <CheckMark type="unchecked" />
                      )}
                    </div>
                  </ListItem>
                );
              }) : null}
            </List> */}
          <Table
            columns={this.state.columns}
            dataSource={this.state.data}
            bordered={true}
            pagination={false}
            loading={this.state.loading}
          >
          </Table>
        </div>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default HistoryPanel;
