import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import Datepicker from './datepicker/datepicker'
import * as hijriUtils from '../utils/hijri_utils'

class HijriDatePicker extends Component {
  render() {
    return (
      <div>
        <Datepicker
          onValueChange={this.props.onChange}
          utils={hijriUtils}
          calendarType={'Hijri'}
          defaultValue={this.props.value}
        />
      </div>
    )
  }
}

export default HijriDatePicker
