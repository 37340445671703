import React from 'react'

export const errorRenderer = errors => {
  let error = []
  if (errors && errors instanceof Array) {
    errors.forEach(err => {
      error.push(
        <div>
          <div>• {err}</div>
          <br />
        </div>,
      )
    })
  } else {
    return errors
  }
  return error
}
