import React from 'react'
import { Link } from 'react-router-dom'

const Content = (props) => (
  <div className="text-center">
    <div className="w-50 d-inline-block pt-5 pb-5 mt-5 mb-5">
      <h1 className="mb-4">
        <strong>Error</strong>
      </h1>
      <p className="mb-4">{props.errorMessage}</p>
      <Link to="/" className="btn">
        Go to the dashboard page
      </Link>
    </div>
  </div>
)

export default Content
