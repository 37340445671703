import { createReducer } from 'redux-act'
import * as app from './app'
import { notification } from 'antd'
import { user as userStorage, token } from '../localstorage/localstorage'
import { _setHideLogin } from './app'
import { push } from 'react-router-redux'

export const REDUCER = 'login'

export async function submit(params, dispatch) {
  const { iqama_id, password } = params
  dispatch(app.addSubmitForm(REDUCER, params))
  let isLogin = await app.login(iqama_id, password, dispatch)
  if (isLogin && isLogin.success && isLogin.token) {
    window.localStorage.setItem('app.Authorization', isLogin.token)
    window.localStorage.setItem('app.Role', isLogin.volunteer.user.role)
    await userStorage.setItem('user', isLogin.volunteer)
    await token.setItem('token', isLogin.token)
    dispatch({
      type: 'AUTHENTICATED',
      message: isLogin.message,
      response: isLogin,
    })
    dispatch(_setHideLogin(true))
    dispatch(push('/dashboard'))
    notification.open({
      type: 'success',
      message: isLogin.message,
      description: 'Welcome to PHVG Razakar Web Portal.',
    })
    dispatch(app.deleteSubmitForm(REDUCER))
  } else {
    dispatch(app.deleteSubmitForm(REDUCER))
    return isLogin
  }
}

const initialState = {}
export default createReducer({}, initialState)
