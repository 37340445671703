import React from 'react';
import { Col, Row, Tabs, Tag } from 'antd';
import TeamInformation from './TeamInformation';
import ProjectInformation from './ProjectInformation';

const TeamAndProjectInformation = ({
  selectedProjects = [],
  teams,
  projects,
  goToTeam,
}) => {
  if (!teams && !projects) return null;

  const teamProjects =
    teams &&
    teams.reduce(
      (acc, team) => ({
        ...acc,
        [team.project_id]: team,
      }),
      {}
    );

  return (
    <div className='info-container'>
      <h6 className='text-center info__header'>Projects And Teams</h6>
      <div className='my-3'>
        <label className='info__title'>Selected Projects by Volunteer:&nbsp;</label>
        {selectedProjects.map((p) => (
          <Tag>{p}</Tag>
        ))}
      </div>
      <Tabs
        defaultActiveKey={projects[0].id}
        centered
        type='card'
        items={projects.map((p) => ({
          key: p.id,
          label: p.name,
          children: (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <ProjectInformation project={p} />
              </Col>
              <Col span={12}>
                <TeamInformation
                  team={teamProjects[p.id]}
                  goToTeam={goToTeam}
                />
              </Col>
            </Row>
          ),
        }))}
      />
    </div>
  );
};

export default TeamAndProjectInformation;
