import React from 'react'
import { Popover } from 'antd'
import './style.scss'
import { formatDate } from '../../../../../helpers/methods.helper'
const OtherInformation = ({ volunteer, currentDate }) => {
  let languages = []

  const RenderInfo = ({ title, detail }) => {
    return (
      <div className="detail-container">
        <div className="detail">
          <label className="info__title">{title}</label>

          <label className="info__detail">{detail}</label>
        </div>
      </div>
    )
  }

  return (
    <div className="info-container">
      <h6 className="text-center info__header">Other Information</h6>
      <hr />
      <div class="row">
        <div className="col-12 col-md-6 ">
          {volunteer.language
            ? volunteer.language.forEach((language, key) => {
                if (key === volunteer.language.length - 1) languages.push(language)
                else languages.push(language + ',')
              })
            : null}
          <RenderInfo title="Language" detail={languages} />

          {volunteer.education ? (
            <RenderInfo title="Education" detail={volunteer.education.name} />
          ) : (
            <RenderInfo title="Education" detail="" />
          )}

          <RenderInfo title="Blood Group" detail={volunteer.blood_grp} />
          <RenderInfo
            title="Experience"
            detail={`PHVG(${volunteer.phvg_experience}y), Job(${volunteer.job_experience}y)`}
          />
        </div>

        <div className="col-12 col-md-6">
          <RenderInfo
            title="Age"
            detail={`${currentDate(volunteer.date_of_birth)} (${formatDate(
              volunteer.date_of_birth,
            )})`}
          />
          <RenderInfo title="Emergency Person" detail={volunteer.emergency_contact_name} />
          <Popover
            content={
              volunteer && volunteer.emergency_contact_no
                ? volunteer.emergency_contact_no.international
                : '-'
            }
            title="International Emergency Number"
          >
            <RenderInfo
              title="Emergency Contact"
              detail={
                volunteer && volunteer.emergency_contact_no
                  ? volunteer.emergency_contact_no.local
                  : '-'
              }
            />
          </Popover>
        </div>
      </div>
    </div>
  )
}
export default OtherInformation
