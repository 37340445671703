import { pole as poleStorage, poles as polesStorage } from '../localstorage/localstorage'
import { pole as poleConstants } from '../constants/app.constants'
import { getData } from '../helpers/request.helper'
import { URL_PATH } from '../constants/api.constants'

export const getPoles = () => {
  return async dispatch => {
    let poles = await polesStorage.getItem('poles')
    if (poles && poles.success) {
      dispatch({
        type: poleConstants.SET_POLES,
        poles: poles,
      })
    } else {
      poles = await getData('GET', URL_PATH.POLES)
      await poleStorage.setItem('pole', poles)
      dispatch({
        type: poleConstants.SET_POLES,
        poles: poles,
      })
    }
  }
}

export const getNewPoles = () => {
  return async dispatch => {
    let poles = await getData('GET', URL_PATH.POLES)
    await poleStorage.setItem('pole', poles)
    dispatch({
      type: poleConstants.SET_POLES,
      poles: poles,
    })
  }
}
export const getPole = id => {
  return async dispatch => {
    let pole = await getData('GET', URL_PATH.POLES + id)
    await poleStorage.setItem(id, pole)
    dispatch({
      type: poleConstants.SET_POLE,
      pole: pole,
    })
  }
}

export const activeOrInactivePoles = poles => {
  return async dispatch => {
    await poleStorage.setItem('pole', poles)
    dispatch({
      type: poleConstants.SET_POLES,
      poles: poles
    })
  }
}