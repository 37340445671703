import React from 'react'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const CheckMark = props => {
  const { size, type } = props
  if (type === 'unchecked') {
    return <CloseCircleOutlined style={{ fontSize: size ? size : 20, color: 'red' }} />;
  } else {
    return <CheckCircleOutlined style={{ fontSize: size ? size : 20, color: 'green' }} />;
  }
}

export default CheckMark
