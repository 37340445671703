import { posts as postsStorage, post as postStorage } from '../localstorage/localstorage'
import { post as postsConstants } from '../constants/app.constants'
import { getData, postData } from '../helpers/request.helper'
import { URL_PATH } from '../constants/api.constants'

export const getPosts = () => {
  return async dispatch => {
    let posts = await postsStorage.getItem('posts')
    if (posts) {
      await postsStorage.removeItem('posts')
    }
    
    posts = await getData('GET', URL_PATH.POSTS)

    dispatch({
      type: postsConstants.SET_POSTS,
      posts: posts,
    })
  }
}

export const refreshStore = () => {
  return async dispatch => {
    dispatch({
      type: postsConstants.SET_POST,
      post: '',
    })
  }
}

export const getNewPosts = () => {
  return async dispatch => {
    let posts = await getData('GET', URL_PATH.POSTS)
    await postsStorage.setItem('posts', posts)
    dispatch({
      type: postsConstants.SET_POSTS,
      posts: posts,
    })
  }
}

export const getPost = id => {
  return async dispatch => {
    let post = await getData('GET', URL_PATH.POSTS + id)
    await postStorage.setItem(id, post)
    dispatch({
      type: postsConstants.SET_POST,
      post: post,
    })
  }
}

export const activeOrInactivePosts = posts => {
  return async dispatch => {
    await postsStorage.setItem('posts', posts)
    dispatch({
      type: postsConstants.SET_POSTS,
      posts: posts,
    })
  }
}
