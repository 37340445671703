import React from 'react'
import { Row, Col, Tag } from 'antd'

import './style.scss'

const TestInformation = (props) => {
    const { tests } = props;
    return (
        <div className="info-container">
            <h6 className="text-center info__header">Test Information</h6>
            <hr />
            {!tests ? (<div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                }}
            >
                <label className="text-center info__title">No Test attempted.</label>
            </div>) : (
                <React.Fragment>
                    {tests.map((t, idx) =>
                        <RenderTest totalQuestions={t.total_questions} totalMarks={t.total_marks} correctAnswers={t.total_correct} obtainedMarks={t.obtained_marks} timeSpent={t.consumed_time} isPass={t.isPass} showResult={t.show_result} categoryResults={t.category_results} attempts={tests.length} attempt_no={idx} />
                    )}
                </React.Fragment>
            )}
        </div>
    )
}

export default TestInformation

const RenderInfo = ({ title, detail }) => {
    return (
      <div className="detail-container">
        <div className="detail">
          <label className="info__title">{title}</label>

          <label className="info__detail">{detail}</label>
        </div>
      </div>
    )
}

const RenderTest = ({ totalQuestions, totalMarks, correctAnswers, obtainedMarks, timeSpent, isPass, showResult, categoryResults, attempts, attempt_no }) => {
    return (
        <div>
            {   attempts > 1 &&
                (<strong>
                    Attempt No. { attempt_no + 1 }
                </strong>)
            }
            

            <div class="row">
                <div className="col-12 col-md-6">
                    <RenderInfo title="Correct / Total Questions" detail={ `${correctAnswers} / ${totalQuestions}` } />
                </div>

                <div className="col-12 col-md-6">
                    <RenderInfo title="Obtained / Total Marks" detail={ `${obtainedMarks} / ${totalMarks}` } />
                </div>
                
                <div className="col-12 col-md-6">
                    <RenderInfo title="Time Taken" detail={ timeSpent } />
                </div>

                <div className="col-12 col-md-6">
                    { showResult && <RenderInfo title="Result" detail={ isPass ? <Tag color="success">Passed</Tag> : <Tag color="error">Failed</Tag> } /> }
                </div>
            </div>

            <p className="cat-summary-head">
                <br />
                Category-based Test Results
            </p>

            <div className="row">
                {
                    categoryResults.map((cat) => {
                        return (
                            <div className="col-12 col-md-6">
                                <RenderInfo title={ cat[0] } detail={ cat[1] } />
                            </div>
                        )
                    })
                }
            </div>

            { (attempts !== attempt_no + 1) && <hr />}
        </div>
    )
}