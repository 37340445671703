import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { reduce } from 'lodash'
import './style.scss'
import { loadableRoutes } from '../../../routes'
import { isBase64 } from '../../../helpers/methods.helper'
class Breadcrumb extends React.Component {
  state = {
    breadcrumb: [],
    customBreadcrumbs: null,
  }

  getPath(data, url, parents = []) {
    let items = reduce(
      data,
      (result, entry) => {
        if (result.length) {
          return result
        } else if (entry.url === url) {
          return [entry].concat(parents)
        } else if (entry.children) {
          let nested = this.getPath(entry.children, url, [entry].concat(parents))
          return nested ? nested : result
        }
        return result
      },
      [],
    )
    return items.length > 0 ? items : false
  }

  getBreadcrumb = (props, items) => {
    let { breadcrumb } = this.state
    let url = props.location.pathname
    let [activeMenuItem, ...path] = this.getPath(items, url)
    if (activeMenuItem && path.length) {
      breadcrumb = path.reverse().map((item, index) => {
        if (index === path.length - 1) {
          return (
            <span key={item.key}>
              <span className="breadcrumbBar__arrow text-muted" />
              <span className="text-muted">{item.title}</span>
              <span className="breadcrumbBar__arrow" />
              <strong>{activeMenuItem.title}</strong>
            </span>
          )
        } else {
          return (
            <span key={item.key}>
              <span className="breadcrumbBar__arrow text-muted" />
              <span className="text-muted">{item.title}</span>
            </span>
          )
        }
      })
    } else {
      breadcrumb = (
        <span>
          <span className="breadcrumbBar__arrow" />
          <strong>{props.name}</strong>
        </span>
      )
    }
    return breadcrumb
  }

  componentWillReceiveProps(newProps) {
    this.createBreadcrumbsPath(newProps)
  }

  componentDidMount() {
    this.createBreadcrumbsPath(this.props)
  }

  titleCase(str) {
    str = str.toLowerCase().split(' ')
    for (var i = 0; i < str.length; i++) {
      if (str[i] === 'phvg') {
        str[i] = str[i].toUpperCase()
      } else {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
      }
    }
    return str.join(' ')
  }
  createBreadcrumbsPath(props) {
    let cleanedUpRoutes = Object.keys(loadableRoutes)
    let url = props.location.pathname
    let previousLinks = ''
    url = url
      .split('/')
      .filter(v => v)
      .map(v => {
        previousLinks = previousLinks + `/${v}`
        return {
          link: previousLinks,
          name: this.titleCase(v.replace(/-/g, ' ')),
        }
      })
    let customBreadcrumbs = url.map((v, i) => {
      if (i === url.length - 1) {
        // CHECKING IF THE ACTIVE ITEM IS BASE64 OR NOT
        const isEncoded = !isBase64(v.name) ? v.name : null
        return (
          <React.Fragment key={i}>
            {isEncoded === null ? null : (
              <span key={i}>
                <span className="breadcrumbBar__arrow" />
                <strong>{v.name}</strong>
              </span>
            )}
          </React.Fragment>
        )
      } else {
        return (
          <span key={i}>
            <span className="breadcrumbBar__arrow text-muted" />
            {cleanedUpRoutes.includes(v.link) ? (
              <Link to={v.link} className="bread-link">
                {v.name}
              </Link>
            ) : (
              <span className="text-muted">{v.name}</span>
            )}
          </span>
        )
      }
    })

    this.setState({
      customBreadcrumbs: customBreadcrumbs,
    })
  }

  render() {
    return (
      <div className="breadcrumbBar">
        <div className="breadcrumbBar__path">
          <Link to={`/dashboard`} className="bread-link">
            Home
          </Link>
          {this.state.customBreadcrumbs}
        </div>
      </div>
    )
  }
}

export default withRouter(Breadcrumb)
