import React from 'react'
import './style.scss'
import CheckMark from '../../../../../components/CustomComponents/CheckMark/CheckMark'
const AttendancePanel = ({ volunteer }) => {
  const RenderAttendanceSheet = () => {
    return volunteer.attendance.map(obj => {
      return (
        <div key={obj.key} className="row">
          <div className="col-6 col-lg-10">
            <label>{obj.name}</label>
          </div>

          <div className="col-6 col-lg-2 d-flex justify-content-center align-items-center">
            <label>{obj.status ? <CheckMark /> : <CheckMark type="unchecked" />}</label>
          </div>
        </div>
      )
    })
  }
  return (
    <React.Fragment>
      {volunteer && volunteer.attendance ? (
        <div className="info-container">
          <h6 className="text-center info__header">Attendance</h6>
          <hr />
          <RenderAttendanceSheet />
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default AttendancePanel
