import {getData, postData} from '../helpers/request.helper'
import { map } from '../constants/app.constants'
import { MAP_CONSTANTS } from '../constants/map.constants'

export const getnewlocations = (filter) =>{
    return async dispatch => {
    postData('POST', MAP_CONSTANTS.URL_VOLUNTEERS, { filter })
      .then(res => {
        if ((res.success = true)) {
          dispatch({
              type: map.SET_NEW_VOLUNTEER_LOCATION,
              newVolunteerLocations: res
          })
        }
      })
      .catch(err => {
        console.warn(err)
      })
    }
}

export const getGeofencedAreas = (parameter) => {
  return async (dispatch) => {
    const response = await getData(
      'GET',
      parameter ? 'geofences?active=true' : 'geofences'
    );
    if (response.success)
      dispatch({
        type: map.SET_GEOFENCES,
        geofences: response.geofence,
      });
  };
};

export const volunteerInGeofences = (params) => {
  return (dispatch) => {
    dispatch({
      type: map.VOLUNTEER_IN_AREA,
      volunteerInArea: params,
    });
  };
};