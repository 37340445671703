export const ENGLISH_LABELS = {
  USER_AND_ACCOUNT_INFORMATION: 'User and Account Information',
  IQAMA_ID: 'Iqama ID',
  IQAMA_EXPIRY: 'Iqama Expiry Date',
  HIJRI: 'Hijri Calendar',
  GREGORIAN: 'Gregorian Calendar',
  EMAIL: 'Email Address',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',

  IQAMA_ID_PLACEHOLDER: 'Enter your Iqama ID',
  IQAMA_EXPIRY_PLACEHOLDER: 'Enter your Iqama expiry date',
  IQAMA_EXPIRY_GREGORIAN_PLACEHOLDER: 'Enter your Iqama expiry date (Gregorian)',
  EMAIL_PLACEHOLDER: 'Enter your email address',
  PASSWORD_PLACEHOLDER: 'Enter a new password',
  CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm your password',

  IQAMA_ID_ERROR_REQUIRED: 'Iqama ID is required',
  IQAMA_ID_ERROR_LENGTH: 'Iqama ID should be 10 digits long',
  IQAMA_EXPIRY_ERROR_REQUIRED: 'Iqama Expiry Date is required',
  IQAMA_EXPIRY_ERROR_VALID: 'Your Iqama’s expiry date has passed. Please select a new Iqama expiry date.',
  EMAIL_ERROR_REQUIRED: 'Email Address is required',
  EMAIL_ERROR_VALID: 'Please enter a valid email address',
  PASSWORD_ERROR_REQUIRED: 'Password is required',
  PASSWORD_ERROR_VALID: 'The password length should be at least 6 characters and should contain at least 1 alphabet and 1 special character',
  CONFIRM_PASSWORD_ERROR_REQUIRED: 'Confirm Password is required',
  CONFIRM_PASSWORD_ERROR_VALID: 'The passwords you entered do not match',

  PERSONAL_INFORMATION: 'Personal Information',
  UPLOAD_PICTURE: 'Upload a Picture',
  VOLUNTEER_PICTURE: 'Volunteer Picture',
  IQAMA_PICTURE: 'Iqama Picture',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  DATE_OF_BIRTH: 'Date of Birth',
  MOBILE_NUMBER: 'Mobile Number',
  WHATSAPP_NUMBER: 'WhatsApp Number',
  CITY: 'City',
  CAMP: 'Camp Name',
  DISTRICT: 'District Name',
  LANGUAGES: 'Languages',
  EDUCATION: 'Education Level',
  BLOOD_GROUP: 'Blood Group',

  FIRST_NAME_PLACEHOLDER: 'Enter your First Name',
  LAST_NAME_PLACEHOLDER: 'Enter your Last Name',
  DATE_OF_BIRTH_PLACEHOLDER: 'Enter your Date of Birth',
  MOBILE_NUMBER_PLACEHOLDER: 'Enter your Mobile Number',
  WHATSAPP_NUMBER_PLACEHOLDER: 'Enter your WhatsApp Number',
  CITY_PLACEHOLDER: 'Enter your City',
  CAMP_PLACEHOLDER: 'Enter your Camp Name',
  DISTRICT_PLACEHOLDER: 'Enter your District Name',
  LANGUAGES_PLACEHOLDER: 'Enter Languages',
  EDUCATION_PLACEHOLDER: 'Enter your Education Level',
  BLOOD_GROUP_PLACEHOLDER: 'Enter your Blood Group',

  VOLUNTEER_PICTURE_ERROR_REQUIRED: 'Volunteer picture is required',
  IQAMA_PICTURE_ERROR_REQUIRED: 'Iqama picture is required',
  FIRST_NAME_ERROR_REQUIRED: 'First Name is required',
  LAST_NAME_ERROR_REQUIRED: 'Last Name is required',
  DATE_OF_BIRTH_ERROR_REQUIRED: 'Date of Birth is required',
  MOBILE_NUMBER_ERROR_REQUIRED: 'Mobile Number is required',
  MOBILE_NUMBER_ERROR_PK: 'Invalid Number, the number should start with 3 and should have 10 digits',
  WHATSAPP_NUMBER_REQUIRED: 'WhatsApp Number is required',
  MOBILE_NUMBER_ERROR_SAU: 'Invalid WhatsApp Number, the number should start with 5 and should have 9 digits',
  NUMBER_ERROR_TAKEN: 'This number is already registered by another volunteer',
  CITY_ERROR_REQUIRED: 'City is required',
  CAMP_ERROR_REQUIRED: 'Camp Name is required',
  DISTRICT_ERROR_REQUIRED: 'District Name is required',
  LANGUAGES_ERROR_REQUIRED: 'Languages field is required',
  EDUCATION_ERROR_REQUIRED: 'Education Level is required',
  BLOOD_GROUP_ERROR_REQUIRED: 'Blood Group is required',

  EXPERIENCE_INFORMATION: 'Experience Information',
  JOB_TITLE: 'Job Title',
  COMPANY: 'Company',
  JOB_EXPERIENCE: 'Job Experience',
  PHVG_EXPERIENCE: 'PHVG Experience',
  PHVG_MINA_EXPERIENCE: 'PHVG Mina Experience',
  OTHER_VOLUNTEER_EXPERIENCE: 'Other Volunteer Experience',

  JOB_TITLE_PLACEHOLDER: 'Enter your Job Title',
  COMPANY_PLACEHOLDER: 'Enter Company Name',
  JOB_EXPERIENCE_PLACEHOLDER: 'Enter your Job Experience in years',
  PHVG_EXPERIENCE_PLACEHOLDER: 'Enter your PHVG Experience in years',
  PHVG_MINA_EXPERIENCE_PLACEHOLDER: 'Enter your PHVG Mina Experience',
  OTHER_VOLUNTEER_EXPERIENCE_PLACEHOLDER: 'Enter details of other volunteer experiences',

  JOB_TITLE_ERROR_REQUIRED: 'Job Title is required',
  COMPANY_ERROR_REQUIRED: 'Company Name is required',
  EXPERIENCE_ERROR_REQUIRED: 'Experience detail is required',
  EXPERIENCE_ERROR_AGE: 'Experience should not exceed your age',
  EXPERIENCE_ERROR_MAXIMUM: 'Experience should not exceed',
  PHVG_MINA_EXPERIENCE_ERROR_VALID: 'PHVG Mina Experience should not exceed PHVG Experience',

  EMERGENCY_INFORMATION: 'Emergency Information',
  EMERGENCY_CONTACT_NAME: 'Emergency Contact Name',
  EMERGENCY_CONTACT_NUMBER: 'Emergency Contact Number',

  EMERGENCY_CONTACT_NAME_PLACEHOLDER: 'Enter Emergency Contact Name',
  EMERGENCY_CONTACT_NUMBER_PLACEHOLDER: 'Enter Emergency Contact Number',

  EMERGENCY_CONTACT_NAME_ERROR_REQUIRED: 'Emergency Contact Name is required',
  EMERGENCY_CONTACT_NUMBER_ERROR_REQUIRED: 'Emergency Contact Number is required',
  EMERGENCY_CONTACT_NUMBER_NUMBER_PK: 'Invalid Emergency Contact Number for Pakistan',
  EMERGENCY_CONTACT_NUMBER_NUMBER_SAU: 'Invalid Emergency Contact Number for Saudi Arabia',
  EMERGENCY_CONTACT_NUMBER_ERROR_SAME: 'Emergency contact number cannot be the same as the mobile number',

  DRIVING_AND_CAR_INFORMATION: 'Driving and Car Information',
  DRIVING_LICENCE: 'Driving Licence',
  DRIVING_LICENCE_PICTURE: 'Driving Licence Picture',
  HAVE_CAR: 'Do you have a car?',
  HAVE_CAR_FOR_MAKKAH: 'Do you have a car for Makkah?',
  CAR_REGISTRATION_PICTURE: 'Car Registration Picture',

  DRIVING_LICENCE_ERROR_REQUIRED: 'Driving Licence detail is required',
  DRIVING_LICENCE_PICTURE_ERROR_REQUIRED: 'Driving Licence Picture is required',
  HAVE_CAR_ERROR_REQUIRED: 'Please indicate if you have a car',
  HAVE_CAR_FOR_MAKKAH_ERROR_REQUIRED: 'Please indicate if you have a car for Makkah',
  CAR_REGISTRATION_PICTURE_ERROR_REQUIRED: 'Car Registration Picture is required',

  OTHER_INFORMATION: 'Other Information',
  PERFORMED_HAJJ: 'Have you performed Hajj?',
  VACCINATION: 'Are you vaccinated?',

  PERFORMED_HAJJ_ERROR_REQUIRED: 'Please indicate if you have performed Hajj',
  VACCINATION_ERROR_REQUIRED: 'Please indicate your vaccination status',

  NEXT: 'Next',

  REGISTRATION_CLOSED: 'Currently registrations are closed',
  REGISTRATION_CLOSED_SUBTITILE: 'We will let you know as soon as registrations are open. Please download our mobile applications for better experience',
  REGISTRATION_CLOSED_WEB: 'Registrations are closed on web',
  REGISTRATION_CLOSED_WEB_SUBTITLE: 'Please download our mobile apps from Google play store and Apple app store for registration',

  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  COMPANY_NAME: 'Company Name',
  JOB_TITLE: 'Job Title',
  JOB_EXPERIENCE: 'Job Experience',
  DISTRICT: 'District',
  MOBILE_NUMBER: 'Mobile Number',
  WHATSAPP_NUMBER: 'Whatsapp Number',
  LANGUAGES: 'Languages',
  CITY: 'City',
  CAMP: 'Camp (if applicable)',
  CAMP_NAME: 'Camp Name',
  EDUCATION: 'Education',
  BLOOD_GROUP: 'Blood Group',
  SUBMIT_BUTTON: 'Submit',
  BACK: 'Back',
  DATE_OF_BIRTH: 'Date of Birth (YYYY/MM/DD)',
  EMERGENCY_CONTACT_NAME: 'Emergency Contact Name',
  EMERGENCY_CONTACT_NUMBER: 'Emergency Contact Number',
  VOLUNTEER_EXPERIENCE: 'PHVG Experience',
  VOLUNTEER_MINA_EXPERIENCE: 'PHVG Mina Experience',
  VOLUNTEER_OTHER_EXPERIENCE: 'Other Experience',
  IQAMA_ID: 'Iqama ID',
  IQAMA_EXPIRY_DATE: 'Iqama Expiry Date',
  PASSWORD: 'Password',
  SUBMIT: 'Please fill all the fields to continue.',
  CONFIRM_PASSWORD: 'Confirm Password',
  CAR: 'Car',
  CAR_HELPER_TEXT: 'Can you help PHVG by bringing your car to Makkah?',
  KIT: 'Mina Kit',
  IQAMA_MSG: 'Your Iqama’s expiry date has passed. Please select your new Iqama expiry date.',
  VACCINATION: 'Hajj Vaccination',
  PERFORMED_HAJJ: 'Performed Hajj',
  VOLUNTEER_IMAGE: 'Volunteer Image',
  IQAMA_IMAGE: 'Iqama Image',
  PREVIOUS_PASSWORD: 'Previous Password',
  IQAMA_EXPIRY_MSG: 'Iqama expiry should be in between 15 Zil-Hajj of current year and 14 Zil-Hajj of next year',
  VERIFICATION_TEXT: 'Iqama ID you entered is already registered.',
  LOGIN_ERROR: 'Something went wrong. Please try again later.',
  CONGRATULATIONS: 'Congratulations!',
  REGISTRATION_FAILED: 'Registration Failed',

  // Team
  TEAM_NAME: 'Team Name',
  TEAM_TYPE: 'Team Type',
  SHIFT: 'Shift',
  AREA_INCHARGE: 'Area Incharge',
  NO_OF_WHEELCHAIRS: 'Number of Wheel Chairs',
  TEAM_MAX_CAPACITY: 'Max. Volunteers Count',
  TEAM_LOCATION: 'Team Location',

  CAR_AVAILABILITY: 'Car Availability for Makkah',
  CAR_AVAILABILITY_HELPER_TEXT: 'Are you willing to take your car to Makkah and help PHVG volunteers?',
  ACTIVE: 'Active',
  AREA: 'Area:',
  FEMALE_VOLUNTEERS_TEXT: 'We are sorry. Currently we have arrangements for male volunteers only.',
  SELECT_GENDER_TEXT: 'Select your gender to continue.',
  SELECT_REGISTER_TEXT: 'Have you ever registered before?',
  GENDER: 'Gender',
  MALE: 'Male',
  FEMALE: 'Female',
  NEXT: 'Next',
  PERSONAL_INFORMATION: 'Personal Information',
  OTHER_INFORMATION: 'Other Information',
  IQAMA_INFORMATION: 'Iqama And Account Information',
  EMERGENCY_INFORMATION: 'Emergency Contact Information',
  CITY_NAME: 'City Name',
  REGISTRATION_OPEN: 'Registration Open',
  REGION: 'Region',
  LONGITUDE: 'Longitude',
  LATITUDE: 'Latitude',
  POLE_NAME: 'Pole Name',
  POST_NAME: 'Post Name',
  IMPORTANCE: 'Importance',
  VERIFIED: 'Verified',
  ROLE: 'Role',
  CONFIRMATION_TEXT: 'I confirm that all of the above information is correct.',
  TOOLTIP_TEXT: 'Please acknowledge.',
  VERIFY_IQAMA_TEXT: 'Verify Iqama Expiry Date',
  IQAMA_EXPIRY_DATE_ALERT: 'Request to Update Iqama Expiry',
  IQAMA_EXPIRY_DATE_ALERT_MSG: 'Iqama expiry date must be updated before Hajj. Volunteers with expired Iqama cannot go to Mina.',
  IQAMA_EXPIRY_DATE_ALERT_TOOLTIP: 'Use this option if you want to ask Volunteer to update their Iqama Expiry Date',
  IQAMA_EXPIRY_DATE_RANGE_MSG: 'Your Iqama’s expiry date has passed. Please select your new Iqama expiry date.',
  IQAMA_IMAGE_UPLOAD_CAPTION: 'Upload Iqama Image',
  IQAMA_IMAGE_UPDATE_ALERT: 'Request to Update Iqama Image',
  IQAMA_IMAGE_UPDATE_ALERT_MSG: 'Please update your Iqama Image',
  IQAMA_IMAGE_UPDATE_ALERT_TOOLTIP: 'Use this option if you want to ask Volunteer to update their Iqama information',
  VOLUNTEER_IMAGE_UPDATE_ALERT: 'Update Volunteer Profile Picture',
  VOLUNTEER_IMAGE_UPDATE_ALERT_MSG: 'Please update your Volunteer Image',
  // Test
  TEST_NAME: 'Test Name',
  TEST_INSTRUCTIONS: 'Test Instructions',
  // Question Labels
  TITLE: 'Title',
  QUESTION: 'Question',
  DISTRACTOR: 'Distractors',
  // ---------------------------
  CORRECT_VALUE: '(Select the correct value)',
  CATEGORY: 'Category',
  DIFFICULTY: 'Difficulty',
  QUESTION_ASSIGNMENT: 'Question Assignment',
  QUESTION_IMAGE: 'Select Question Image',
  TIME: 'Total Time For Test',
  VALID_IQAMA: 'Do you have a valid Saudi Residence status (Iqama)?',
  VALID_IQAMA_TEXT: 'You must have a valid Saudi Residence status (Iqama) to continue.',
  TEAM: 'Team',

  //LOGIN
  REGISTRATION_CLOSE: 'Registration is closed for now.',

  //ReportModal
  WARNING_PDF_REPORT: 'PDF Report can not be generated for Teams with Volunteers.Please Select Other Options.',
  EMAIL_SENT_MSG: 'Report has been sent.Please Check your inbox/spam folder.',
  PDF_REPORT_MSG: 'Selecting more than 12 filters (columns) may disturb the PDF view.',

  PLACEHOLDER_ENTER_JOB_EXP: 'Enter Job Experience',
  PLACEHOLDER_ENTER_JOB_TITLE: 'Enter Job Title',
  PLACEHOLDER_ENTER_COMPANY: 'Enter Company Name',
  PLACEHOLDER_ENTER_WHATSAPP: 'Enter Whatsapp Number',
  PLACEHOLDER_ENTER_MOBILE_NO: 'Enter Mobile Number',
  PLACEHOLDER_ENTER_EMERGENCY_NO: 'Enter Emergency Number',
  PLACEHOLDER_SELECT_LANGUAGES: 'Select Languages',
  PLACEHOLDER_SELECT_TEAM: 'Select Team',
  PLACEHOLDER_SELECT_CITY: 'Select City',
  PLACEHOLDER_SELECT_CAMP: 'Setect Camp',
  PLACEHOLDER_ENTER_YEARS: 'Enter number of years',
  PLACEHOLDER_COUNTRY_CODE: 'Select Country Code',
  PLACEHOLDER_SELECT_DISTRICT: 'Select District',
  PLACEHOLDER_SELECT_EDUCATION: 'Select Education',
  PLACEHOLDER_SELECT_BLOOD_GRP: 'Select Blood Group',
  PLACEHOLDER_SELECT_DOB: 'Select Date of Birth',
  PLACEHOLDER_EMERGENCY_CONTACT_NAME: 'Emergency Contact Name',
  ERROR_MESSAGE_FIRST_NAME: 'Please enter your first name.',
  ERROR_MESSAGE_LAST_NAME: 'Please enter your last name.',
  ERROR_MESSAGE_PHONE_NUMBER: 'Please enter your phone number.',
  ERROR_MESSAGE_WHATSAPP_NUMBER: 'Please enter your whatsapp number.',
  ERROR_MESSAGE_LANGUAGE: 'Please select your language.',
  ERROR_MESSAGE_CITY: 'Please select your city.',
  ERROR_MESSAGE_DISTRICT: 'Please select your district.',
  ERROR_MESSAGE_EDUCATION: 'Please select your education.',
  ERROR_MESSAGE_BLOOD_GRP: 'Please select your blood group.',
  ERROR_MESSAGE_SELECT_DOB: 'Please enter your date of birth.',
  ERROR_MESSAGE_EMERGENCY_CONTACT_NAME: 'Please enter your emergency contact name.',
  ERROR_MESSAGE_COUNTRY_CODE: 'Please select your country code.',
  ERROR_MESSAGE_EMERGENCY_NO: 'Please enter your emergency contact number.',
  UPDATE_VOLUNTEER_IMAGE: 'Update Volunteer Image',

  PLACEHOLDER_ENTER_IQAMA_ID: 'Enter 10 digits Iqama ID',
  ERROR_MESSAGE_IQAMA_ID: 'Iqama ID should be 10 digits long.',
  ERROR_MESSAGE_IQAMA_ID_EMPTY: 'Please enter your iqama id',

  ERROR_MESSAGE_ROLE: 'Please select a role.',
  PLACEHOLDER_SELECT_ROLE: 'Select Role',
  ERROR_MESSAGE_EMAIL: 'Please enter your Email',
  ERROR_MESSAGE_EMAIL_VALID: 'Please enter a valid Email',
  PLACEHOLDER_ENTER_PREVIOUS_PASSWORD: 'Enter Previous Password',
  ERROR_MESSAGE_PASSWORD: 'Password should be at least 6 digits long.',
  ERROR_PASSWORD_MISMATCH: 'The two passwords that you entered do not match.',
  PLACEHOLDER_ENTER_NEW_PASSWORD: 'Enter New Password (minimum 6 characters).',
  PLACEHOLDER_CONFIRM_NEW_PASSWORD: 'Confirm New Password (minimum 6 characters)',
  ERROR_MESSAGE_GENDER: 'Please select your gender to continue.',
  Volunteer: 'Volunteer',
  'Super Admin': 'Super Admin',
  'Regional Admin': 'Regional Admin',
  Admin: 'Admin',
  'Assistant Team Leader': 'Assistant Team Leader',
  'Team Leader': 'Team Leader',
  'Area Incharge': 'Area Incharge',
  Trainer: 'Trainer',
  TAKEN_NUMBER_M: 'Mobile number is already registered.',
  TAKEN_NUMBER_WA: 'Whatsapp number is already registered.',
  DOWNLOAD_APP_MSG: 'Please download our mobile application "PHVG Razakar" and register for the Hajj 1444 event by completing the registration form.',
}

export const URDU_LABELS = {
  USER_AND_ACCOUNT_INFORMATION: 'صارف اور اکاؤنٹ کی معلومات',
  IQAMA_ID: 'آقامہ آئی ڈی',
  IQAMA_EXPIRY: 'آقامہ ختم ہونے کی تاریخ',
  HIJRI: 'ہجری کیلنڈر',
  GREGORIAN: 'گریگورین کیلنڈر',
  EMAIL: 'ای میل ایڈریس',
  PASSWORD: 'پاس ورڈ',
  CONFIRM_PASSWORD: 'پاس ورڈ کی تصدیق کریں',

  IQAMA_ID_PLACEHOLDER: 'آپ کا آقامہ آئی ڈی درج کریں',
  IQAMA_EXPIRY_PLACEHOLDER: 'آپ کی آقامہ ختم ہونے کی تاریخ درج کریں',
  IQAMA_EXPIRY_GREGORIAN_PLACEHOLDER: 'آپ کی آقامہ ختم ہونے کی تاریخ (گریگورین) درج کریں',
  EMAIL_PLACEHOLDER: 'آپ کا ای میل ایڈریس درج کریں',
  PASSWORD_PLACEHOLDER: 'نیا پاس ورڈ درج کریں',
  CONFIRM_PASSWORD_PLACEHOLDER: 'پاس ورڈ دوبارہ درج کریں',

  IQAMA_ID_ERROR_REQUIRED: 'آقامہ آئی ڈی ضروری ہے',
  IQAMA_ID_ERROR_LENGTH: 'آقامہ آئی ڈی 10 ہنرے کا ہونا چاہئے',
  IQAMA_EXPIRY_ERROR_REQUIRED: 'آقامہ ختم ہونے کی تاریخ ضروری ہے',
  IQAMA_EXPIRY_ERROR_VALID: 'آپ کی آقامہ کی ختم ہونے کی تاریخ گزر چکی ہے۔ براہ کرم اپنی نئی آقامہ ختم ہونے کی تاریخ منتخب کریں۔',
  EMAIL_ERROR_REQUIRED: 'ای میل ایڈریس ضروری ہے',
  EMAIL_ERROR_VALID: 'براہ کرم ایک درست ای میل ایڈریس درج کریں',
  PASSWORD_ERROR_REQUIRED: 'پاس ورڈ ضروری ہے',
  PASSWORD_ERROR_VALID: 'پاس ورڈ کی لمبائی کم از کم 6 ہونی چاہئے اور کم از کم 1 حرف اور 1 خصوصی حرف شامل ہونا چاہئے',
  CONFIRM_PASSWORD_ERROR_REQUIRED: 'پاس ورڈ کی تصدیق ضروری ہے',
  CONFIRM_PASSWORD_ERROR_VALID: 'آپ نے درج کردے گئے پاس ورڈز میچ نہیں کیے ہیں',

  PERSONAL_INFORMATION: 'ذاتی معلومات',
  UPLOAD_PICTURE: 'تصویر اپ لوڈ کریں',
  VOLUNTEER_PICTURE: 'والنٹیئر کی تصویر',
  IQAMA_PICTURE: 'آقامہ کی تصویر',
  FIRST_NAME: 'پہلا نام',
  LAST_NAME: 'آخری نام',
  DATE_OF_BIRTH: 'پیدائش کی تاریخ',
  MOBILE_NUMBER: 'موبائل نمبر',
  WHATSAPP_NUMBER: 'واٹس ایپ نمبر',
  CITY: 'شہر',
  CAMP: 'کیمپ کا نام',
  DISTRICT: 'ضلع کا نام',
  LANGUAGES: 'زبانیں',
  EDUCATION: 'تعلیم',
  BLOOD_GROUP: 'خون کا گروپ',

  FIRST_NAME_PLACEHOLDER: 'آپ کا پہلا نام درج کریں',
  LAST_NAME_PLACEHOLDER: 'آپ کا آخری نام درج کریں',
  DATE_OF_BIRTH_PLACEHOLDER: 'آپ کی پیدائش کی تاریخ درج کریں',
  MOBILE_NUMBER_PLACEHOLDER: 'آپ کا موبائل نمبر درج کریں',
  WHATSAPP_NUMBER_PLACEHOLDER: 'آپ کا واٹس ایپ نمبر درج کریں',
  CITY_PLACEHOLDER: 'آپ کا شہر درج کریں',
  CAMP_PLACEHOLDER: 'آپ کا کیمپ کا نام درج کریں',
  DISTRICT_PLACEHOLDER: 'آپ کا ضلع کا نام درج کریں',
  LANGUAGES_PLACEHOLDER: 'زبانیں درج کریں',
  EDUCATION_PLACEHOLDER: 'آپ کی تعلیم درج کریں',
  BLOOD_GROUP_PLACEHOLDER: 'آپ کا خون کا گروپ درج کریں',

  VOLUNTEER_PICTURE_ERROR_REQUIRED: 'والنٹیئر کی تصویر ضروری ہے',
  IQAMA_PICTURE_ERROR_REQUIRED: 'آقامہ کی تصویر ضروری ہے',
  FIRST_NAME_ERROR_REQUIRED: 'پہلا نام ضروری ہے',
  LAST_NAME_ERROR_REQUIRED: 'آخری نام ضروری ہے',
  DATE_OF_BIRTH_ERROR_REQUIRED: 'پیدائش کی تاریخ ضروری ہے',
  MOBILE_NUMBER_ERROR_REQUIRED: 'موبائل نمبر ضروری ہے',
  MOBILE_NUMBER_ERROR_PK: 'غلط نمبر، نمبر 3 سے شروع ہونا چاہئے اور 10 ہنرے ہونے چاہئیں',
  WHATSAPP_NUMBER_REQUIRED: 'واٹس ایپ نمبر ضروری ہے',
  MOBILE_NUMBER_ERROR_SAU: 'غلط واٹس ایپ نمبر، نمبر 5 سے شروع ہونا چاہئے اور 9 ہنرے ہونے چاہئیں',
  NUMBER_ERROR_TAKEN: 'یہ نمبر پہلے ہی ایک دوسرے والنٹیئر کی طرف سے رجسٹر ہو چکا ہے',
  CITY_ERROR_REQUIRED: 'شہر ضروری ہے',
  CAMP_ERROR_REQUIRED: 'کیمپ کا نام ضروری ہے',
  DISTRICT_ERROR_REQUIRED: 'ضلع کا نام ضروری ہے',
  LANGUAGES_ERROR_REQUIRED: 'زبانوں کا شعبہ ضروری ہے',
  EDUCATION_ERROR_REQUIRED: 'تعلیم کی سطح ضروری ہے',
  BLOOD_GROUP_ERROR_REQUIRED: 'خون کا گروپ ضروری ہے',

  EXPERIENCE_INFORMATION: 'تجربے کی معلومات',
  JOB_TITLE: 'وظیفہ کا عنوان',
  COMPANY: 'کمپنی',
  JOB_EXPERIENCE: 'وظیفہ کا تجربہ',
  PHVG_EXPERIENCE: 'پی ایچ وی جی تجربہ',
  PHVG_MINA_EXPERIENCE: 'پی ایچ وی جی مینا تجربہ',
  OTHER_VOLUNTEER_EXPERIENCE: 'دوسرے والنٹیئر کے تجربے کی تفصیل',

  JOB_TITLE_PLACEHOLDER: 'آپ کا وظیفہ کا عنوان درج کریں',
  COMPANY_PLACEHOLDER: 'کمپنی کا نام درج کریں',
  JOB_EXPERIENCE_PLACEHOLDER: 'آپ کا وظیفہ کا تجربہ سالوں میں درج کریں',
  PHVG_EXPERIENCE_PLACEHOLDER: 'آپ کا پی ایچ وی جی تجربہ سالوں میں درج کریں',
  PHVG_MINA_EXPERIENCE_PLACEHOLDER: 'آپ کا پی ایچ وی جی مینا تجربہ درج کریں',
  OTHER_VOLUNTEER_EXPERIENCE_PLACEHOLDER: 'دوسرے والنٹیئر تجربے کی تفصیل درج کریں',

  JOB_TITLE_ERROR_REQUIRED: 'وظیفہ کا عنوان ضروری ہے',
  COMPANY_ERROR_REQUIRED: 'کمپنی کا نام ضروری ہے',
  EXPERIENCE_ERROR_REQUIRED: 'تجربہ کی تفصیل ضروری ہے',
  EXPERIENCE_ERROR_AGE: 'تجربہ آپ کی عمر سے زیادہ نہیں ہونا چاہئے',
  EXPERIENCE_ERROR_MAXIMUM: 'تجربہ زیادہ نہیں ہونا چاہئے',
  PHVG_MINA_EXPERIENCE_ERROR_VALID: 'پی ایچ وی جی مینا تجربہ پی ایچ وی جی تجربہ سے زیادہ نہیں ہونا چاہئے',

  EMERGENCY_INFORMATION: 'اضطراری معلومات',
  EMERGENCY_CONTACT_NAME: 'اضطراری رابطے کا نام',
  EMERGENCY_CONTACT_NUMBER: 'اضطراری رابطے کا نمبر',

  EMERGENCY_CONTACT_NAME_PLACEHOLDER: 'اضطراری رابطے کا نام درج کریں',
  EMERGENCY_CONTACT_NUMBER_PLACEHOLDER: 'اضطراری رابطے کا نمبر درج کریں',

  EMERGENCY_CONTACT_NAME_ERROR_REQUIRED: 'اضطراری رابطے کا نام ضروری ہے',
  EMERGENCY_CONTACT_NUMBER_ERROR_REQUIRED: 'اضطراری رابطے کا نمبر ضروری ہے',
  EMERGENCY_CONTACT_NUMBER_NUMBER_PK: 'غلط اضطراری رابطے کا نمبر پاکستان کے لئے',
  EMERGENCY_CONTACT_NUMBER_NUMBER_SAU: 'غلط اضطراری رابطے کا نمبر سعودی عرب کے لئے',
  EMERGENCY_CONTACT_NUMBER_ERROR_SAME: 'اضطراری رابطے کا نمبر موبائل نمبر کے برابر نہیں ہو سکتا',

  DRIVING_AND_CAR_INFORMATION: 'گاڑی چلانے اور کار کی معلومات',
  DRIVING_LICENCE: 'ڈرائیونگ لائسنس',
  DRIVING_LICENCE_PICTURE: 'ڈرائیونگ لائسنس کی تصویر',
  HAVE_CAR: 'کیا آپ کے پاس گاڑی ہے؟',
  HAVE_CAR_FOR_MAKKAH: 'کیا آپ کے پاس مکہ کے لئے گاڑی ہے؟',
  CAR_REGISTRATION_PICTURE: 'کار کی رجسٹریشن کی تصویر',

  DRIVING_LICENCE_ERROR_REQUIRED: 'ڈرائیونگ لائسنس کی تفصیل ضروری ہے',
  DRIVING_LICENCE_PICTURE_ERROR_REQUIRED: 'ڈرائیونگ لائسنس کی تصویر ضروری ہے',
  HAVE_CAR_ERROR_REQUIRED: 'براہ کرم بتائیں کہ آپ کے پاس گاڑی ہے یا نہیں',
  HAVE_CAR_FOR_MAKKAH_ERROR_REQUIRED: 'براہ کرم بتائیں کہ آپ کے پاس مکہ کے لئے گاڑی ہے یا نہیں',
  CAR_REGISTRATION_PICTURE_ERROR_REQUIRED: 'کار کی رجسٹریشن کی تصویر ضروری ہے',

  OTHER_INFORMATION: 'دوسری معلومات',
  PERFORMED_HAJJ: 'کیا آپ نے حج کیا ہے؟',
  VACCINATION: 'آپ ویکسینیٹ کئے گئے ہیں؟',

  PERFORMED_HAJJ_ERROR_REQUIRED: 'براہ کرم بتائیں کہ آپ نے حج کیا ہے یا نہیں',
  VACCINATION_ERROR_REQUIRED: 'براہ کرم آپ کی ویکسینیشن کی حیثیت بتائیں',

  NEXT: 'اگلا',

  PERSONAL_INFORMATION: '',
  VOLUNTEER_PICTURE: '',
  IQAMA_PICTURE: '',
  FIRST_NAME: 'نام',
  LAST_NAME: 'فیملی کا نام',
  DATE_OF_BIRTH: 'تاریخ پیدائش',
  MOBILE_NUMBER: 'موبائل نمبر',
  WHATSAPP_NUMBER: 'واٹس ایپ نمبر',
  CITY: 'شہر',
  CAMP: 'کیمپ',
  DISTRICT: 'ڈسٹرکٹ',
  LANGUAGES: 'زبان',
  EDUCATION: 'تعلیم',
  BLOOD_GROUP: 'بلڈ گروپ',

  FIRST_NAME_PLACEHOLDER: 'نام',
  LAST_NAME_PLACEHOLDER: 'فیملی کا نام',
  DATE_OF_BIRTH_PLACEHOLDER: 'تاریخ پیدائش',
  MOBILE_NUMBER_PLACEHOLDER: 'موبائل نمبر',
  WHATSAPP_NUMBER_PLACEHOLDER: 'واٹس ایپ نمبر',
  CITY_PLACEHOLDER: 'شہر',
  CAMP_PLACEHOLDER: 'کیمپ',
  DISTRICT_PLACEHOLDER: 'ڈسٹرکٹ',
  LANGUAGES_PLACEHOLDER: 'زبان',
  EDUCATION_PLACEHOLDER: 'تعلیم',
  BLOOD_GROUP_PLACEHOLDER: 'بلڈ گروپ',

  FIRST_NAME_ERROR: 'نام',
  LAST_NAME_ERROR: 'فیملی کا نام',
  DATE_OF_BIRTH_ERROR: 'تاریخ پیدائش',
  MOBILE_NUMBER_ERROR: 'موبائل نمبر',
  WHATSAPP_NUMBER_ERROR: 'واٹس ایپ نمبر',
  CITY_ERROR: 'شہر',
  CAMP_ERROR: 'کیمپ',
  DISTRICT_ERROR: 'ڈسٹرکٹ',
  LANGUAGES_ERROR: 'زبان',
  EDUCATION_ERROR: 'تعلیم',
  BLOOD_GROUP_ERROR: 'بلڈ گروپ',

  EMAIL: 'ای میل',
  COMPANY_NAME: 'کمپنی کا نام',
  BACK: 'پیچھے',
  NEXT: 'اگے',
  SUBMIT_BUTTON: 'جمع کرائیں',
  EMERGENCY_CONTACT_NAME: 'رابطے کا نام ',
  EMERGENCY_CONTACT_NUMBER: 'رابطے کا نمبر',
  VOLUNTEER_EXPERIENCE: 'پی ایچ وی جی کا تجربہ ',
  VOLUNTEER_MINA_EXPERIENCE: 'پی ایچ وی جی منٰی کا تجربہ',
  VOLUNTEER_OTHER_EXPERIENCE: 'دوسرا تجربہ',
  IQAMA_ID: 'اقامہ نمبر',
  IQAMA_EXPIRY_DATE: 'اقامہ کی تاریخ تنسیخ',
  PASSWORD: 'پاس ورڈ',
  SUBMIT: 'برائے مہربانی تمام فیلڈز کو پُر کریں۔',
  CONFIRM_PASSWORD: 'پاس ورڈ کی تصدیق کریں',
  CAR: 'ذاتی گاڑی',
  CAR_HELPER_TEXT: 'کیا آپ اپنی گاڑی مکہ لے جا سکتے ہیں؟',
  KIT: 'آلات',
  TEST: 'ٹیست',
  VACCINATION: 'ویکسین',
  PERFORMED_HAJJ: 'کیا آپ حج کر چکے ہیں۔؟',
  VOLUNTEER_IMAGE: 'رضاکار کی تصویر',
  IQAMA_IMAGE: 'اقامہ کی تصویر',
  PREVIOUS_PASSWORD: 'پرانے خوفیہ حروف',
  CAR_AVAILABILITY: 'کار کی دستیابی، مکہ کے لئے',
  CAR_AVAILABILITY_HELPER_TEXT: 'کیا آپ اپنی گاڑی مکہ لے جا کر پی ایچ وی جی کے رضاکاروں کی مدد کرنے کے لئے تیار ہیں؟',
  PERSONAL_INFORMATION: 'ذاتی معلومات',
  OTHER_INFORMATION: 'دیگر معلومات',
  IQAMA_INFORMATION: 'اقامہ اور اکاؤنٹ کی معلومات',
  EMERGENCY_INFORMATION: 'ہنگامی حالات میں رابطے کی معلومات ',
  FEMALE_VOLUNTEERS_TEXT: 'ہم معذرت خواہ ہیں۔ اس وقت صرف مرد رضاکاروں کے لیے انتظامات میسر ہیں۔',
  SELECT_GENDER_TEXT: 'اپنی جنس کا انتخاب کیجئے۔',
  SELECT_REGISTER_TEXT: 'کیا آپ نے پہلے کبھی اندراج کیا ہے؟ ',
  GENDER: 'صنف',
  MALE: 'مرد',
  FEMALE: 'عورت',
  JOB_TITLE: 'کام کی نوعیت',
  JOB_EXPERIENCE: 'کام کا تجربہ',
  ACTIVE: 'فعال',
  IQAMA_MSG: 'آپ کے اقامہ کی میعاد ختم ہو چکی ہے۔ براہ مہربانی، نئے اقامہ کی تاریخ تنسیخ کا انتخاب کیجیئے۔',
  VERIFIED: 'تصدیق شدہ',
  CONFIRMATION_TEXT: 'میں تصدیق کرتا ہوں کہ مندرجہ بالا معلومات باکل درست ہیں',
  TOOLTIP_TEXT: 'براہ مہربانی تصدیق کریں۔',
  VERIFY_IQAMA_TEXT: 'اقامہ کی تاریخ تنسیخ کی تصدیق کریں',
  IQAMA_EXPIRY_DATE_ALERT: 'اقامہ کی تاریخ تنسیخ کو اپ ڈیٹ کرنے کی درخواسث کریں',
  IQAMA_EXPIRY_DATE_ALERT_MSG: 'اقامہ ختم ہونے کی تاریخ حج سے پہلے اپ ڈیٹ کرنا لازمی ہے۔ ہمیں افسوس ہے کہ درست اقامہ کے بغیر رضاکار منی نہیں جا سکیں گے۔',
  IQAMA_EXPIRY_DATE_ALERT_TOOLTIP: 'اگر آپ رضاکار سے اقامہ کی تاریخ تنسیخ کو اپ ڈیٹ کرنے کا کہنا چاہتے ہیں تو یہ آپشن استعمال کریں',
  IQAMA_EXPIRY_DATE_RANGE_MSG: 'آپ کے اقامہ کی میعاد ختم ہو چکی ہے۔ براہ مہربانی، نئے اقامہ کی تاریخ تنسیخ کا انتخاب کیجیئے۔',
  IQAMA_IMAGE_UPLOAD_CAPTION: 'اقامہ تصویر اپ لوڈ کریں',
  IQAMA_IMAGE_UPDATE_ALERT: 'اقامہ کی تصویر کو اپ ڈیٹ کرنے کی درخواست کریں',
  IQAMA_IMAGE_UPDATE_ALERT_MSG: 'براہ مہربانی اپنے اقامہ کی تصویر اپ ڈیٹ کریں',
  IQAMA_IMAGE_UPDATE_ALERT_TOOLTIP: 'اگر آپ رضاکار سے ان کی اقامہ کی معلومات کو اپ ڈیٹ کرنے کے لیے کہنا چاہتے ہیں تو یہ آپشن استعمال کریں',
  VOLUNTEER_IMAGE_UPDATE_ALERT: 'اپنی تصویر اپ ڈیٹ کریں',
  VOLUNTEER_IMAGE_UPDATE_ALERT_MSG: 'براہ مہربانی اپنی تصویر اپ ڈیٹ کریں',
  VALID_IQAMA: 'کیا آپ کے پاس سعودی عرب کا تصریق شدہ اقامہ موجود ہے؟',
  VALID_IQAMA_TEXT: 'آپ کے پاس سعودی عرب کا تصریق شدہ اقامہ موجود ہونا لازمی ہے۔',

  TEST_INSTRUCTIONS_CONFIRMATION: 'میں اس بات کا اقرار کرتا ہوں کہ میں نے مندرجہ بالا ہدایات کو اچھی طرح سے سمجھ لیا ہے',

  PLACEHOLDER_ENTER_JOB_EXP: 'ملازمت کا تجربہ درج کریں',
  PLACEHOLDER_ENTER_JOB_TITLE: 'ملازمت کا عنوان درج کریں',
  PLACEHOLDER_ENTER_COMPANY: 'کمپنی کا نام درج کریں',
  PLACEHOLDER_ENTER_WHATSAPP: 'واٹس ایپ نمبر درج کریں',
  PLACEHOLDER_ENTER_MOBILE_NO: 'موبائل نمبر درج کریں',
  PLACEHOLDER_ENTER_EMERGENCY_NO: 'ایمرجنسی نمبر درج کریں',
  PLACEHOLDER_SELECT_LANGUAGES: 'زبانیں منتخب کریں',
  PLACEHOLDER_SELECT_TEAM: 'ٹیم منتخب کریں',
  PLACEHOLDER_SELECT_CITY: 'شہر منتخب کریں',
  PLACEHOLDER_SELECT_CAMP: 'کیمپ منتخب کریں',
  PLACEHOLDER_ENTER_YEARS: 'سالوں کی تعداد درج کریں',
  PLACEHOLDER_COUNTRY_CODE: 'ملک کا کوڈ',
  PLACEHOLDER_SELECT_DISTRICT: 'ضلع منتخب کریں',
  PLACEHOLDER_SELECT_EDUCATION: 'تعلیم کا انتخاب کریں',
  PLACEHOLDER_SELECT_BLOOD_GRP: 'بلڈ گروپ منتخب کریں',
  PLACEHOLDER_SELECT_DOB: 'تاریخ پیدائش منتخب کریں',
  PLACEHOLDER_EMERGENCY_CONTACT_NAME: 'ہنگامی رابطہ کا نام',
  ERROR_MESSAGE_FIRST_NAME: 'براہ کرم اپنا پہلا نام درج کریں',
  ERROR_MESSAGE_LAST_NAME: 'برائے کرم اپنا آخری نام درج کریں۔',
  ERROR_MESSAGE_PHONE_NUMBER: 'برائے کرم اپنا فون نمبر درج کریں۔',
  ERROR_MESSAGE_WHATSAPP_NUMBER: 'برائے مہربانی اپنا واٹس ایپ نمبر درج کریں۔',
  ERROR_MESSAGE_LANGUAGE: 'برائے کرم اپنی زبان منتخب کریں۔',
  ERROR_MESSAGE_CITY: 'براہ کرم اپنا شہر منتخب کریں۔',
  ERROR_MESSAGE_DISTRICT: 'براہ کرم اپنا ضلع منتخب کریں۔',
  ERROR_MESSAGE_EDUCATION: 'برائے کرم اپنی تعلیم کا انتخاب کریں۔',
  ERROR_MESSAGE_BLOOD_GRP: 'برائے مہربانی اپنا بلڈ گروپ منتخب کریں۔',
  ERROR_MESSAGE_SELECT_DOB: 'براءے مہربانی اپنی تاریخ پیدائش درج کریں.',
  ERROR_MESSAGE_EMERGENCY_CONTACT_NAME: 'برائے کرم اپنا ہنگامی رابطہ نام درج کریں۔',
  ERROR_MESSAGE_COUNTRY_CODE: 'براہ کرم اپنے ملک کا کوڈ منتخب کریں۔',
  ERROR_MESSAGE_EMERGENCY_NO: 'براہ کرم اپنا ہنگامی رابطہ نمبر درج کریں۔',
  UPDATE_VOLUNTEER_IMAGE: 'رضاکارانہ امیج کو اپ ڈیٹ کریں',

  PLACEHOLDER_ENTER_IQAMA_ID: '10 ہندسوں کا اقامہ ID درج کریں',
  ERROR_MESSAGE_IQAMA_ID: 'اقامہ کی شناخت 10 ہندسوں کی لمبی ہونی چاہئے۔',
  ERROR_MESSAGE_IQAMA_ID_EMPTY: 'برائے مہربانی اپنا اقامہ ID داخل کریں۔',

  ROLE: 'کردار',
  ERROR_MESSAGE_ROLE: 'براہ کرم کوئی کردار منتخب کریں۔',
  PLACEHOLDER_SELECT_ROLE: 'کردار منتخب کریں',
  ERROR_MESSAGE_EMAIL: 'براہ کرم اپنا ای میل درج کریں',
  ERROR_MESSAGE_EMAIL_VALID: 'ایک درست ای میل درج کریں',
  PLACEHOLDER_ENTER_PREVIOUS_PASSWORD: 'پچھلا پاس ورڈ درج کریں',
  ERROR_MESSAGE_PASSWORD: 'پاس ورڈ کم از کم 6 ہندسوں کا ہونا چاہئے۔',
  PLACEHOLDER_ENTER_NEW_PASSWORD: 'نیا پاس ورڈ درج کریں (کم از کم 6 حروف)',
  PLACEHOLDER_CONFIRM_NEW_PASSWORD: 'نئے پاس ورڈ کی تصدیق کریں (کم از کم 6 حروف)',
  ERROR_PASSWORD_MISMATCH: 'آپ کے درج کردہ دونوں پاس ورڈ مختلف ہیں۔',
  ERROR_MESSAGE_GENDER: 'جاری رکھنے کے لئے براہ کرم صنف منتخب کریں۔',

  Volunteer: 'رضا کار',
  'Super Admin': 'سپر ایڈمن',
  'Regional Admin': 'علاقائی ایڈمن',
  Admin: 'ایڈمن',
  'Assistant Team Leader': 'اسسٹنٹ ٹیم لیڈر',
  'Team Leader': 'ٹیم لیڈر',
  'Area Incharge': 'ایریا انچارج',
  Trainer: 'ٹرینر',
  TAKEN_NUMBER_M: 'موبائل نمبر پہلے سے رجسٹرڈ ہے۔',
  TAKEN_NUMBER_WA: 'واٹس ایپ نمبر پہلے سے رجسٹرڈ ہے۔',
  DOWNLOAD_APP_MSG: 'براہ کرم ہماری موبائل ایپلیکیشن ڈاؤن لوڈ کریں اور حج ۱۴۴۴ کے لیے اپنا رجشٹریشن فارم مکمل کریں۔',
}
