import React, { useEffect, useState } from 'react';
import { Steps } from 'antd';

import { getData } from '../../../helpers/request.helper';
import { URL_PATH } from '../../../constants/api.constants';

function RegistrationSteps({ userID }) {
  const [process, setProcess] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const response = await getData(
          'GET',
          URL_PATH.VOLUNTEER + userID + URL_PATH.VOLUNTEER_REG_PROCESS
        );
        setProcess(response);
      } catch (error) {
        console.log('Error', error);
      }
    })();
  }, []);

  const processItems = (items) => {
    for (let i = 0; i < items.length; i++) {
      if (
        i > 0 &&
        items[i].status === 'finish' &&
        items[i - 1].status === 'wait'
      ) {
        items[i].status = 'wait';
      }
    }
    return items;
  };

  return (
    <div className='px-4 mb-4 w-100'>
      <Steps items={processItems(process)} />
    </div>
  );
}

export default RegistrationSteps;
