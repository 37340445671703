import { store } from './../index'
import { getAppAccessRules } from '../actions/index'
import { user } from '../localstorage/localstorage'

export function hasPermission(entityName, permissionName) {
  // no need of roleName
  // we only fetch the rules for current user
  // return true / false

  const state = store.getState()
  if (!state.reducer.rules) {
    store.dispatch(getAppAccessRules)
    return []
  }
  if (state.app.userState.role === 'Super Admin') return true

  if (state.reducer.rules[entityName]) {
    return state.reducer.rules[entityName].includes(permissionName)
  }
}

export async function hasPagePermission(entityName, permissionName, id) {
  let state = store.getState();
  let userID = await user.getItem("user")

  if (id) {
    if(id === userID.id){
      return [state.app.userState.role]
    }
  }

  if (!state.reducer.rules) {
    await store.dispatch(getAppAccessRules())
    state = store.getState();
    // return []
  }

  if (state.reducer.rules && state.reducer.rules[entityName]) {
    if (state.reducer.rules[entityName].includes(permissionName)){
      return [state.app.userState.role];
    }
  }

  return ['Super Admin']
}
