import React from 'react';
import './style.scss';

const TeamInformation = ({ team, goToTeam }) => {
  let teamPosts = [];
  let teamRooms = [];
  const renderInfo = (title, detail, team, goToWhere) => {
    return (
      <div className='detail-container'>
        {goToWhere === undefined ? (
          <div className='detail'>
            <label className='info__title'>{title}</label>
            <label className='info__detail'>{detail}</label>
          </div>
        ) : (
          <div className='detail'>
            <label className='info__title'>{title}</label>
            <label className='info__poptext' onClick={() => goToWhere(team.id)}>
              {detail}
            </label>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className='info-container'>
      <h6 className='text-center info__header'>Team Information</h6>
      <hr />
      {team ? (
        <>
          {/* Rendering the Details Using Helper Function */}
          {renderInfo('Team Name', team.name, team, goToTeam)}
          {renderInfo(
            'Team Type',
            team.team_type + (team.shift ? `(${team.shift.name})` : '')
          )}
          {team.posts && team.posts.length > 0 ? (
            <React.Fragment>
              {team.posts.forEach((posts, key) => {
                if (key === posts.name) teamPosts.push(posts.name);
                else teamPosts.push(posts.name + ',');
              })}
              {renderInfo('Team Posts', teamPosts)}
            </React.Fragment>
          ) : null}
          {team.rooms && team.rooms.length > 0 ? (
            <React.Fragment>
              {team.rooms.forEach((room, key) => {
                if (key === room.length - 1) {
                  teamRooms.push(room.name);
                } else {
                  teamRooms.push(room.name + ',');
                }
              })}
              {renderInfo('Team Rooms', teamRooms)}
            </React.Fragment>
          ) : null}
        </>
      ) : (
        <div className='d-flex justify-content-center align-items-center h-100'>
          <label className='text-center info__title'>No Team assigned.</label>
        </div>
      )}
    </div>
  );
};

export default TeamInformation;
