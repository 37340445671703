export const MAP_CONSTANTS = {
  MINA_LNG: 39.8946,
  POLE: 'POLE',
  POST: 'POST',
  VOL: 'VOL',
  CITY: 'CITY',
  MINA_LAT: 21.4146,
  MAP_ZOOM: 15,
  CLUSTER_ZOOM_10: 17,
  CLUSTER_ZOOM_15: 15,
  CLUSTER_ZOOM_20: 20,
  CLUTSER_TEXT_SIZE: 12,
  CLUSTER_MAIN_RADIUS: 50,
  CLUSTER_MAX_ZOOM: 14,
  CLUTSER_CIRCLE_RADIUS: 2,
  ICON_WIDTH: 32,
  ICON_HEIGHT: 40,
  POP_WIDTH: 200,
  URL_POLES: '/poles/get_locations',
  URL_VOLUNTEERS: '/volunteers/get_locations',
  URL_POSTS: '/posts/get_locations',
  MAP_STYLE: 'mapbox://styles/mapbox/streets-v9',
  MAP_STYLE_SATELLITE: 'mapbox://styles/mapbox/satellite-v9',
  NAVIGATION_POS: 'bottom-right',
  MAP_REFRESH_MILLISECONDS: 180000,

  //Options Contants
  OPTION_VOLUNTEERS: 'Volunteers',
  OPTION_POLES: 'Camps / Poles',
  OPTION_POSTS: 'Posts',
  OPTION_LAYER_TEAM: 'Team',
  OPTION_IQAMA: 'Search By Iqama',
  OPTION_NAME: 'Search By Name',
  OPTION_TEAM: 'Search By Team',

  URL_FOR_VOL: './resources/images/markerIcons/Volunteer.svg',
  URL_FOR_HIGHLIGHT_VOL: './resources/images/markerIcons/HeighlightedVolunteer.svg',
  URL_FOR_BUILDING: './resources/images/markerIcons/Building.svg',
  URL_FOR_POST: './resources/images/markerIcons/Post.svg',
  URL_FOR_POST_GOOD: './resources/images/markerIcons/PostGood.svg',
  URL_FOR_POST_IMPORTANT: './resources/images/markerIcons/PostImportant.svg',
  URL_FOR_POST_CRITICAL: './resources/images/markerIcons/PostCritical.svg',
  URL_FOR_POLE: './resources/images/markerIcons/Pole.svg',

  ERROR_HEADING: 'Select Any Option',
  ERROR_DESC: 'Please Select Any Option.You Have Not Selected Any Option.',

  // Map Local Storage
  MAP_SETTINGS_KEY: 'map-settings',

};
