import React from 'react'
import { Modal } from 'antd'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { user } from '../../../localstorage/localstorage'
import { getData } from '../../../helpers/request.helper'
import { URL_PATH } from '../../../constants/api.constants'
import ReRegistration from '../Re-registration.js'
import { logout } from "ducks/app";

class RegistrationPopup extends React.Component {
  state = {
    visible: null,
    messageForPopup: null,
    reRegistrationPopup : false
  }

  async componentDidMount() {
    let currentUser = await user.getItem('user')
    if (currentUser) {
      let response = await getData(
        'GET',
        URL_PATH.VOLUNTEER + currentUser.id + URL_PATH.VOLUNTEER_POPUP,
      )
      if (response) {
        if(response.blacklisted){
          this.props.logout()
        }
        if (response.success === true) {
          this.setState({ messageForPopup: response.message, visible: true })
        } else {
          this.setState({ messageForPopup: response.message, visible: false })
        }
      }
    }
  }

  handleOk = e => {
    console.log(e)
    this.setState({
      visible: false,
      reRegistrationPopup : true
    })
    // this.props.goToLogin()
  }

  handleCancel = e => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  render() {
    return (
      <div>
        <Modal
          title={this.state.messageForPopup}
          open={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          maskClosable={false}
          centered
          closable={false}
          okText="Continue"
          cancelText="Ask me Later"
        >
          <div>
            <p style={{ fontSize: 14 }}>
              Registrations for Hajj are now open. Please click <strong>Continue</strong> if you
              want to apply or
              <strong> Ask me Later</strong> otherwise.{' '}
            </p>
          </div>
        </Modal>
        <ReRegistration visible={this.state.reRegistrationPopup} close={()=>{
          this.setState({
            reRegistrationPopup : false
          })
        }} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  goToLogin: () => dispatch(push({ pathname: '/registration', data: false })),
  logout: () => {
    dispatch(logout());
  },
})

export default connect(null, mapDispatchToProps)(RegistrationPopup)
