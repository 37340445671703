import { requiredData as dataStorage } from '../localstorage/requiredData.localstorage'
import { getData } from '../helpers/request.helper'
import { requiredData as requiredDataConstants } from '../constants/app.constants'
import { URL_PATH } from '../constants/api.constants'

export const getRequiredData = () => {
  return async dispatch => {
    let requiredData = await getData('GET', URL_PATH.REQUIRED_DATA)
    await dataStorage.setItem('requiredData', requiredData)
    dispatch({
      type: requiredDataConstants.SET_REQUIRED_DATA,
      requiredData: requiredData,
    })
  }
}
